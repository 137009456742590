import hosts from "./hosts";
import resources from "./resources";
import { combineReducers } from "@reduxjs/toolkit";
import app from "./app";
import jobs from "./jobs";
import history from "./history";
export default combineReducers({
  hosts,
  resources,
  app,
  jobs: jobs.reducer,
  history,
});
