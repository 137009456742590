import _ from "lodash";
import * as React from "react";
import { NodeObject } from "react-force-graph-2d";
import { useDispatch, useSelector } from "react-redux";
import { fetchHistoryForHost } from "../redux/reducers/history/extensionState";
import { WebLinkStored } from "../redux/reducers/resources";
import { AppDispatch, RootState } from "../redux/store";
import {
  AppState,
  CompletedTabNavigation,
  PageAction,
} from "../../../extension/src/shared/types";
import { useLoadExtensionState } from "../WebHistory/useLoadExtensionState";
import { isLoaded } from "../../../extension/src/content_script/hypothesis/annotator/util/frame-util";
import { ElementAnnotationData } from "../../../extension/src/content_script/selectors/ElementSelectorCreator";

const idSeparator = "~~~~";

type BaseNode = NodeObject & {
  nodeType: string;

  color?: string;

  id: string;
  url: string;
  host: string;
  name: string;
};

export type ActionNode = BaseNode & {
  nodeType: "action";
  data: PageAction;
};
export type HistoryItemNode = BaseNode & {
  nodeType: "history-item";
  historyNodeType: keyof CompletedNavigationData;
  data: CompletedTabNavigation;
};

type MetaInfo = {
  url: string;
  favicon: string;
};

export type WebHistoryNode = ActionNode | HistoryItemNode;

type WebHistoryNodes = WebHistoryNode[];

// const hostnameFilterKey = `wm:hostnameFilter`;
export function useWebHistoryData() {
  let [selectedHistoryNode, setSelectedHistoryNode] = React.useState<
    string | null
  >(null);

  let [data, setData] = React.useState<{
    nodes: WebHistoryNodes;
    links: WebLinkStored[];
    metaInfo: Record<string, MetaInfo>;
  }>({
    nodes: [],
    links: [],
    metaInfo: {},
  });

  const pageActions = useSelector((s: RootState) => {
    return s.history.extension.appState?.pageActions;
  });
  const completedNavigations = useSelector((s: RootState) => {
    return s.history.extension.appState?.completedNavigations || [];
  });

  React.useEffect(() => {
    if (!completedNavigations || !pageActions) {
      return;
    }
    console.log("data updated");
    // let { completedNavigations, pageActions } = extensionState;

    let destNodes: HistoryItemNode[] = completedNavigations.map((n) => {
      let host = "";
      let url = n.destUrl;
      try {
        let parsed = new URL(url);
        ({ host } = parsed);
      } catch (e) {}

      return {
        id: `${n.id}${idSeparator}${n.destUrl}`,
        // id: `${n.dest}`,
        nodeType: "history-item" as const,
        historyNodeType: "destUrl",
        url: url,
        host,
        name: nodeName(n, url),
        color: nodeColor(n, url),
        data: n,
      };
    });

    let finalDestNodes: HistoryItemNode[] = completedNavigations.map((n) => {
      let host = "";
      let url = n.finalDest;
      try {
        let parsed = new URL(url);
        ({ host } = parsed);
      } catch (e) {}

      return {
        id: `${n.id}${idSeparator}${n.finalDest}`,
        // id: `${n.finalDest}`,
        nodeType: "history-item" as const,
        historyNodeType: "finalDest",
        url: url,
        host,
        name: nodeName(n, url),
        color: nodeColor(n, url),
        data: n,
      };
    });

    let srcNodes: HistoryItemNode[] = completedNavigations.map((n) => {
      let host = "";
      let url = n.sourceUrl;
      try {
        let parsed = new URL(url);
        ({ host } = parsed);
      } catch (e) {}

      let useTabId = n.tabId;

      if (n.sourceTabId && n.sourceTabId != n.tabId) {
        useTabId = n.sourceTabId;
      }

      return {
        id: `${n.id}${idSeparator}${n.sourceUrl}`,
        nodeType: "history-item" as const,
        historyNodeType: "sourceUrl",
        url: url,
        host,
        name: nodeName(n, url),
        color: nodeColor(n, url),
        data: n,
      };
    });

    const excludedActionTypes = ["focus", "blur"];
    const filteredActions = _.chain(pageActions).filter(
      (a) => !excludedActionTypes.includes(a.type),
    );

    let metaInfo: Record<string, MetaInfo> = {};

    let actionNodes: ActionNode[] = filteredActions
      .map((a) => {
        let host = "";
        try {
          let parsed = new URL(a.url);
          ({ host } = parsed);
        } catch (e) {}
        if (a.type == "load") {
          // @ts-ignore
          let info = a.payload.info as MetaInfo;
          if (info) {
            metaInfo[a.url] = info;
          }
        }
        return {
          id: `${a.navigationId}${idSeparator}${a.timeStamp}${idSeparator}${a.type}`,
          name: actionNodeName(a),
          host,
          url: a.url,
          nodeType: "action" as const,
          color: actionNodeColor(a),
          data: a,
        };
      })
      .value();

    let nodes: WebHistoryNodes = _.chain([] as WebHistoryNodes)
      .concat(destNodes, finalDestNodes, srcNodes, actionNodes)
      .uniqBy("id")
      .value();

    let destLinks = _(completedNavigations)
      .map((n) => {
        let hosts = [];
        try {
          let parsed = new URL(n.sourceUrl);
          let { host } = parsed;
          hosts.push(host);
        } catch (e) {}
        try {
          let parsed = new URL(n.destUrl);
          let { host } = parsed;
          hosts.push(host);
        } catch (e) {}

        let source = `${n.id}${idSeparator}${n.sourceUrl}`;
        let target = `${n.id}${idSeparator}${n.destUrl}`;
        return {
          _id: `${n.id}${idSeparator}${n.sourceUrl}${idSeparator}${n.destUrl}`,
          linkType: n.type,
          source,
          target,
          hosts,
        };
      })
      .filter(notEmpty)
      .value();

    let finalDestLinks = _(completedNavigations)
      .map((n) => {
        if (n.destUrl == n.finalDest) {
          return null;
        }
        let hosts = [];
        try {
          let parsed = new URL(n.destUrl);
          let { host } = parsed;
          hosts.push(host);
        } catch (e) {}
        try {
          let parsed = new URL(n.finalDest);
          let { host } = parsed;
          hosts.push(host);
        } catch (e) {}

        let source = `${n.id}${idSeparator}${n.destUrl}`;
        let target = `${n.id}${idSeparator}${n.finalDest}`;
        return {
          _id: `${n.id}${idSeparator}${n.destUrl}${idSeparator}${n.finalDest}`,
          linkType: n.type,
          source,
          target,
          hosts,
        };
      })
      .filter(notEmpty)
      .value();
    let finalDestSourceLinks = _(completedNavigations)
      .map((n) => {
        // if (n.destUrl == n.finalDest) {
        //   return null;
        // }

        let targetNav = _.find(completedNavigations, (other) => {
          if (!other.sourceNavigationId) {
            return (
              other.sourceUrl == n.finalDest &&
              // other.tabId == sourceTabId &&
              other.timeStamp > n.timeStamp
            );
          } else {
            return other.sourceNavigationId == n.id;
          }
        });
        if (!targetNav) {
          console.log("no targetnav found for", n);
          return;
        }

        let source = `${n.id}${idSeparator}${n.finalDest}`;
        let target = `${targetNav.id}${idSeparator}${targetNav.sourceUrl}`;
        let hosts = [];
        try {
          let parsed = new URL(n.destUrl);
          let { host } = parsed;
          hosts.push(host);
        } catch (e) {}
        try {
          let parsed = new URL(targetNav.sourceUrl);
          let { host } = parsed;
          hosts.push(host);
        } catch (e) {}
        return {
          _id: `${n.id}${idSeparator}${n.finalDest}${idSeparator}${n.sourceUrl}`,
          linkType: n.type,
          source,
          target,
          hosts,
        };
      })
      .filter(notEmpty)
      .value();
    let sourceFinalDestLinks = _(completedNavigations)
      .map((n) => {
        // if (n.destUrl == n.finalDest) {
        //   return null;
        // }

        let { sourceNavigationId } = n;

        let targetNav = _.find(completedNavigations, (other) => {
          if (!sourceNavigationId) {
            return (
              other.finalDest == n.sourceUrl &&
              // other.tabId == sourceTabId &&
              other.timeStamp < n.timeStamp
            );
          } else {
            return sourceNavigationId == other.id;
          }
        });
        if (!targetNav) {
          // console.log("no sourceFinalDestLinksId found for", n);
          return;
        } else {
          // console.log("sourceFinalDestLinksId", { n, targetNav });
        }

        let source = `${targetNav.id}${idSeparator}${targetNav.finalDest}`;
        let target = `${n.id}${idSeparator}${n.sourceUrl}`;
        let hosts = [];
        try {
          let parsed = new URL(n.sourceUrl);
          let { host } = parsed;
          hosts.push(host);
        } catch (e) {}
        try {
          let parsed = new URL(targetNav.finalDest);
          let { host } = parsed;
          hosts.push(host);
        } catch (e) {}
        return {
          _id: `${n.id}${idSeparator}${n.finalDest}${idSeparator}${n.sourceUrl}`,
          linkType: n.type,
          source,
          target,
          hosts,
        };
      })
      .filter(notEmpty)
      .value();

    let actionLinks = pageActions
      .map((a) => {
        if (a.type != "load") {
          return undefined;
        }
        return actionLink(a);
      })
      .filter(notEmpty);

    let actionChainLinks = filteredActions
      .filter((a) => a.tabId != 0)
      // .groupBy((a) => `${a.tabId}${idSeparator}${a.url}`)
      .groupBy((a) => a.navigationId)
      .map((tabUrlActions, tabUrl) => {
        let sorted = _.sortBy(tabUrlActions, "timeStamp");

        return sorted
          .map((a, i) => {
            let prev = sorted[i - 1];
            if (!prev) {
              if (a.type != "load") {
                // console.warn(tabUrl, 'first not load', a);
                return actionLink(a);
              }
              return undefined;
            }
            let source = makeActionId(prev);
            let target = makeActionId(a);
            return {
              _id: `link${idSeparator}${source}${idSeparator}${target}`,
              source,
              target,
              hosts: [],
              linkType: "activity",
            };
          })
          .filter(notEmpty);
      })
      .flatten()
      .value();

    let actionClicks = filteredActions
      .filter(
        (a) => ["click", "auxclick"].includes(a.type) && !!getActionHref(a),
      )
      .map((a) => {
        let href = getActionHref(a)!;
        if (!href) {
          return undefined;
        }

        let targetNav = _.find(
          completedNavigations,
          ({ destUrl, sourceUrl, sourceNavigationId }) => {
            if (sourceNavigationId) {
              return sourceNavigationId == a.navigationId;
            } else {
              return sourceUrl == a.url && destUrl.includes(href);
            }
          },
        );
        if (targetNav) {
          let source = makeActionId(a);
          let target = `${targetNav.id}${idSeparator}${targetNav.destUrl}`;
          return {
            _id: `link${idSeparator}${source}${idSeparator}${target}`,
            source,
            target,
            hosts: [],
            linkType: "activity",
          };
        } else {
          console.log("id no nav found for click", a.url, href);
        }
      })
      .filter(notEmpty)
      .value();

    nodes = nodes.filter((n) => {
      let valid = n.id.split(idSeparator)[0] !== "0";
      if (!valid) {
        console.warn("node not valid", n);
      }

      return valid;
    });

    console.log({
      destLinks,
      finalDestLinks,
      actionLinks,
      actionChainLinks,
      actionClicks,
      finalDestSourceLinks,
      sourceFinalDestLinks,
    });

    let links = _.chain([] as WebLinkStored[])
      .concat(
        destLinks,
        finalDestLinks,
        actionLinks,
        actionChainLinks,
        finalDestSourceLinks,
        sourceFinalDestLinks,
        actionClicks,
      )
      .value();
    links = links.filter((l) => {
      let foundSource = _.find(nodes, { id: l.source });
      let foundTarget = _.find(nodes, { id: l.target });

      let found = foundSource && foundTarget;
      if (!found) {
        console.warn("link source/target not found", l, {
          foundSource,
          foundTarget,
        });
      }
      return found;
    });
    console.log({ nodes, links });
    setData({
      nodes,
      links,
      metaInfo,
    });
  }, [pageActions, completedNavigations]);

  return {
    data,
    // stateJson: extensionState,
    stateJson: {
      pageActions,
      completedNavigations,
    },
    selectedHistoryNode,
    setSelectedHistoryNode,
  };
}

export type WebHistoryData = ReturnType<typeof useWebHistoryData>;
function getActionHref(a: PageAction): string | undefined {
  return (
    _.get(a, "payload.target.href") || _.get(a, "payload.closestHrefNode.href")
  );
}

function actionNodeName(a: PageAction): string {
  let name = `${a.type} ${
    a.timeStamp ? new Date(a.timeStamp).toISOString() : "???"
  } - ${a.url.slice(0, 150)}`;

  if (!_.isEmpty(a.payload)) {
    let out = "";
    if (a.type == "selectionchangeend") {
      out = getSelectedText(a);
    }
    out = out || JSON.stringify(a.payload, null, 2);

    if (out) {
      name = `${name}<br/>${out}`;
    }
  }

  return name;
}

function actionLink(a: PageAction) {
  let hosts = [];
  try {
    let parsed = new URL(a.url);
    let { host } = parsed;
    hosts.push(host);
  } catch (e) {}
  return {
    _id: `link${idSeparator}${a.navigationId}${idSeparator}${a.timeStamp}${idSeparator}${a.type}`,
    source: `${a.navigationId}${idSeparator}${a.url}`,
    target: makeActionId(a),
    hosts,
    linkType: a.type,
  };
}

const colors = {
  darkGray: "#40404F",
  orange: "#FFA500",
  gold: "#FFD700",
  lightGray: "#858482",
  sienna: "#aa6c39",
  purple: "#800080",
  blue: "#1b03a3",
  biege: "#eddfa6",
  green: "#50C878",
};

function actionNodeColor(a: ActionData): string {
  if (a.type == "blur" || a.type == "focus") {
    return colors.sienna;
  }
  if (a.type == "selectionchangeend") {
    if (getSelectedText(a)) {
      return colors.purple;
    } else {
      return colors.lightGray;
    }
  }
  if (a.type == "click") {
    return colors.orange;
  }

  if (a.type == "wm:annotation") {
    return colors.gold;
  }
  if (a.type == "wm:screenshot") {
    return colors.blue;
  }
  if (a.type == "beforeunload") {
    return colors.darkGray;
  }
  return colors.biege;
}

function makeActionId(a: PageAction): string {
  return `${a.navigationId}${idSeparator}${a.timeStamp}${idSeparator}${a.type}`;
}

type CompletedNavigationData = AppState["completedNavigations"][number];

type ActionData = AppState["pageActions"][number];

function nodeColor(n: CompletedNavigationData, url: string) {
  if (n.sourceUrl.includes("chrome://new")) {
    return "#FFF857";
  } else {
    if (url.includes("net::ERR")) {
      return "#dc143c";
    }
    if (isSearch(url)) {
      return colors.green;
    }
    if (n.sourceTabId && n.sourceTabId != n.tabId) {
      return "#BCFFFF";
    } else {
      return "#89cff0";
    }
  }
}
function isSearch(url: string) {
  return url.includes("search?q");
}

function nodeName(n: CompletedNavigationData, url: string) {
  if (isSearch(url)) {
    try {
      let parsed = new URL(url);
      let { host } = parsed;

      let q = parsed.searchParams.get("q");
      if (q) {
        return `${host} - ${q}`;
      }
    } catch (e) {}
  }
  return url;
}
export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export function getSelectedText(a: PageAction) {
  let targets = _.get(a, "payload.target");
  if (targets && targets[0]) {
    let quote = _.find(targets[0].selector, { type: "TextQuoteSelector" });
    if (quote) {
      return quote.exact;
    }
  }
}

export function getSelectedTagNames(a: PageAction) {
  let targets = _.get(a, "payload.target");
  if (targets && targets[0]) {
    let range = _.find(targets[0].selector, { type: "RangeSelector" });
    if (range) {
      let { startContainer, endContainer } = range;

      if (startContainer && endContainer) {
        return `${startContainer} - ${endContainer}`;
      }
    }
  }
}

export function getImageSrc(a: { payload: ElementAnnotationData }) {
  if (a.payload.blobUrl) {
    return a.payload.blobUrl;
  }
  let targets = _.get(a, "payload.target");
  if (targets && targets[0]) {
    let quote = _.find(targets[0].selector, { type: "XPathElementSelector" });
    if (quote) {
      let src =
        quote.attrs?.poster ||
        quote.attrs?.src ||
        quote.attrs?.currentSrc ||
        quote.attrs?.css?.backgroundImageUrl;

      if (src) {
        return src;
      }
    }
  }
}
