import { useNavigate } from "@reach/router";
import { unwrapResult } from "@reduxjs/toolkit";
import * as _ from "lodash";
import * as React from "react";
import { CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../config";
import { WebHistoryData, WebHistoryNode } from "../hooks/useWebHistoryData";
import { crawlSelectedResource } from "../redux/reducers/resources";
import { analyzeKeywords } from "../redux/reducers/textActionss";
import { RootState } from "../redux/store";
import { imageLinkTypes, SafeImage } from "../SafeImage";
import { Debug } from "../shared/Debug";
import { actionLinkStyle, floatingBoxStyle } from "../WebViewer";
import { PageInfo } from "./PageInfo";

export const WebHistoryNodeVisualizer = (p: { webData: WebHistoryData }) => {
  const dispatch = useDispatch();
  let { webData } = p;

  let { selectedHistoryNode } = webData;

  let exists = _.find(webData.data.nodes, (r) => r.id == selectedHistoryNode);
  if (!selectedHistoryNode || !exists) {
    return null;
  }
  let actions: JSX.Element[] = [];
  let preview;

  if (exists.nodeType == "history-item") {
    preview = (
      <PageInfo
        historyNode={exists.data}
        pageLinks={{}}
        pageEvents={{}}
        pageAnnotations={{}}
        actualDepth={0}
        depth={0}
      />
    );
  } else {
    preview = (
      <>
        <pre
          style={{
            overflow: "auto",
          }}
        >
          {exists.url}
        </pre>
        <ResourceCard resource={exists} />
      </>
    );
  }

  return (
    <div
      style={{
        ...floatingBoxStyle,
        width: "33vw",
      }}
    >
      {preview}
      <Debug>{exists}</Debug>
      {/* {!crawled ? <div>not crawled</div> : null} */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {actions}
      </div>
    </div>
  );
};

export const ResourceCard: React.FC<{ resource?: WebHistoryNode }> = ({
  resource,
}) => {
  if (!resource) {
    return null;
  }

  const { id } = resource;

  return (
    <div>
      <div>id: {id}</div>
    </div>
  );

  // if (!resource || !resource.info?.meta) {
  //   return null;
  // }
  // let { meta, favicon, metaImage } = resource.info;
  // let { title, description } = meta;

  // let titleDisplay = <strong>{title}</strong>;
  // let faviconDisplay;
  // if (favicon) {
  //   faviconDisplay = (
  //     <img
  //       src={favicon.to_url}
  //       style={{
  //         height: 'auto',
  //         maxWidth: 50,
  //         maxHeight: 35,
  //         marginRight: 5,
  //       }}
  //     />
  //   );
  // }

  // let descriptionDisplay = description ? (
  //   <Description description={description} />
  // ) : null;
  // let metaImageDisplay;
  // if (metaImage) {
  //   metaImageDisplay = (
  //     <img
  //       src={metaImage.to_url}
  //       style={{
  //         height: 'auto',
  //         maxWidth: 125,
  //         maxHeight: 125,
  //         marginRight: 5,
  //       }}
  //     />
  //   );
  // }

  // return (
  //   <>
  //     {title || favicon ? (
  //       <div
  //         style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}
  //       >
  //         {faviconDisplay}
  //         {titleDisplay}
  //       </div>
  //     ) : null}
  //     <div style={{ display: 'flex', alignItems: 'center' }}>
  //       {metaImageDisplay}
  //       {descriptionDisplay}
  //     </div>
  //   </>
  // );
};

export const Description: React.FC<{ description: string }> = ({
  description,
}) => {
  let isShort = !description || description.length < 150;
  let [expanded, setExpanded] = React.useState(isShort);
  let style: CSSProperties = {
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    marginBottom: "15px",
  };
  let shadowStyle: CSSProperties = {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    margin: "0",
    padding: "30px 0",
  };

  /* "transparent" only works here because == rgba(0,0,0,0) */
  if (!expanded) {
    style = {
      ...style,
      maxHeight: "50px",
    };
    shadowStyle.backgroundImage =
      "linear-gradient(to bottom, transparent, white)";
  }

  return (
    <div style={style} onClick={() => setExpanded(!expanded)}>
      {description}
      <div style={shadowStyle}></div>
    </div>
  );
};
