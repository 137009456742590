import * as _ from "lodash";
import * as React from "react";
import { absolutizeUrls } from "./utils/abolutizeUrls";
import { actionLinkStyle, floatingBoxStyle } from "./WebViewer";
import { useInput, useCheckBox } from "./hooks/useInput";
import { SiteData } from "./DOMViewer";
import { render } from "react-dom";
function previewSelector() {
  function showInfo(el: HTMLElement) {
    const infoElId = "webmoose-info-el";
    if (el.matches(`#${infoElId}`)) {
      return;
    }
    let info = elementInfo(el);
    let ancestor = el.parentElement;
    while (ancestor) {
      info = `${elementInfo(ancestor)} > ${info}`;
      ancestor = ancestor.parentElement;
    }
    let infoEl = document.getElementById(infoElId);
    if (!infoEl) {
      infoEl = document.createElement("div");
      infoEl.id = infoElId;
      infoEl.style.fontFamily = "mono";
      infoEl.style.whiteSpace = "wrap";
      infoEl.style.background = "white";
      infoEl.style.position = "fixed";
      infoEl.style.top = "0";
      infoEl.style.left = "0";
      infoEl.style.width = "100vw";
      infoEl.style.zIndex = "999999";

      if (document.body) {
        document.body.appendChild(infoEl);
      } else if (document.firstElementChild) {
        document.firstElementChild.appendChild(infoEl);
      } else {
        console.log("empty document?");
      }
    }
    infoEl.innerHTML = info;
  }
  document.addEventListener("mouseover", function (evt) {
    // console.log({ evt }, evt.target);
    showInfo(evt.target as HTMLElement);
  });

  function elementInfo(el: HTMLElement) {
    let { tagName, className, id } = el;

    let info = `${tagName}`;
    if (className) {
      let classes = className.split(" ").filter((c) => !!c.trim());
      info = `${info}.${classes.join(", .")}`;
    }
    if (id) {
      info = `${info}#${id}`;
    }

    return info;
  }
}
export const DOMManipulator = (p: { siteData: SiteData }) => {
  const { siteData } = p;
  const currentTreeInput = useInput("");
  const [treeOptions, setTreeOptions] = React.useState<{ uri: string }[]>([]);

  const [parsedOriginal, setParsedOriginal] = React.useState<Document | null>();

  const [manipulatedHTML, setManipulatedHTML] = React.useState("");
  const allowScripts = useCheckBox(false);
  // const selectorInput = useInput('');
  // const attributeInput = useInput('');
  const selectorInput = useInput("html");
  const attributeInput = useInput("");
  const [extracted, setExtracted] = React.useState<string[]>([]);
  const [rendered, setRendered] = React.useState(false);

  React.useEffect(() => {
    if (!currentTreeInput.value) {
      currentTreeInput.setValue(_.first(siteData.data)?.uri || "");
    }
  }, [siteData.data, currentTreeInput]);
  React.useEffect(() => {
    setTreeOptions(siteData.data.map((t) => ({ uri: t.uri })));
  }, [siteData.data]);

  React.useEffect(() => {
    let selector = selectorInput.value;
    if (!selector) {
      setManipulatedHTML("");
      return;
    }
    let first = _.find(siteData.data, (t) => t.uri == currentTreeInput.value);
    if (!first || !first.raw) {
      return;
    }

    if (!parsedOriginal) {
      let parser = new DOMParser();
      let absoluted = absolutizeUrls(first.raw, first.uri);
      // let absoluted = first.raw;
      let doc = parser.parseFromString(absoluted, "text/html");
      setParsedOriginal(doc);
      return;
    }
    let matches;
    try {
      matches = parsedOriginal.querySelectorAll(selector);
      // console.log({ matches });
    } catch (e) {
      console.warn(e);
      return;
    }
    if (rendered) {
      setManipulatedHTML(
        `<script>${previewSelector.toString()}; previewSelector();</script>`,
      );
    } else {
      setManipulatedHTML("");
    }
    Array.from(matches).forEach((el) =>
      setManipulatedHTML((o) => `${o}${el.outerHTML}`),
    );
  }, [
    parsedOriginal,
    selectorInput.value,
    siteData.data,
    currentTreeInput.value,
    rendered,
    allowScripts.value,
  ]);

  let iframeRef = React.useRef<HTMLIFrameElement>(null);

  let onScriptsChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let checked = event.target.checked;
      allowScripts.setValue(checked);
      _.defer(() => {
        if (iframeRef.current) {
          iframeRef.current.srcdoc = iframeRef.current.srcdoc;
        }
      });
    },
    [allowScripts, iframeRef],
  );

  React.useEffect(() => {
    let extractAttributesStr = attributeInput.value;
    if (parsedOriginal && extractAttributesStr) {
      let selector = selectorInput.value;
      let attributes = extractAttributesStr.split(",").map((s) => s.trim());

      let matches;
      try {
        matches = parsedOriginal.querySelectorAll(selector);
        // console.log({ matches });
      } catch (e) {
        console.warn(e);
        return;
      }

      let extracted: string[] = [];
      Array.from(matches).forEach((el) => {
        attributes.forEach((attr) => {
          let val = el.getAttribute(attr);
          if (_.isString(val)) {
            extracted.push(val);
          }
        });
      });

      setExtracted(extracted);
    }
  }, [attributeInput.value, parsedOriginal, selectorInput.value]);

  let [extractedCrawlResults, setExtractedCrawlResults] = React.useState<
    Record<
      string,
      {
        crawling: boolean;
      }
    >
  >({});
  React.useEffect(() => {
    extracted.forEach(async (url) => {
      try {
        let parser = new URL(url);
        let parsed = parser.href;
        console.log("should crawl", url);
        if (!extractedCrawlResults[parsed]) {
          setExtractedCrawlResults((existing) => {
            return {
              ...existing,
              [parser.href]: {
                crawling: true,
              },
            };
          });
          // let crawled = await crawlResource(parser.href);
          console.log("would crawl", parser.href);
          // setExtractedCrawlResults((existing) => {
          //   return {
          //     ...existing,
          //     [parser.href]: crawled,
          //   };
          // });
        }
      } catch (e) {
        console.warn(url, e);
      }
    });
  });

  React.useEffect(() => {
    return () => {
      setParsedOriginal(null);
      setExtracted([]);
    };
  }, [currentTreeInput.value]);

  let manipulated;
  let boxStyle: React.CSSProperties = {
    maxHeight: "50vh",
    minWidth: "50vw",
    overflow: "auto",
    resize: "both",
  };
  if (rendered) {
    let sandboxProp: Record<string, string> = {
      sandbox: "",
    };
    if (allowScripts.checked) {
      sandboxProp = {};
    }
    manipulated = (
      <iframe
        ref={iframeRef}
        style={boxStyle}
        {...sandboxProp}
        // dangerouslySetInnerHTML={{ __html: manipulatedHTML }}
        srcDoc={manipulatedHTML}
      />
    );
  } else {
    manipulated = (
      <textarea readOnly value={manipulatedHTML} style={boxStyle} />
    );
  }

  const toggleManipulatedCb = React.useCallback(
    () => setRendered((r) => !r),
    [],
  );
  const toggleManipulated = (
    <span style={{ ...actionLinkStyle }} onClick={toggleManipulatedCb}>
      {rendered ? "rendered" : "raw"}
    </span>
  );

  let renderOptions;
  if (rendered) {
    renderOptions = (
      <>
        <label>
          allow scripts
          <input
            {...allowScripts.bind}
            onChange={onScriptsChanged}
            type="checkbox"
          />
        </label>
      </>
    );
  }

  const treeOptionsEls = treeOptions.map(({ uri }) => (
    <option value={uri} key={uri}>
      {uri}
    </option>
  ));
  const treeSelect = (
    <select {...currentTreeInput.bind}>{treeOptionsEls}</select>
  );

  let extractedDisplay;
  if (attributeInput.value && extracted.length) {
    extractedDisplay = (
      <ul>
        {extracted.map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
    );
  }

  return (
    <div
      style={{
        ...floatingBoxStyle,
        bottom: 0,
        top: "auto",
        maxHeight: "75vh",
        overflow: "auto",
      }}
    >
      <div>
        <label>tree</label>
        {treeSelect}
      </div>
      <div>
        <label>selector</label>
        <input {...selectorInput.bind} />
      </div>
      <br />
      <div>
        {toggleManipulated}
        {renderOptions}
      </div>
      {manipulated}
      <div>
        <label>extract attributes (, separated)</label>
        <input {...attributeInput.bind} />
        <div
          style={{
            maxHeight: "30vh",
            overflow: "auto",
          }}
        >
          {extractedDisplay}
        </div>
      </div>
    </div>
  );
};
