import * as React from "react";
import { DOMVisualizer, ParsedDOMTree } from "./DOMVisualizer";
import { DOMManipulator } from "./DOMManipulator";
import { usePageData } from "./hooks/usePageData";

type LinkType = {
  source: NodeType;
  target: NodeType;
};

type NodeType = {
  links: LinkType[];
};

const NODE_R = 8;
interface DOMViewerProps {
  resourceId: string;
}

export type DOMLatestResponseResponse = {
  response?: {
    body: string;
    meta: {
      headers?: {
        "content-type"?: string;
      };
    };
  };
  resource: {
    url: string;
  };
};

export type ResourceResponse = {
  id: number;
  url: string;
};

export type DOMTree = {
  resourceId: string;
  tree?: ParsedDOMTree;
  uri: string;
  raw?: string;
};

export type SiteData = ReturnType<typeof usePageData>;

export function DOMViewer(p: DOMViewerProps) {
  let { resourceId } = p;
  let siteData = usePageData(resourceId);
  // let selectedResource = { webData };
  return (
    <div className="App">
      <DOMVisualizer siteData={siteData} />
      <DOMManipulator siteData={siteData} />
      {/* <DOM3DVisualizer siteData={siteData} /> */}
      {/* <ResourceVisualizer webData={webData} />
      <ManualCrawler webData={webData} /> */}
    </div>
  );
}
