import { unwrapResult } from "@reduxjs/toolkit";
import * as React from "react";
import { ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { Collapsible } from "./shared/Collapsible";
import { useCheckBox, useInput } from "./hooks/useInput";
import { useWebData } from "./hooks/useWebData";
import { crawlResource, fetchRandomUrl } from "./redux/reducers/resources";
import { actionLinkStyle } from "./WebViewer";

export const ManualCrawler = () => {
  const webData = useWebData();
  let dispatch = useDispatch();
  let originalHostOnly = useCheckBox(true);

  let [crawlDepth, setCrawlDepth] = React.useState(0);
  let crawlUrlInput = useInput("");
  let [crawlRes, setCrawlRes] = React.useState<{
    crawlUrl?: string;
    response: string;
  } | null>();
  let { selectedResource, refetchResource, setSelectedResource } = webData;

  let [isManualCrawling, setManualCrawling] = React.useState(false);

  let startCrawlUrl = React.useCallback(
    async (url: string) => {
      setManualCrawling(true);
      setCrawlRes(null);
      let crawlingUrl = url;
      try {
        let crawledUrlResult = await dispatch(
          crawlResource({
            url: crawlingUrl,
            crawlOptions: {
              maxDepth: crawlDepth,
              constrainHost: originalHostOnly.checked,
            },
          }),
        );
        // @ts-ignore
        let crawledUrl = unwrapResult(crawledUrlResult);
        if (selectedResource != crawledUrl) {
          setSelectedResource(crawledUrl);
        }

        crawlingUrl = crawledUrl || crawlingUrl;
        crawlUrlInput.setValue("");
      } catch (e) {
        setManualCrawling(false);
        setCrawlRes({
          response: `:( ${JSON.stringify(e)}`,
          crawlUrl: crawlingUrl,
        });
        return;
      }
      setManualCrawling(false);
    },
    [
      dispatch,
      crawlUrlInput,
      crawlDepth,
      originalHostOnly.checked,
      selectedResource,
      setSelectedResource,
    ],
  );

  let startCrawl = React.useCallback(async () => {
    startCrawlUrl(crawlUrlInput.value);
  }, [crawlUrlInput, startCrawlUrl]);
  let crawlRandom = React.useCallback(async () => {
    try {
      let randomUrlR = await dispatch(fetchRandomUrl());
      // @ts-ignore
      let randomUrl = unwrapResult(randomUrlR);

      if (randomUrl) {
        return startCrawlUrl(randomUrl);
      }
    } catch (e) {
      setCrawlRes({
        response: `:( ${JSON.stringify(e)}`,
      });
    }
  }, [dispatch, startCrawlUrl]);

  let resDisplay = crawlRes ? (
    <>
      {crawlRes.crawlUrl ? <pre> url: {crawlRes.crawlUrl}</pre> : ""}
      <pre style={{ maxHeight: "150px", overflow: "auto" }}>
        {crawlRes.response}
      </pre>
    </>
  ) : null;
  let crawlButton = isManualCrawling ? (
    <div>crawling...</div>
  ) : (
    <button onClick={startCrawl}>crawl</button>
  );
  let [didYouMean, setDidYouMean] = React.useState("");
  let validateUrl = React.useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    setDidYouMean("");
    try {
      let { value } = evt.target;
      let validated = new URL(evt.target.value);
      if (validated.href != value) {
        setDidYouMean(validated.href);
      }
    } catch (e) {
      let { value } = evt.target;
      let suggest = value;
      if (suggest) {
        if (!/\s/.test(suggest)) {
          if (!/\.(?:.)/.test(suggest)) {
            suggest = `${suggest}.com/`;
          }

          if (!/http/.test(suggest)) {
            suggest = `http://${suggest}`;
          }
        }
      }
      if (suggest != value) {
        setDidYouMean(suggest);
      }
    }
  }, []);

  let didYouMeanDisplay;
  if (didYouMean) {
    didYouMeanDisplay = (
      <div>
        did you mean?{" "}
        <span
          style={actionLinkStyle}
          onClick={(e) => {
            crawlUrlInput.setValue(didYouMean);
            validateUrl({
              target: { value: didYouMean },
            } as ChangeEvent<HTMLInputElement>);
          }}
        >
          {didYouMean}
        </span>
      </div>
    );
  }

  let crawlRandomButton = !isManualCrawling ? (
    <div style={actionLinkStyle} onClick={crawlRandom}>
      random
    </div>
  ) : null;

  return (
    <Collapsible title="manual crawl">
      <div>
        <form onSubmit={startCrawl}>
          <div>
            <label>url</label>
            <input
              {...crawlUrlInput.bind}
              onInput={validateUrl}
              onBlur={validateUrl}
            />
            {didYouMeanDisplay}
          </div>
          <div>
            <label>depth</label>
            <input
              value={crawlDepth}
              min={0}
              max={2}
              type="number"
              onChange={(e) => {
                setCrawlRes(null);
                setCrawlDepth(parseInt(e.target.value) || 0);
              }}
            />
          </div>
          <div>
            <label style={{ userSelect: "none" }}>
              original host only
              <input {...originalHostOnly.bind} type="checkbox" />
            </label>
          </div>
          <br />
          {crawlButton}
          {crawlRandomButton}
        </form>
        {resDisplay}
      </div>
    </Collapsible>
  );
};
