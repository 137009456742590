import { useNavigate } from "@reach/router";
import { unwrapResult } from "@reduxjs/toolkit";
import * as _ from "lodash";
import * as React from "react";
import { CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "./config";
import { WebData, WebNode } from "./hooks/useWebData";
import { crawlSelectedResource } from "./redux/reducers/resources";
import { analyzeKeywords } from "./redux/reducers/textActionss";
import { RootState } from "./redux/store";
import { imageLinkTypes, SafeImage } from "./SafeImage";
import { actionLinkStyle, floatingBoxStyle } from "./WebViewer";

export const ResourceVisualizer = (p: { webData: WebData }) => {
  const dispatch = useDispatch();
  let { webData } = p;

  let { selectedResource, refetchResource, setSelectedResource } = webData;

  let exists = _.find(webData.resources, (r) => r.id == selectedResource);

  let crawled;
  if (exists?.info) {
    let { meta } = exists.info;
    if (meta) {
      crawled = !!meta.statusCode;
    }
  }

  let isCrawling = useSelector((s: RootState) => {
    return (
      selectedResource &&
      _.some(s.jobs.waitingForJobs, (j) => j.url == selectedResource)
    );
  });

  let doCrawl = React.useCallback(async () => {
    if (isCrawling || !selectedResource) {
      return false;
    }

    await dispatch(crawlSelectedResource());
  }, [dispatch, isCrawling, selectedResource]);

  const doAnalyze = React.useCallback(() => {
    if (exists) {
      dispatch(analyzeKeywords(exists.url));
    }
  }, [dispatch, exists]);
  const navigate = useNavigate();

  let navToTree = React.useCallback(() => {
    if (exists && exists.resourceId) {
      navigate(`/dom/${exists.resourceId}`);
    }
  }, [exists, navigate]);

  if (!selectedResource || !exists) {
    return null;
  }
  let actions = [];
  actions.push(
    <a href={selectedResource} target="_blank" rel="noreferrer" key="visit">
      visit
    </a>,
  );
  actions.push(
    <a
      href={`${apiUrl}/proxy/${selectedResource}`}
      target="_blank"
      rel="noreferrer"
      key="proxy"
    >
      proxy
    </a>,
  );
  actions.push(
    <a
      href={`http://destroytheinternets.com/site/?phys_origin=${encodeURIComponent(
        selectedResource,
      )}`}
      // rel="noopener"
      rel="noreferrer"
      target="_blank"
      key="dti"
    >
      dti
    </a>,
  );
  if (!isCrawling) {
    actions.push(
      <span style={actionLinkStyle} onClick={doCrawl} key="crawl">
        {crawled ? "recrawl" : "crawl"}
      </span>,
    );
  } else {
    actions.push("crawling...");
  }

  actions.push(
    <span style={actionLinkStyle} onClick={doAnalyze} key="analyze">
      analyze
    </span>,
  );

  let preview;
  if (
    imageLinkTypes.includes(exists.resourceType || "") ||
    !exists.resourceType
  ) {
    preview = (
      <SafeImage src={exists.url} tryEvenIfNoMatch={!!exists.resourceType} />
    );
  }

  if (exists?.resourceId) {
    actions.push(
      <span
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={navToTree}
        key="domTree"
      >
        dom tree
      </span>,
    );
  }

  return (
    <div
      style={{
        ...floatingBoxStyle,
        width: "33vw",
      }}
    >
      <pre
        style={{
          overflow: "auto",
        }}
      >
        {exists.url}
      </pre>
      <ResourceCard resource={exists} />
      {!crawled ? <div>not crawled</div> : null}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {actions}
      </div>
      {preview}
    </div>
  );
};

export const ResourceCard: React.FC<{ resource?: WebNode }> = ({
  resource,
}) => {
  if (!resource || !resource.info?.meta) {
    return null;
  }
  let { meta, favicon, metaImage } = resource.info;
  let { title, description } = meta;

  let titleDisplay = <strong>{title}</strong>;
  let faviconDisplay;
  if (favicon) {
    faviconDisplay = (
      <img
        src={favicon.to_url}
        style={{
          height: "auto",
          maxWidth: 50,
          maxHeight: 35,
          marginRight: 5,
        }}
      />
    );
  }

  let descriptionDisplay = description ? (
    <Description description={description} />
  ) : null;
  let metaImageDisplay;
  if (metaImage) {
    metaImageDisplay = (
      <img
        src={metaImage.to_url}
        style={{
          height: "auto",
          maxWidth: 125,
          maxHeight: 125,
          marginRight: 5,
        }}
      />
    );
  }

  return (
    <>
      {title || favicon ? (
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          {faviconDisplay}
          {titleDisplay}
        </div>
      ) : null}
      <div style={{ display: "flex", alignItems: "center" }}>
        {metaImageDisplay}
        {descriptionDisplay}
      </div>
    </>
  );
};

export const Description: React.FC<{ description: string }> = ({
  description,
}) => {
  let isShort = !description || description.length < 150;
  let [expanded, setExpanded] = React.useState(isShort);
  let style: CSSProperties = {
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    marginBottom: "15px",
  };
  let shadowStyle: CSSProperties = {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    margin: "0",
    padding: "30px 0",
  };

  /* "transparent" only works here because == rgba(0,0,0,0) */
  if (!expanded) {
    style = {
      ...style,
      maxHeight: "50px",
    };
    shadowStyle.backgroundImage =
      "linear-gradient(to bottom, transparent, white)";
  }

  return (
    <div style={style} onClick={() => setExpanded(!expanded)}>
      {description}
      <div style={shadowStyle}></div>
    </div>
  );
};
