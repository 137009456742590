import * as React from "react";
import { useInView } from "react-hook-inview";

export const EverWasInView: React.FC<{}> = (props) => {
  const [ref, inView] = useInView();
  const [everWasInView, setEverWasInview] = React.useState(inView);
  React.useEffect(() => {
    if (inView) {
      setEverWasInview(true);
    }
  }, [inView]);

  let display;
  if (everWasInView) {
    // display = React.Children.
    display = props.children;
    // display = <GroupingControlInner {...props} />;
  }
  return <div ref={ref}>{display}</div>;
};
