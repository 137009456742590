import * as _ from "lodash";
import { HTMLDocumentMetadata } from "./document-plugin";

export function getDocumentInfoDescription(info: HTMLDocumentMetadata) {
  return (
    _.get(info, "facebook.description.0") ||
    _.get(info, "twitter.description.0") ||
    _.get(info, "description") ||
    ""
  );
}
