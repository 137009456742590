// import { saveAs } from 'file-saver';
import { toPath } from "svg-points";

// random(), similar to Processing's
// If two parameters are passed, they are interpreted as the minimum and maximum of the desired range
// If only one parameter is passed, it is interpreted as the maximum, while zero is used as the minimum
export function random(min, max) {
  if (max === undefined) {
    max = min;
    min = 0;
  }

  if (typeof min !== "number" || typeof max !== "number") {
    throw new TypeError("Expected all arguments to be numbers");
  }

  return Math.random() * (max - min) + min;
}

// Converts a number from one range to another
export function map(value, originalLower, originalUpper, newLower, newUpper) {
  return (
    newLower +
    (newUpper - newLower) *
      ((value - originalLower) / (originalUpper - originalLower))
  );
}

// Returns an array of point coordinates (also arrays, with two entries) for points arranged in a circle
export function getCircleOfPoints(cx, cy, radius, resolution) {
  let angle, x, y;
  let points = [];

  for (let i = 0; i < resolution; i++) {
    angle = (2 * Math.PI * i) / resolution;
    x = cx + Math.floor(radius * Math.cos(angle));
    y = cy + Math.floor(radius * Math.sin(angle));

    points.push([x, y]);
  }

  return points;
}

// Creates an SVG document containing all of the visible geometry, then pushes it to the client
// - Triggered by pressing `e` in any sketch. See KeyboardInteractions.js for definition
// export function exportSVG(network) {
//   // Set up <svg> element
//   let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
//   svg.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns', 'http://www.w3.org/2000/svg');
//   svg.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
//   svg.setAttribute('width', window.innerWidth);
//   svg.setAttribute('height', window.innerHeight);
//   svg.setAttribute('viewBox', '0 0 ' + window.innerWidth + ' ' + window.innerHeight);

//   // Create <line>s for each branch segment
//   if(network.settings.ShowBranches) {
//     let nodeLinesGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');

//     for(let node of network.nodes) {
//       if(node.parent != null) {
//         let lineNode = `
//           <line
//             x1="${node.parent.position.x}"
//             y1="${node.parent.position.y}"
//             x2="${node.position.x}"
//             y2="${node.position.y}"
//             stroke="black"
//           />
//         `;

//         nodeLinesGroup.innerHTML += lineNode;
//       }
//     }

//     svg.appendChild(nodeLinesGroup);
//   }

//   // Create <path>s for bounds
//   if(network.settings.ShowBounds) {
//     if(network.bounds.length > 0) {
//       let boundsGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');

//       for(let bound of network.bounds) {
//         boundsGroup.appendChild(
//           getPathElFromPoints(bound.polygon)
//         );
//       }

//       svg.appendChild(boundsGroup);
//     }
//   }

//   // Create <path>s for obstacles
//   if(network.settings.ShowObstacles) {
//     if(network.obstacles.length > 0) {
//       let obstaclesGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');

//       for(let obstacle of network.obstacles) {
//         obstaclesGroup.appendChild(
//           getPathElFromPoints(obstacle.polygon)
//         );
//       }

//       svg.appendChild(obstaclesGroup);
//     }
//   }

//   // Generate the document as a Blob and force a download as a timestamped .svg file
//   const svgDoctype = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>';
//   const serializedSvg = (new XMLSerializer()).serializeToString(svg);
//   const blob = new Blob([svgDoctype, serializedSvg], { type: 'image/svg+xml;' })
//   saveAs(blob, 'venation-' + Date.now() + '.svg');
// }

// Create a <path> element with a properly-formatted `d` attribute containing all the coordinates of the passed points
function getPathElFromPoints(points) {
  let pointsString = "";

  for (let [index, point] of points.entries()) {
    pointsString += point[0] + "," + point[1];

    if (index < points.length - 1) {
      pointsString += " ";
    }
  }

  // Add closepath command to automatically draw line back to initial point
  pointsString += " " + points[0][0] + "," + points[0][1];

  let d = toPath({
    type: "polyline",
    points: pointsString,
  });

  let pathEl = document.createElementNS("http://www.w3.org/2000/svg", "path");
  pathEl.setAttribute("d", d);
  pathEl.setAttribute("style", "fill: none; stroke: black; stroke-width: 1");

  return pathEl;
}
