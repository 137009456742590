import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as _ from "lodash";
import { Screenshot } from "../../../../../extension/src/shared/types";
import { RootState } from "../../store";
import { reduxActionLog } from "../../utils";

// let maxreq = 10;
let currentrq = 0;
export const fetchMedia = createAsyncThunk<
  { media: LoadedMediaData },
  string,
  {
    state: RootState;
  }
>(
  "history/fetchMedia",
  //   @ts-ignore
  async (mediaId, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }
    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "GOT_MEDIA_BLOB_URL") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotMediaId = payload.mediaId;
            if (gotMediaId == mediaId) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              console.log("waiting", gotMediaId, mediaId);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, 2000);
      // console.log('would postmessag', mediaId);
      window.postMessage(
        {
          type: "GET_MEDIA_BLOB_URL",
          payload: {
            mediaId,
          },
        },
        "*",
      );
    });
  },
);

type LoadedMediaData = {
  id: string;
  blobUrl: string;
};
export const mediaSlice = createSlice({
  name: "media",
  initialState: {
    pending: [] as string[],
    failed: [] as string[],
    all: {} as Record<string, LoadedMediaData>,
  },
  reducers: {
    unloadMedia(state, action: PayloadAction<string>) {
      let mediaId = action.payload;
      let loaded = state.all[mediaId];
      let all = state.all;
      if (loaded) {
        all = { ...state.all };
        delete all[mediaId];
        let { blobUrl } = loaded;
        URL.revokeObjectURL(blobUrl);
      }

      let pending = _.without(state.pending, mediaId);
      let failed = _.without(state.failed, mediaId);

      return {
        ...state,
        pending,
        failed,
        all,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMedia.pending, (state, action) => {
      // console.log('fetch pending', action);
      let { pending } = state;
      pending = [...pending, action.meta.arg];

      return {
        ...state,
        pending,
      };
    });
    builder.addCase(fetchMedia.rejected, (state, action) => {
      // console.log('fetch rejected', action);
      let { arg: mediaId } = action.meta;
      let pending = _.without(state.pending, mediaId);
      let failed = [...state.failed, mediaId];
      return {
        ...state,
        pending,
        failed,
      };
    });
    builder.addCase(fetchMedia.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      console.log({ meta });

      let { arg: mediaId } = meta;

      let { media } = payload;

      let pending = _.without(state.pending, mediaId);
      let failed = _.without(state.failed, mediaId);

      return {
        ...state,
        pending,
        failed,
        all: {
          ...state.all,

          [mediaId]: {
            ...media,
          },
        },
      };
    });
  },
});
