import * as _ from "lodash";
import React from "react";
import { Collapsible } from "../../../app/src/shared/Collapsible";
import { GroupingControl } from "../../../app/src/WebHistory/GroupingControl";
import { PartialBy } from "../background/db";
import { Annotation, PageAction } from "../shared/types";
import { normalizeUrl } from "../utils/normalizedUrl";

export const pageActionUseCurrentNavigationId = "current";
export type CreateAnnotationData = PartialBy<
  Annotation,
  "timeStamp" | "id" | "navigationId" | "deviceId"
>;

const createAnnotationChrome = (anno: CreateAnnotationData) => {
  chrome.runtime.sendMessage({
    type: "CREATE_ANNOTATION",
    payload: anno,
  });
};

type CreateAnnotation = (a: CreateAnnotationData) => void;

export const CreateAnnotationForm: React.FC<{
  url?: string;
  createAnnotation?: CreateAnnotation;
  targetId: CreateAnnotationData["targetId"];
  targetType: CreateAnnotationData["targetType"];
  targetNavigationId: CreateAnnotationData["targetNavigationId"];
}> = ({ url, targetId, targetType, targetNavigationId, createAnnotation }) => {
  let textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const onSubmit = React.useCallback(
    (evt: React.MouseEvent) => {
      let doCreateAnnotation = createAnnotation || createAnnotationChrome;
      if (textAreaRef.current) {
        let urlToUse = url || window.location.href;
        urlToUse = normalizeUrl(urlToUse) || "";
        if (!urlToUse) {
          throw Error("invalid url");
        }

        let { value } = textAreaRef.current;
        if (value && value.trim()) {
          doCreateAnnotation({
            url: urlToUse,
            targetId,
            targetType,
            targetNavigationId,
            text: value,
          });
          textAreaRef.current.value = "";
        }
      }

      evt.preventDefault();
      evt.stopPropagation();
    },
    [targetId, targetType, targetNavigationId, createAnnotation, url],
  );

  return (
    <>
      <textarea data-test-id="wm-create-annotation-text" ref={textAreaRef} />
      <span
        data-test-id="wm-create-annotation"
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={onSubmit}
      >
        Click
      </span>
    </>
  );
};

type ActionInfo = Pick<PageAction, "id" | "navigationId">;

export const PageActionAnnotations: React.FC<{
  annotationUrl?: string;
  highlightActionToNode: { action: ActionInfo };
  existing: Annotation[] | undefined;
  targetType: Annotation["targetType"];
  title?: string;
  startExpanded?: boolean;
  noGroupsForAction?: boolean;
  noCollapse?: boolean;
  createAnnotation?: CreateAnnotation;
}> = ({
  annotationUrl,
  highlightActionToNode,
  existing,
  targetType,
  title,
  startExpanded,
  createAnnotation,
  noCollapse,
  noGroupsForAction,
}) => {
  const actionId = highlightActionToNode.action.id;
  const navigationId = highlightActionToNode.action.navigationId;

  const preventBubble = React.useCallback((evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  }, []);

  startExpanded = _.isBoolean(startExpanded) ? startExpanded : true;
  title = title || "annotations";

  let makeChildren = () => {
    let existingAnnotations = null;
    if (existing) {
      existingAnnotations = existing.map((action) => {
        let { timeStamp, text } = action;
        let groupForAnnotation = (
          <GroupingControl
            iconSize={"small"}
            nodeId={action.id}
            nodeType={"annotations"}
          />
        );
        return (
          <div
            key={action.id}
            style={{
              display: "flex",
              borderBottom: "2px solid darkgray",
            }}
          >
            {groupForAnnotation}
            <div>
              <pre>{text}</pre>
              <div>{new Date(timeStamp).toISOString()}</div>
            </div>
          </div>
        );
      });
    }
    return <div key="annotations">{existingAnnotations}</div>;
  };

  let contents;
  if (noCollapse) {
    contents = makeChildren();
  } else {
    contents = (
      <Collapsible
        title={title}
        startExpanded={startExpanded}
        makeChildren={makeChildren}
      ></Collapsible>
    );
  }

  let groupsForAction = noGroupsForAction ? null : (
    <GroupingControl nodeType={targetType} nodeId={actionId} />
  );

  return (
    <div className="wm-annotation wm-exclude" onClick={preventBubble}>
      <div
        style={{
          outline: "thin solid lightblue",
          maxWidth: "50vw",
        }}
      >
        {contents}
        {groupsForAction}
      </div>
      <CreateAnnotationForm
        url={annotationUrl}
        targetId={actionId}
        targetType={targetType}
        targetNavigationId={navigationId}
        createAnnotation={createAnnotation}
      />
    </div>
  );
};
