import React from "react";
import { fetchAllGroups } from "../reducers/history/groups";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { useLoadExtensionState } from "../../WebHistory/useLoadExtensionState";

export function useAllGroups() {
  const dispatch = useDispatch<AppDispatch>();
  const loadGroups = React.useCallback(() => {
    return dispatch(fetchAllGroups());
  }, [dispatch]);

  let groupsLoaded = useSelector(
    (s: RootState) =>
      !!(
        s.history.groups.all.length || s.history.groups.loaded.includes("all")
      ),
  );

  useLoadExtensionState({
    isLoaded: groupsLoaded,
    loadFromExtension: loadGroups,
  });
}
