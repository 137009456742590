import Defaults from "./Defaults";

export default class Attractor {
  constructor(position, ctx, settings = {}) {
    this.position = position; // vec2 of this attractor's position
    this.ctx = ctx; // global canvas context
    this.settings = Object.assign({}, Defaults, settings);

    this.influencingNodes = []; // references to nodes this attractor is influencing each frame
    this.fresh = true; // flag used to prevent attractors from being removed during first frame of Closed venation mode
  }

  draw() {
    // Draw the attraction zone
    if (this.settings.ShowAttractionZones) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        this.position.x,
        this.position.y,
        this.settings.AttractionDistance,
        this.settings.AttractionDistance,
        0,
        0,
        Math.PI * 2,
      );
      this.ctx.fillStyle = this.settings.Colors.AttractionZoneColor;
      this.ctx.fill();
    }

    // Draw the kill zone
    if (this.settings.ShowKillZones) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        this.position.x,
        this.position.y,
        this.settings.KillDistance,
        this.settings.KillDistance,
        0,
        0,
        Math.PI * 2,
      );
      this.ctx.fillStyle = this.settings.Colors.KillZoneColor;
      this.ctx.fill();
    }

    // Draw the attractor
    if (this.settings.ShowAttractors) {
      this.ctx.beginPath();
      this.ctx.ellipse(
        this.position.x,
        this.position.y,
        1,
        1,
        0,
        0,
        Math.PI * 2,
      );
      this.ctx.fillStyle = this.settings.Colors.AttractorColor;
      this.ctx.fill();
    }
  }
}
