export const Light = {
  BackgroundColor: "rgba(255,255,255,1)",
  AttractorColor: "rgba(0,0,0,.5)",
  BranchColor: "rgba(0,0,0,1)",
  TipColor: "rgba(255,0,0,1)",
  AttractionZoneColor: "rgba(0,255,0,.002)",
  KillZoneColor: "rgba(255,0,0,.4)",
  InfluenceLinesColor: "rgba(0,0,255,1)",
  BoundsFillColor: "rgba(0,0,0,.1)",
  BoundsBorderColor: "rgba(0,0,0,.1)",
  ObstacleFillColor: "rgba(0,0,0,.7)",
};

export const Dark = {
  BackgroundColor: "rgba(0,0,0,.9)",
  AttractorColor: "rgba(255,255,255,.5)",
  BranchColor: "rgba(255,255,255,1)",
  TipColor: "rgba(0,255,255,1)",
  AttractionZoneColor: "rgba(255,255,255,.002)",
  KillZoneColor: "rgba(255,0,0,.4)",
  InfluenceLinesColor: "rgba(255,255,255,.2)",
  BoundsFillColor: "rgba(255,255,255,0)",
  BoundsBorderColor: "rgba(255,255,255,.05)",
  ObstacleFillColor: "rgba(255,255,255,.2)",
};

export const Realistic = {
  BackgroundColor: "rgba(255,255,255,1)",
  AttractorColor: "rgba(255,255,255,1)",
  BranchColor: "rgba(255,255,255,.6)",
  // BranchColor: 'rgba(0,0,0,.2)',
  TipColor: "rgba(255,0,0,1)",
  AttractionZoneColor: "rgba(0,255,0,.002)",
  KillZoneColor: "rgba(255,0,0,.4)",
  InfluenceLinesColor: "rgba(0,0,255,1)",
  BoundsFillColor: "rgba(61,166,12,1)",
  BoundsBorderColor: "rgba(255,255,255,1)",
  ObstacleFillColor: "rgba(255,255,255,1)",
};

export const Custom = {
  BackgroundColor: "rgb(242,242,242)",
  AttractorColor: "rgba(255,255,255,.6)",
  BranchColor: "rgba(255,255,255,1)",
  InfluenceLinesColor: "rgba(255,255,255,.3)",
  // BoundsFillColor: 'rgb(61,85,136)',
  // BoundsBorderColor: 'rgb(61,85,136)'
  BoundsFillColor: "rgb(210, 81, 94)",
  BoundsBorderColor: "rgb(210, 81, 94)",
};
