import { JobDescriptor, JobId } from "./jobs";
import * as _ from "lodash";

import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  combineReducers,
  unwrapResult,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { apiUrl } from "../../config";
import {
  HostSummaryResponse,
  WebNode,
  linkTypeToResourceType,
  WebLink,
  pageLinkTypes,
} from "../../hooks/useWebData";
import { actions } from "./hosts";
import { analyzeKeywords, RawKeywords, addKeywords } from "./textActionss";
import { ResourceResponse } from "../../DOMViewer";
import jobsSlice from "./jobs";

export type WebLinkStored = WebLink & {
  _id: string;
  source: string;
  target: string;
  linkType: string;
  hosts: string[];
  meta?: {
    title?: string;
    text?: string;
  };
};

type ForHostResourceType = {
  id: number;
  url: string;
  host: string;
  link_type?: string;
  crawled: boolean;
};

export const fetchForHost = createAsyncThunk<
  WebNode[],
  string | null,
  {
    state: RootState;
  }
>(
  "resources/fetchForHost",
  async (selectedHost, { dispatch, getState, requestId }) => {
    if (selectedHost) {
      console.log("fetch", selectedHost);
      let { fetchedForHosts } = getState().resources;

      if (fetchedForHosts[selectedHost] != requestId) {
        return [] as WebNode[];
      }
      let allResources: ForHostResourceType[];

      allResources = await (
        await fetch(`${apiUrl}/resources/for-host/${selectedHost}`)
      ).json();

      let resourceNodes = allResources.map((resource: ForHostResourceType) => {
        let resourceType = linkTypeToResourceType(resource.link_type);
        return {
          id: resource.url,
          url: resource.url,
          name: `${resource.url}`,
          nodeType: "resource",
          host: resource.host,
          resource_count: 1,
          resourceId: resource.id,
          resourceType,
          crawled: resource.crawled,
        };
      });
      return resourceNodes;
    }
    return [];
  },
);

export const crawlSelectedResource = createAsyncThunk<
  string | void,
  undefined,
  {
    state: RootState;
  }
>(
  "resources/crawlSelectedResource",
  async (_unused, { dispatch, getState, requestId }) => {
    let { resources } = getState();
    let { selected } = resources.filters;
    console.log("crawl", selected);
    if (selected) {
      let crawledUrlResult = await dispatch(crawlResource({ url: selected }));
      let crawledUrl = unwrapResult(crawledUrlResult);

      if (crawledUrl) {
        ({ resources } = getState());
        let selectedResource = resources.filters.selected;
        if (crawledUrl != selectedResource && selectedResource == selected) {
          dispatch(resourceActions.selectResource(crawledUrl));
        }
      }
      return crawledUrl;
    } else {
      return;
    }
  },
);

export const crawlResource = createAsyncThunk<
  string | void,
  {
    url: string;
    crawlOptions?: CrawlOptions;
  },
  {
    state: RootState;
  }
>(
  "resources/crawlResource",
  async ({ url, crawlOptions }, { dispatch, getState, requestId }) => {
    console.log("crawl", url);
    if (url) {
      let crawledUrl = await doCrawlResource(url, {
        crawlOptions: { maxDepth: 0, ...crawlOptions },
        onJobComplete: (jobDescription) => {
          dispatch(jobsSlice.actions.jobComplete(jobDescription));
          if (jobDescription.queueName == "crawl-url") {
            let { url: resolvedUrl } = jobDescription;
            if (resolvedUrl) {
              dispatch(refetchResource(resolvedUrl));
            }
          }
        },
        onJobFail: (jobDescription) => {
          dispatch(jobsSlice.actions.jobComplete(jobDescription));
        },
        onJobWait: (...args) =>
          dispatch(jobsSlice.actions.waitingForJob(...args)),
      });

      if (crawledUrl) {
        await dispatch(refetchResource(crawledUrl));
      }
      return crawledUrl;
    } else {
      return;
    }
  },
);

export const fetchRandomUrl = createAsyncThunk<
  string | void,
  undefined,
  {
    state: RootState;
  }
>(
  "resources/fetchRandomUrl",
  async (_none, { dispatch, getState, requestId }) => {
    let { url } = await (await fetch(`${apiUrl}/resources/random`)).json();

    return url;
  },
);

type CrawlOptions = {
  maxDepth?: number;
  constrainHost?: boolean;
};

export async function doCrawlResource(
  selectedResource: string,
  opts: {
    onQueued?: () => void;
    onJobWait?: (j: JobDescriptor) => void;
    onJobComplete?: (j: JobDescriptor) => void;
    onJobFail?: (j: JobDescriptor) => void;
    crawlOptions?: CrawlOptions;
  } = {},
) {
  let { onQueued, onJobWait, onJobComplete, onJobFail, crawlOptions } = opts;
  let maxDepth = 0;
  let constrainHost = 1;
  if (crawlOptions) {
    if (crawlOptions.maxDepth) {
      maxDepth = crawlOptions.maxDepth;
    }

    if (crawlOptions.constrainHost === false) {
      constrainHost = 0;
    }
  }
  let res: {
    jobId: string;
  };
  // try {
  res = await (
    await fetch(
      `${apiUrl}/crawl/args/maxDepth=${maxDepth}&constrainHost=${constrainHost}/${selectedResource}`,
    )
  ).json();

  if (onQueued) {
    onQueued();
  }

  let { jobId } = res;
  if (!jobId) {
    throw new Error("no job id");
  }

  if (onJobWait) {
    onJobWait({
      queueName: "crawl-url",
      jobId,
      url: selectedResource,
    });
  }
  let jobStatus = await waitForJobComplete("crawl-url", jobId);
  let resolvedUrl = _.get(jobStatus, "returnvalue[0].url") || selectedResource;
  if (jobStatus) {
    if (jobStatus.returnvalue && jobStatus.returnvalue.length) {
      if (onJobComplete) {
        onJobComplete({
          queueName: "crawl-url",
          jobId,
          url: resolvedUrl,
        });
      }
      const returnValue = jobStatus.returnvalue[0];
      if (returnValue.dependents && returnValue.dependents.length) {
        console.log("wait for", returnValue.dependents.length, "tasks");
        await Promise.all(
          returnValue.dependents.map(async ({ jobId, queueName }) => {
            if (onJobWait) {
              onJobWait({ queueName, jobId, url: selectedResource });
            }
            await waitForJobComplete(queueName, jobId);
            console.log(queueName, jobId, "completed");
            if (onJobComplete) {
              onJobComplete({ queueName, jobId, url: selectedResource });
            }
          }),
        );
      }

      return resolvedUrl;
    } else {
      const { failedReason } = jobStatus;
      if (onJobFail) {
        onJobFail({
          queueName: "crawl-url",
          jobId,
          url: resolvedUrl,
        });
      }
      if (failedReason) {
        return Promise.reject(failedReason);
      }
      console.warn(selectedResource, jobStatus);
      return selectedResource;
    }
  } else {
    return selectedResource;
  }
  // } catch (e) {
  //   console.log(e);
  // }
}

const checkJobStatus = async (queueName: string, jobId: JobId) =>
  (await fetch(`${apiUrl}/jobs/job/${queueName}/${jobId}`)).json();

type JobStatus = {
  completed: boolean;
  returnvalue: [
    {
      url: string;
      dependents: {
        jobId: number | string;
        queueName: string;
      }[];
    },
  ];
  failedReason?: string;
};
const waitForJobComplete = async (queueName: string, jobId: JobId) => {
  let completed = false;

  let jobStatus: JobStatus | null = null;
  while (!completed) {
    await delayP(1000);
    jobStatus = await checkJobStatus(queueName, jobId);
    completed = !!jobStatus?.completed;
  }

  return jobStatus;
};

const delayP = async (delay: number) =>
  new Promise((resolve, reject) => setTimeout(resolve, delay));
export const refetchResource = createAsyncThunk<
  Promise<unknown>,
  string,
  {
    state: RootState;
  }
>(
  "resources/refetchResource",
  async (url, { dispatch, getState, requestId }) => {
    return Promise.all([
      dispatch(
        fetchLinksForResource({
          url,
          force: true,
        }),
      ),
      dispatch(fetchNodeMeta({ url, force: true })),
    ]);
  },
);

export type NodeMeta = Partial<{
  title: string;
  description: string;
  headers: Record<string, string>;
  statusCode: number;
  keywords: {
    results?: RawKeywords;
  };
}>;

export function nodeMetaFetched(n: WebNode) {
  return !!n.info;
}
export function linkMetaFetched(n: WebLinkStored) {
  return !!n.meta;
}

export type NodeInfo = {
  meta?: NodeMeta;
  favicon?: FromLinkType;
  metaImage?: FromLinkType;
  forResource?: ResourceResponse;
};

export const fetchNodeMeta = createAsyncThunk<
  NodeInfo,
  {
    url: string | null;
    force?: boolean;
  },
  {
    state: RootState;
  }
>(
  "resources/fetchNodeMeta",
  async ({ url, force }, { dispatch, getState, requestId }) => {
    let resourceUrl = url;
    if (resourceUrl) {
      console.log("fetchNodeMeta", resourceUrl);
      let { all } = getState().resources;

      if (!force) {
        let exists = _.find(all, (n) => n.id == resourceUrl);
        if (exists && nodeMetaFetched(exists)) {
          return exists.info || {};
        }
      }

      let nodeMetaResponse: NodeInfo;

      nodeMetaResponse = await (
        await fetch(
          `${apiUrl}/resources/args/followRedirects=false/meta/${resourceUrl}`,
        )
      ).json();
      let fetchedMeta = nodeMetaResponse;
      if (fetchedMeta?.meta?.keywords?.results) {
        dispatch(
          addKeywords({
            resourceUrl,
            keywords: fetchedMeta.meta.keywords.results,
          }),
        );
      }
      return fetchedMeta || {};
    }
    return {};
  },
);

export const fetchLinkMeta = createAsyncThunk<
  NodeMeta | undefined,
  {
    resourceLinkId: number;
    force?: boolean;
  },
  {
    state: RootState;
  }
>(
  "resources/fetchLinkMeta",
  async ({ resourceLinkId, force }, { dispatch, getState, requestId }) => {
    if (resourceLinkId) {
      console.log("fetchLinkMeta", resourceLinkId);
      let { links } = getState().resources;

      if (!force) {
        let exists = _.find(links, (n) => n.resourceLinkId == resourceLinkId);
        if (exists && linkMetaFetched(exists)) {
          return exists.meta || {};
        }
      }

      let nodeMetaResponse: {
        link_meta: WebLinkStored["meta"];
      };
      try {
        nodeMetaResponse = await (
          await fetch(`${apiUrl}/resources/links/meta/${resourceLinkId}`)
        ).json();
        let fetchedMeta = nodeMetaResponse.link_meta;
        return fetchedMeta || undefined;
      } catch (e) {
        console.warn("fetchlinkmeta", resourceLinkId, e);
        return undefined;
      }
    }
    return undefined;
  },
);

type FromLinkType = {
  id: number;
  to_url: string;
  host: string;
  link_type: string;
  to_id?: number;
};
type ToLinkType = {
  id: number;
  from_url: string;
  host: string;
  link_type: string;
  from_id?: number;
};
type LinksForResourceResponse = {
  resource: {
    id: number;
    url: string;
    host: string;
  };
  fromLinks: FromLinkType[];
  toLinks: ToLinkType[];
};
export const fetchLinksForResource = createAsyncThunk<
  LinksForResourceResponse | undefined,
  {
    url: string | null;
    force?: boolean;
  },
  {
    state: RootState;
  }
>(
  "resources/fetchLinksForResource",
  async ({ url, force }, { dispatch, getState, requestId }) => {
    let selectedResource = url;

    if (selectedResource) {
      let { fetchedForResources } = getState().resources;

      if (!force && fetchedForResources[selectedResource] != requestId) {
        return Promise.reject("already fetching");
      }

      let allResources: LinksForResourceResponse;
      allResources = await (
        await fetch(
          `${apiUrl}/resources/args/links/fromResponse=latest/${selectedResource}`,
        )
      ).json();

      return allResources;
    }
    return;
  },
);

const allResourcesSlice = createSlice({
  name: "resources",
  initialState: [] as WebNode[],
  reducers: {
    addKeywords: (
      state,
      action: PayloadAction<{
        resourceUrl: string;
        keywords: string[];
      }>,
    ) => {
      let { keywords, resourceUrl } = action.payload;

      if (keywords) {
        let existing = _.find(state, (n) => n.id == resourceUrl);
        let toAdd: WebNode[] = [];

        if (!existing) {
          let host = "";
          try {
            let parsed = new URL(resourceUrl);
            host = parsed.host;
          } catch (e) {}
          toAdd.push({
            id: resourceUrl,
            name: resourceUrl,
            url: resourceUrl,
            nodeType: "resource",
            host: host,
            // resourceId,
            // resourceType,
          });
        }

        toAdd = toAdd.concat(
          keywords.map((kw) => {
            return {
              id: kw,
              name: kw,
              nodeType: "keyword",
              resourceType: "keyword",
              url: "keyword",
              host: "keyword",
            };
          }),
        );

        return _.chain(state)
          .concat(toAdd)
          .uniqBy((n) => n.id)
          .value();
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchForHost.fulfilled, (state, action) => {
      console.log("fetch fulfilled", action);
      let { meta, payload } = action;
      let resourceNodes = payload;
      let existing = state;
      resourceNodes = resourceNodes.filter(
        (newResource) =>
          !_.find(
            existing,
            (existingResource) => existingResource.url == newResource.url,
          ),
      );
      return existing.concat(resourceNodes);
    });
    builder.addCase(fetchLinksForResource.fulfilled, (state, action) => {
      console.log("resources fetchLinksForResource fulfilled", action);
      let { meta, payload } = action;
      let allResources = payload;
      if (!allResources) {
        return state;
      }
      let selectedResource = action.meta.arg.url;
      let existing = state;

      let linkedToResources = allResources.toLinks.map((l) => {
        let { host, from_url, link_type } = l;
        let url = from_url;
        let resourceType = pageLinkTypes.includes(link_type)
          ? "page"
          : link_type;

        let resourceId = l.from_id;
        return {
          id: url,
          name: url,
          url,
          nodeType: "resource",
          host: host,
          resourceId,
          // resourceType,
        } as WebNode;
        //   linkType: link_type,
        // };
      });

      let linkedFromResources = allResources.fromLinks.map((l) => {
        let { host, to_url, link_type } = l;
        let url = to_url;
        let resourceType = linkTypeToResourceType(link_type);

        let resourceId = l.to_id;
        return {
          id: url,
          name: url,
          url,
          nodeType: "resource",
          host,
          resourceType,
          resourceId,
        } as WebNode;
      });

      let parsed;
      try {
        parsed = new URL(selectedResource || "");
      } catch (e) {}
      let fetchedForResource = [];
      if (parsed) {
        let resourceId = action.payload?.resource?.id;
        let resourceType = "page";
        fetchedForResource.push({
          id: parsed.href,
          name: parsed.href,
          url: parsed.href,
          nodeType: "resource",
          host: parsed.host,
          resourceType,
          resourceId,
        });
      }

      linkedToResources = _.chain(linkedToResources)
        .concat(linkedFromResources)
        .concat(fetchedForResource)
        .uniqBy((r) => r.url)
        .filter(
          (newResource) =>
            !_.find(
              existing,
              (existingResource) => existingResource.url == newResource.url,
            ),
        )
        .value();
      return existing.concat(linkedToResources);
    });

    builder.addCase(fetchNodeMeta.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      let resourceNodes = payload;
      let all = state;
      let { url } = action.meta.arg;
      let existingIndex = _.findIndex(all, (n) => n.id == url);
      if (_.isNumber(existingIndex)) {
        let existing = all[existingIndex];
        existing = {
          ...existing,
          info: action.payload,
        };

        if (!existing.resourceId) {
          if (action.payload.forResource?.url == existing.url) {
            let { id } = action.payload.forResource;
            existing.resourceId = id;
          } else {
            console.log("no resource id, but urls do not match not updating");
          }
        }

        all[existingIndex] = existing;
      } else {
        console.warn("no state node found for", url);
      }
      return all;
    });

    // builder.addCase(analyzeKeywords.fulfilled, (state, action) => {
    //   let keywords = action.payload;
    //   if (keywords) {
    //     let toAdd = keywords.map((kw) => {
    //       return {
    //         id: kw,
    //         name: kw,
    //         nodeType: 'keyword',
    //         resourceType: 'keyword',
    //         url: 'keyword',
    //         host: 'keyword',
    //       };
    //     });

    //     return _.chain(state)
    //       .concat(toAdd)
    //       .uniqBy((n) => n.id)
    //       .value();
    //   }
    // });
  },
});
const linksSlice = createSlice({
  name: "links",
  initialState: [] as WebLinkStored[],
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchForHost.fulfilled, (state, action) => {
      console.log("fetch fulfilled", action);
      let { meta, payload } = action;
      let { arg: maybeForHost, requestId } = meta;
      if (!maybeForHost) {
        return state;
      }

      let forHost = maybeForHost;
      let resourceNodes = payload;
      let existing = state;

      let connectToHost = resourceNodes.map((resource) => {
        return {
          _id: `${forHost}-${resource.url}`,
          source: forHost,
          target: resource.url,
          linkType: "hostLink",
          hosts: [forHost],
        };
      });

      let merged = _.chain(existing)
        .concat(connectToHost)
        .uniqBy((l) => `${l.source}-${l.target}`)
        .value();
      return merged;
    });

    builder.addCase(fetchLinksForResource.fulfilled, (state, action) => {
      // console.log('links fetchLinksForResource fulfilled', action);
      let { meta, payload } = action;
      let allResources = payload;
      if (!allResources) {
        return state;
      }
      let selectedResource = allResources.resource
        ? allResources.resource.url
        : action.meta.arg.url;
      if (!selectedResource) {
        return;
      }
      let parsed: URL | undefined;
      try {
        parsed = new URL(selectedResource || "");
      } catch (e) {
        parsed = undefined;
      }

      let parsedHost = parsed ? parsed.host : "???";
      let parsedUrl = parsed ? parsed.href : selectedResource;
      let existing = state;

      let linkedFromResources = allResources.fromLinks.map((l) => {
        let { id: resourceLinkId, host, to_url, link_type } = l;
        let url = to_url;
        let resourceType = linkTypeToResourceType(link_type);

        let resourceId = l.to_id;
        return {
          node: {
            id: url,
            name: url,
            url,
            nodeType: "resource",
            host,
            resourceType,
            resourceId,
          } as WebNode,
          linkType: link_type,
          resourceLinkId,
        };
      });
      let linkedFromConnections = linkedFromResources.map((r) => {
        let { node, linkType, resourceLinkId } = r;
        let { id } = node;
        return {
          _id: `${parsedUrl}-${id}`,
          source: parsedUrl,
          target: id,
          linkType: linkType || "resourceLink",
          hosts: [parsedHost, node.host],
          resourceLinkId,
        };
      });

      let linkedToResources = allResources.toLinks.map((l) => {
        let { id: resourceLinkId, host, from_url, link_type } = l;
        let url = from_url;
        let resourceType = pageLinkTypes.includes(link_type)
          ? "page"
          : link_type;

        let resourceId = l.from_id;
        return {
          node: {
            id: url,
            name: url,
            url,
            nodeType: "resource",
            host: host,
            resourceId,
            // resourceType,
          } as WebNode,
          linkType: link_type,
          resourceLinkId,
        };
      });
      let fetchedForResource = [];
      if (parsed) {
        let resourceId = action.payload?.resource?.id;
        let resourceType = "page";
        fetchedForResource.push({
          node: {
            id: parsed.href,
            name: parsed.href,
            url: parsed.href,
            nodeType: "resource",
            host: parsed.host,
            resourceType,
            resourceId,
          } as WebNode,
          linkType: "hostLink",
          resourceLinkId: 0,
        });
      }

      let linksToHost = _.chain(linkedToResources)
        .concat(linkedFromResources)
        .concat(fetchedForResource)
        // .concat([
        //   {
        //     node: {
        //       host: parsed.host,
        //       url: selectedResource,
        //     } as WebNode,
        //     linkType: 'something',
        //   },
        // ])
        .map(({ node }) => {
          // TODO check that host exists
          // if (
          //   !_.find(newHostNodes, { host: node.host }) &&
          //   !_.find(hosts, { host: node.host })
          // ) {
          //   newHostNodes.push({
          //     id: node.host,
          //     name: `${node.host} ???`,
          //     nodeType: 'host',
          //     host: node.host,
          //     url: node.host,
          //     resource_count: 1,
          //   });
          // }
          if (!node.host || !node.url) {
            console.log("skip link", node);
            return;
          }

          return {
            _id: `${node.host}-${node.url}`,
            source: node.host,
            target: node.url,
            linkType: "hostLink",
            hosts: [node.host],
          };
        })
        .filter((n) => !!n)
        .value() as WebLinkStored[];
      let linkedToConnections = linkedToResources.map((r) => {
        let { node, linkType, resourceLinkId } = r;
        let { id } = node;
        return {
          _id: `${id}-${selectedResource}`,
          source: id,
          target: selectedResource || "",
          linkType: linkType || "resourceLink",
          hosts: [node.host, parsedHost],
          resourceLinkId,
        };
      });

      return _.chain(existing)
        .concat(linkedFromConnections)
        .concat(linkedToConnections)
        .concat(linksToHost)
        .uniqBy((l) => `${l.source}-${l.target}`)
        .value();
    });

    builder.addCase(allResourcesSlice.actions.addKeywords, (state, action) => {
      let { payload } = action;
      let { resourceUrl, keywords } = payload;

      let toAdd = keywords.map((kw) => {
        return {
          _id: `${resourceUrl}-${kw}`,
          source: resourceUrl,
          target: kw,
          linkType: "keyword",
          hosts: [],
        };
      });

      return _.chain(state)
        .concat(toAdd)
        .uniqBy((l) => `${l.source}-${l.target}`)
        .value();
    });
    builder.addCase(fetchLinkMeta.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      let linkMeta = payload;
      if (!linkMeta) {
        return state;
      }
      let all = state;
      let { resourceLinkId } = action.meta.arg;
      let existingIndex = _.findIndex(
        all,
        (n) => n.resourceLinkId == resourceLinkId,
      );
      if (_.isNumber(existingIndex)) {
        let existing = all[existingIndex];
        existing = {
          ...existing,
          meta: action.payload,
        };
        all[existingIndex] = existing;
      }
      return all;
    });
  },
});
const fetchedForHostsSlice = createSlice({
  name: "resources.fetchedForHosts",
  initialState: {} as Record<string, string>,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchForHost.pending, (state, action) => {
      // console.log('fetch pending', action);
      let { meta } = action;
      let { arg: forHost, requestId } = meta;
      if (!forHost || state[forHost]) {
        return state;
      }
      return {
        ...state,
        [forHost]: requestId,
      };
    });
    builder.addCase(fetchForHost.rejected, (state, action) => {
      // console.log('fetch rejected', action);
      let { meta } = action;
      let { arg: forHost, requestId } = meta;
      if (!forHost) {
        return state;
      }
      let newState = { ...state };
      delete newState[forHost];
      return newState;
    });
    builder.addCase(fetchForHost.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
    });
  },
});

const fetchedForResources = createSlice({
  name: "resources.fetchedForResources",
  initialState: {} as Record<string, string>,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchLinksForResource.pending, (state, action) => {
      // console.log('fetch pending', action);
      let { meta } = action;
      let { arg, requestId } = meta;
      let { url: forHost } = arg;
      if (!forHost || state[forHost]) {
        return state;
      }
      return {
        ...state,
        [forHost]: requestId,
      };
    });
    builder.addCase(fetchLinksForResource.rejected, (state, action) => {
      // console.log('fetch rejected', action);
      let { meta } = action;
      let { arg, requestId } = meta;
      let { url: forHost } = arg;
      if (!forHost) {
        return state;
      }
      let newState = { ...state };
      delete newState[forHost];
      return newState;
    });
    builder.addCase(fetchLinksForResource.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
    });
  },
});

const excludedLinkTypeToResourceType = (linkType: string) => {
  let resourceType = linkType;
  if (resourceType == "hostLink") {
    resourceType = "host";
  } else if (resourceType == "anchor") {
    return;
  }

  return resourceType;
};

const resourceFiltersSlice = createSlice({
  name: "resources.resourceFiltersSlice",
  initialState: {
    selected: "",
    excludedLinkTypes: [] as string[],
    excludedResourceTypes: [] as string[],
  },
  reducers: {
    selectResource: (state, action: PayloadAction<string>) => {
      let selected = action.payload;
      state.selected = selected;
    },
    excludeLinkType: (state, action: PayloadAction<string>) => {
      let linkType = action.payload;
      console.log("should exclude", linkType);
      state.excludedLinkTypes = _.uniq(
        state.excludedLinkTypes.concat([linkType]),
      );

      let resourceType = excludedLinkTypeToResourceType(linkType);
      if (resourceType) {
        state.excludedResourceTypes = _.uniq(
          state.excludedResourceTypes.concat([resourceType]),
        );
      }
      return state;
    },
    includeLinkType: (state, action: PayloadAction<string>) => {
      let linkType = action.payload;
      console.log("should inclkude", linkType);
      state.excludedLinkTypes = _.without(state.excludedLinkTypes, linkType);

      let resourceType = excludedLinkTypeToResourceType(linkType);
      if (resourceType) {
        state.excludedResourceTypes = _.without(
          state.excludedResourceTypes,
          resourceType,
        );
      }

      return state;
    },
  },

  extraReducers: (builder) => {},
});

export const resourceActions = {
  ...resourceFiltersSlice.actions,
  ...allResourcesSlice.actions,
};

export default combineReducers({
  all: allResourcesSlice.reducer,
  links: linksSlice.reducer,
  fetchedForHosts: fetchedForHostsSlice.reducer,
  fetchedForResources: fetchedForResources.reducer,
  filters: resourceFiltersSlice.reducer,
});
