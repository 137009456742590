import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as _ from "lodash";
import { Screenshot } from "../../../../../extension/src/shared/types";
import { RootState } from "../../store";
import { reduxActionLog } from "../../utils";

// let maxreq = 10;
let currentrq = 0;
export const getScreenshot = createAsyncThunk<
  { screenshot: Screenshot },
  string,
  {
    state: RootState;
  }
>(
  "history/getScreenshot",
  //   @ts-ignore
  async (screenshotId, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }
    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "GOT_SCREENSHOT") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotScreenshotId = payload.screenshot.id;
            if (gotScreenshotId == screenshotId) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              console.log("waiting", gotScreenshotId, screenshotId);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, 2000);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_SCREENSHOT",
          payload: {
            screenshotId,
          },
        },
        "*",
      );
    });
  },
);
export const screenshotsSlice = createSlice({
  name: "screenshots",
  initialState: {
    pending: [] as string[],
    failed: [] as string[],
    all: {} as Record<string, Screenshot>,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getScreenshot.pending, (state, action) => {
      // console.log('fetch pending', action);
      let { pending } = state;
      pending = [...pending, action.meta.arg];

      return {
        ...state,
        pending,
      };
    });
    builder.addCase(getScreenshot.rejected, (state, action) => {
      // console.log('fetch rejected', action);
      let { arg: screenshotId } = action.meta;
      let pending = _.without(state.pending, screenshotId);
      let failed = [...state.failed, screenshotId];
      return {
        ...state,
        pending,
        failed,
      };
    });
    builder.addCase(getScreenshot.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      console.log({ meta });

      let { arg: screenshotId } = meta;

      let { screenshot } = payload;

      let pending = _.without(state.pending, screenshotId);
      let failed = _.without(state.failed, screenshotId);

      return {
        ...state,
        pending,
        failed,
        all: {
          ...state.all,

          [screenshotId]: {
            ...screenshot,
          },
        },
      };
    });
  },
});
