import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { CreateAnnotationData } from "../../../../../extension/src/content_script/PageActionAnnotations";
import {
  Annotation,
  AppState,
  CompletedTabNavigation,
  PageAction,
} from "../../../../../extension/src/shared/types";
import { RootState } from "../../store";
import { reduxActionLog } from "../../utils";
import { extensionRequestTimeout } from "./constants";
import { toggleFilterForGroup } from "./groups";

let currentrq = 0;
export const fetchHistoryForUrl = createAsyncThunk<
  { state: AppState; url: string },
  string,
  {
    rejectValue: string;
  }
>(
  "history/fetchHistoryForUrl",
  async (forUrl, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }

    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "GOT_STATE_FOR_URL") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotUrl = payload.url;
            if (gotUrl == forUrl) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              // console.log("waiting", gotUrl, forUrl);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_STATE_FOR_URL",
          payload: {
            url: forUrl,
          },
        },
        "*",
      );
    });
  },
);

export const fetchPageAction = createAsyncThunk<
  { pageAction: PageAction; pageActionId: string },
  string,
  {
    rejectValue: string;
  }
>(
  "history/fetchPageAction",
  async (pageActionId, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }

    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "GOT_PAGE_ACTION") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotPageActionId = payload.pageActionId;
            if (gotPageActionId == pageActionId) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              console.log("waiting", gotPageActionId, pageActionId);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_PAGE_ACTION",
          payload: {
            pageActionId,
          },
        },
        "*",
      );
    });
  },
);

export const fetchAnnotationsForUrl = createAsyncThunk<
  { state: { annotations: Annotation[] }; url: string },
  string,
  {
    rejectValue: string;
  }
>(
  "history/fetchAnnotationsForUrl",
  async (forUrl, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }

    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "GOT_ANNOTATIONS_FOR_URL") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotUrl = payload.url;
            if (gotUrl == forUrl) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              // console.log("waiting", gotUrl, forUrl);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_ANNOTATIONS_FOR_URL",
          payload: {
            url: forUrl,
          },
        },
        "*",
      );
    });
  },
);

export const fetchAnnotationsForNavigationId = createAsyncThunk<
  { state: { annotations: Annotation[] }; url: string },
  string,
  {
    rejectValue: string;
  }
>(
  "history/fetchAnnotationsForNavigationId",
  async (navigationId, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }

    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (
          event.data.type &&
          event.data.type == "GOT_ANNOTATIONS_FOR_NAVIGATION_ID"
        ) {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotUrl = payload.navigationId;
            if (gotUrl == navigationId) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              // console.log("waiting", gotUrl, navigationId);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_ANNOTATIONS_FOR_NAVIGATION_ID",
          payload: {
            navigationId,
          },
        },
        "*",
      );
    });
  },
);
export const fetchHistoryForNavigationId = createAsyncThunk<
  { state: AppState; navigationId: string },
  string,
  {
    state: RootState;
  }
>(
  "history/fetchHistoryForNavigationId",
  async (navigationId, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }

    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (
          event.data.type &&
          event.data.type == "GOT_STATE_FOR_NAVIGATION_ID"
        ) {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotNavigationId = payload.navigationId;
            if (gotNavigationId == navigationId) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              console.log("waiting", gotNavigationId, navigationId);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_STATE_FOR_NAVIGATION_ID",
          payload: {
            navigationId,
          },
        },
        "*",
      );
    });
  },
);

export const fetchResultsForSearch = createAsyncThunk<
  { states: AppState[]; term: string },
  string,
  {
    state: RootState;
  }
>(
  "history/fetchResultsForSearch",
  async (term, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }

    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "GOT_SEARCH_RESULTS") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotTerm = payload.term;
            if (gotTerm == term) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              console.log("waiting", gotTerm, term);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, 60 * 1000);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_SEARCH_RESULTS",
          payload: {
            term: term,
          },
        },
        "*",
      );
    });
  },
);

export const fetchHistoryForHost = createAsyncThunk<
  { state: AppState },
  { hostnameFilter: string },
  {
    state: RootState;
  }
>(
  "history/fetchHistoryForHost",
  async (requestPayload, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }

    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "GOT_STATE") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            unlisten();
            resolve(payload);
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_STATE",
          payload: requestPayload,
        },
        "*",
      );
    });
  },
);

export const outsideCreateAnnotation = createAsyncThunk<
  { annotation: Annotation },
  CreateAnnotationData & { navigationId: string },
  {
    state: RootState;
  }
>(
  "history/outsideCreateAnnotation",
  async (requestPayload, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }

    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (
          event.data.type &&
          event.data.type == "CREATE_OUTSIDE_ANNOTATION_SUCCESS"
        ) {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            unlisten();
            resolve(payload);
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "CREATE_OUTSIDE_ANNOTATION",
          payload: requestPayload,
        },
        "*",
      );
    });
  },
);

export const fetchHistoryForDateRange = createAsyncThunk<
  {
    completedNavigations: CompletedTabNavigation[];
    startTime: number;
    endTime: number;
  },
  { startDate: Date; endDate: Date },
  {
    state: RootState;
  }
>(
  "history/fetchHistoryForDateRange",
  async ({ startDate, endDate }, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }

    let startTime = startDate ? startDate.getTime() : 0;
    let endTime = endDate ? endDate.getTime() : null;
    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "GOT_STATE_FOR_TIME_RANGE") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotStartDate = payload.startTime;
            let gotEndDate = payload.endTime;
            if (gotStartDate == startTime && gotEndDate == endTime) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              console.log("waiting", startTime, endTime);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_STATE_FOR_TIME_RANGE",
          payload: {
            startTime,
            endTime,
          },
        },
        "*",
      );
    });
  },
);

const emptyAppState = function (): AppState {
  return {
    completedNavigations: [],
    pageActions: [],
    tabs: [],
    tabNavigations: [],
    screenshots: [],
    annotations: [],
  };
};

export const extensionStateSlice = createSlice({
  name: "extension",
  initialState: {
    appState: undefined as AppState | undefined,
    fetched: [] as string[],
    fetching: [] as string[],
  },
  reducers: {
    resetExtensionState(state) {
      return {
        ...state,
        appState: undefined,
        fetched: [],
      };
    },

    annotationCreated(
      state,
      action: PayloadAction<{
        annotation: Annotation;
      }>,
    ) {
      let appState = state.appState || emptyAppState();

      return {
        ...state,
        appState: {
          ...appState,

          annotations: [...appState.annotations, action.payload.annotation],
        },
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchHistoryForHost.pending, (state, action) => {
      // console.log('fetch pending', action);

      return {
        ...state,
      };
    });
    builder.addCase(fetchHistoryForHost.rejected, (state, action) => {
      // console.log('fetch rejected', action);
      return {
        ...state,
      };
    });
    builder.addCase(fetchHistoryForHost.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { payload, meta } = action;
      let { hostnameFilter } = meta.arg;
      let { state: appState } = payload;
      let { fetched } = state;
      // fetched = [...fetched, hostnameFilter];
      fetched = [hostnameFilter];
      let fetching = _.without(state.fetching, hostnameFilter);
      return {
        ...state,
        fetched,
        fetching,
        appState,
      };
    });

    builder.addCase(fetchHistoryForUrl.pending, (state, action) => {
      // console.log('fetch pending', action);

      return {
        ...state,
        fetching: [...state.fetching, action.meta.arg],
      };
    });
    builder.addCase(fetchHistoryForUrl.rejected, (state, action) => {
      // console.log('fetch rejected', action);
      let fetching = _.without(state.fetching, action.meta.arg);
      return {
        ...state,
      };
    });
    builder.addCase(fetchHistoryForUrl.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { payload, meta } = action;
      let url = meta.arg;
      let { state: stateForUrl } = payload;

      let { appState, fetched } = state;

      if (!appState || _.isEmpty(appState)) {
        return {
          ...state,
          appState: stateForUrl,
        };
      }
      // let nextAppState = { ...appState };

      // nextAppState.completedNavigations = _(appState.completedNavigations)
      //   .concat(stateForUrl.completedNavigations)
      //   .uniqBy("id")
      //   .value();
      // nextAppState.pageActions = _(appState.pageActions)
      //   .concat(stateForUrl.pageActions)
      //   .uniqBy("id")
      //   .value();

      let nextAppState = mergeHistory(appState, stateForUrl);

      fetched = [...fetched, url];
      let fetching = _.without(state.fetching, url);
      return {
        ...state,
        appState: nextAppState,
        fetched,
        fetching,
      };
    });

    builder.addCase(fetchAnnotationsForUrl.pending, (state, action) => {
      // console.log('fetch pending', action);

      let key = `annotations:${action.meta.arg}`;
      return {
        ...state,
        fetching: [...state.fetching, key],
      };
    });
    builder.addCase(fetchAnnotationsForUrl.rejected, (state, action) => {
      // console.log('fetch rejected', action);
      let key = `annotations:${action.meta.arg}`;
      let fetching = _.without(state.fetching, key);
      return {
        ...state,
        fetching,
      };
    });
    builder.addCase(fetchAnnotationsForUrl.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let key = `annotations:${action.meta.arg}`;
      let { payload, meta } = action;
      let url = meta.arg;
      let { state: stateForUrl } = payload;

      let { appState, fetched } = state;

      if (!appState || _.isEmpty(appState)) {
        return {
          ...state,
          appState: {
            ...emptyAppState(),
            ...stateForUrl,
          },
        };
      }

      let nextAppState = { ...appState };

      nextAppState.annotations = _(appState.annotations)
        .concat(stateForUrl.annotations)
        .uniqBy("id")
        .value();

      fetched = [...fetched, key];
      let fetching = _.without(state.fetching, key);
      return {
        ...state,
        appState: nextAppState,
        fetched,
        fetching,
      };
    });
    builder.addCase(
      fetchAnnotationsForNavigationId.pending,
      (state, action) => {
        // console.log('fetch pending', action);

        let key = `annotations:navigation:${action.meta.arg}`;
        return {
          ...state,
          fetching: [...state.fetching, key],
        };
      },
    );
    builder.addCase(
      fetchAnnotationsForNavigationId.rejected,
      (state, action) => {
        // console.log('fetch rejected', action);
        let key = `annotations:navigation:${action.meta.arg}`;
        let fetching = _.without(state.fetching, key);
        return {
          ...state,
          fetching,
        };
      },
    );
    builder.addCase(
      fetchAnnotationsForNavigationId.fulfilled,
      (state, action) => {
        // console.log('fetch fulfilled', action);
        let key = `annotations:navigation:${action.meta.arg}`;
        let { payload, meta } = action;

        let { state: stateForUrl } = payload;

        let { appState, fetched } = state;

        if (!appState || _.isEmpty(appState)) {
          return {
            ...state,
            appState: {
              ...emptyAppState(),
              ...stateForUrl,
            },
          };
        }

        let nextAppState = { ...appState };

        nextAppState.annotations = _(appState.annotations)
          .concat(stateForUrl.annotations)
          .uniqBy("id")
          .value();

        fetched = [...fetched, key];
        let fetching = _.without(state.fetching, key);
        return {
          ...state,
          appState: nextAppState,
          fetched,
          fetching,
        };
      },
    );

    builder.addCase(fetchHistoryForNavigationId.pending, (state, action) => {
      // console.log('fetch pending', action);

      return {
        ...state,
        fetching: [...state.fetching, action.meta.arg],
      };
    });
    builder.addCase(fetchHistoryForNavigationId.rejected, (state, action) => {
      // console.log('fetch rejected', action);
      let fetching = _.without(state.fetching, action.meta.arg);
      return {
        ...state,
        fetching,
      };
    });
    builder.addCase(fetchHistoryForNavigationId.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { payload, meta } = action;
      let url = meta.arg;
      let { state: stateForUrl } = payload;

      let { appState, fetched } = state;
      fetched = [...fetched, url];

      if (!appState || _.isEmpty(appState)) {
        return {
          ...state,
          fetched,
          appState: stateForUrl,
        };
      }
      let nextAppState = { ...appState };

      nextAppState = mergeHistory(appState, stateForUrl);
      let fetching = _.without(state.fetching, action.meta.arg);

      return {
        ...state,
        appState: nextAppState,
        fetched,
        fetching,
      };
    });

    builder.addCase(toggleFilterForGroup.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      if (!payload) {
        return state;
      }

      let { completedNavigations } = payload;

      return {
        ...state,
        fetched: [],
        fetching: [],
        appState: {
          completedNavigations,
          pageActions: [],
          tabs: [],
          tabNavigations: [],
          screenshots: [],
          annotations: [],
        },
      };
    });

    builder.addCase(fetchHistoryForDateRange.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      if (!payload) {
        return state;
      }

      let { completedNavigations } = payload;

      return {
        ...state,
        fetched: [],
        fetching: [],
        appState: {
          completedNavigations,
          pageActions: [],
          tabs: [],
          tabNavigations: [],
          screenshots: [],
          annotations: [],
        },
      };
    });

    builder.addCase(fetchResultsForSearch.fulfilled, (state, action) => {
      let { payload, meta } = action;
      let url = meta.arg;
      let { states } = payload;

      // let { appState } = state;
      // appState = appState || emptyAppState();
      let appState = emptyAppState();

      let nextAppState = states.reduce((currentState, resultState) => {
        return mergeHistory(currentState, resultState);
      }, appState);

      return {
        ...state,
        fetched: [],
        fetching: [],
        appState: nextAppState,
      };
    });

    builder.addCase(fetchPageAction.fulfilled, (state, action) => {
      let { pageAction } = action.payload;
      if (!pageAction) {
        return state;
      }
      let { appState } = state;
      appState = appState || emptyAppState();

      let nextAppState = { ...appState };
      nextAppState.pageActions = _(appState.pageActions)
        .concat([pageAction])
        .uniqBy("id")
        .value();

      return {
        ...state,
        appState: nextAppState,
      };
    });

    builder.addCase(outsideCreateAnnotation.fulfilled, (state, action) => {
      let { appState } = state;
      appState = appState || emptyAppState();

      let nextAppState = { ...appState };

      nextAppState.annotations = _(appState.annotations)
        .concat([action.payload.annotation])
        .uniqBy("id")
        .value();

      return {
        ...state,
        appState: nextAppState,
      };
    });
  },
});

function mergeHistory(appState: AppState, stateForUrl: AppState) {
  if (!appState || _.isEmpty(appState)) {
    return stateForUrl;
  }
  let nextAppState = { ...appState };

  nextAppState.completedNavigations = _(appState.completedNavigations)
    .concat(stateForUrl.completedNavigations)
    .uniqBy("id")
    .value();
  nextAppState.pageActions = _(appState.pageActions)
    .concat(stateForUrl.pageActions)
    .uniqBy("id")
    .value();
  nextAppState.annotations = _(appState.annotations)
    .concat(stateForUrl.annotations)
    .uniqBy("id")
    .value();

  return nextAppState;
}
