import * as React from "react";

export const imageLinkTypes = ["image", "favicon", "metaimage"];

const escape = "\\";
const imageMatchRE = new RegExp(
  [
    escape + ".apng",
    ".bmp",
    ".gif",
    ".ico",
    ".cur",
    ".jpg",
    ".jpeg",
    ".jfif",
    ".pjpeg",
    ".pjp",
    ".png",
    ".svg",
    ".webp",
  ].join("|" + escape),
);

export const SafeImage = (p: { src: string; tryEvenIfNoMatch?: boolean }) => {
  let { src, tryEvenIfNoMatch } = p;

  let [err, setErr] = React.useState("");
  let [loading, setLoading] = React.useState(!!src);

  let onError = React.useCallback((e) => {
    console.log({ e });
    setErr("image error :(");
    setLoading(false);
  }, []);

  let onLoad = React.useCallback(() => {
    setLoading(false);
  }, []);

  React.useEffect(() => {
    setErr("");
    setLoading(true);
  }, [src]);

  if (!tryEvenIfNoMatch && !imageMatchRE.test(src)) {
    return null;
  }

  if (err) {
    return <div>{err}</div>;
  } else {
    let loadingIndicator;
    if (loading) {
      loadingIndicator = <div>...</div>;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loadingIndicator}
        <img
          style={{
            maxWidth: "250px",

            maxHeight: "150px",
            width: "auto",
            visibility: loading ? "hidden" : "visible",
          }}
          src={src}
          onLoad={onLoad}
          onError={onError}
        />
      </div>
    );
  }
};
