import React, { ChangeEvent } from "react";

export function useInput<T = string>(initialValue: T) {
  const [value, setValue] = React.useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(initialValue),
    bind: {
      value,
      onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setValue((event.target.value as unknown) as T);
      },
    },
  };
}

export function useCheckBox(initialChecked: boolean, value = "checkbox") {
  const [checked, setChecked] = React.useState(initialChecked);

  return {
    value: checked,
    checked,
    setValue: setChecked,
    reset: () => setChecked(initialChecked),
    bind: {
      value,
      checked,
      onChange: (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
      },
    },
  };
}
