import * as React from "react";
import { useEffect } from "react";

/**
 * A simple React hook for differentiating single and double clicks on the same component.
 *
 * @param {node} ref Dom node to watch for double clicks
 * @param {number} [latency=300] The amount of time (in milliseconds) to wait before differentiating a single from a double click
 * @param {function} onSingleClick A callback function for single click events
 * @param {function} onDoubleClick A callback function for double click events
 */

type DoubleClickHook = (opts: {
  ref: React.MutableRefObject<any>;
  latency?: number;
  onSingleClick?: (e: MouseEvent) => unknown;
  onDoubleClick?: (e: MouseEvent) => unknown;
}) => unknown;

const useDoubleClick: DoubleClickHook = ({
  ref,
  latency = 300,
  onSingleClick,
  onDoubleClick,
}) => {
  let clickCount = React.useRef(0);
  useEffect(() => {
    const clickRef = ref.current;
    // let clickCount = 0;
    const handleClick = (e: MouseEvent) => {
      clickCount.current += 1;

      setTimeout(() => {
        if (clickCount.current === 1) onSingleClick ? onSingleClick(e) : null;
        else if (clickCount.current === 2)
          onDoubleClick ? onDoubleClick(e) : null;

        // clickCount = 0;
        clickCount.current = 0;
      }, latency);
    };
    if (!clickRef) {
      return;
    }
    // Add event listener for click events
    clickRef.addEventListener("click", handleClick);

    // Remove event listener
    return () => {
      clickRef.removeEventListener("click", handleClick);
    };
  }, [ref, onDoubleClick, onSingleClick, latency, clickCount]);
};

export default useDoubleClick;
