import * as d3Force from "d3-force";
import { forceManyBody } from "d3-force";
import _ from "lodash";
import React, { CSSProperties } from "react";
import {
  ForceGraphMethods,
  LinkObject,
  NodeObject,
} from "react-force-graph-2d";
import { useSelector } from "react-redux";
import { GraphView2D } from "../graph/GraphView2D";
import { WebLink } from "../hooks/useWebData";
import { WebHistoryData, WebHistoryNode } from "../hooks/useWebHistoryData";
import { RootState } from "../redux/store";
import { Debug } from "../shared/Debug";
import { PageAction } from "../../../extension/src/shared/types";
import { floatingBoxStyle } from "../WebViewer";

export const WebHistoryVisualizer = (p: { webData: WebHistoryData }) => {
  const { webData } = p;
  const {
    data,
    setSelectedHistoryNode,
    // setSelectedHost,
    // setSelectedResource,
    // setFetchNodeMeta,
    // selectedResource,
    // refetchResource,
    stateJson,
  } = webData;

  // let maxResourceCount =
  //   _.maxBy(hosts, (h) => h.resource_count)?.resource_count || 1;
  // let resourceCountScale = scaleLinear()
  //   .domain([1, maxResourceCount])
  //   .range([5, 20]);

  // const [highlightNodes, setHighlightNodes] = React.useState(
  //   new Set<NodeObject>(),
  // );
  // const [highlightLinks, setHighlightLinks] = React.useState(
  //   new Set<WebLink>(),
  // );
  // const [hoverNode, setHoverNode] = React.useState<NodeObject | null>(null);

  // const updateHighlight = () => {
  //   setHighlightNodes(highlightNodes);
  //   setHighlightLinks(highlightLinks);
  // };

  // const handleNodeHover = (node: WebNode | NodeObject | null) => {
  //   highlightNodes.clear();
  //   highlightLinks.clear();
  //   if (node && isWebNode(node)) {
  //     if (node.nodeType == 'resource') {
  //       setFetchNodeMeta(node.id);
  //     }
  //     highlightNodes.add(node);
  //     // node.neighbors.forEach((neighbor) => highlightNodes.add(neighbor));
  //     // node.links.forEach((link) => highlightLinks.add(link));
  //   }

  //   setHoverNode(node || null);
  //   updateHighlight();
  // };

  // const handleLinkHover = (link: LinkObject | null) => {
  //   highlightNodes.clear();
  //   highlightLinks.clear();

  //   if (link) {
  //     console.log({ link });
  //     highlightNodes.add(link.source as NodeObject);
  //     highlightNodes.add(link.target as NodeObject);
  //     if (isWebLink(link)) {
  //       highlightLinks.add(link);
  //       let { resourceLinkId } = link;
  //       if (resourceLinkId) {
  //         dispatch(fetchLinkMeta({ resourceLinkId }));
  //       }
  //     }
  //   }

  //   updateHighlight();
  // };

  const graphRef = React.useRef<ForceGraphMethods>();

  React.useEffect(() => {
    // console.log({ graphRef });
    if (graphRef.current) {
      //@ts-ignore
      window._graph = graphRef.current;
      graphRef.current.d3Force(
        "link",
        d3Force.forceLink().distance(50).iterations(3).strength(0.5),
      );
      //@ts-ignore
      // graphRef.current.d3Force('charge').distanceMax(200);
      // graphRef.current.d3Force('center', null);
      graphRef.current.d3Force(
        "charge",
        forceManyBody().strength(-20).distanceMax(3000),
        // .distanceMin(50),
      );
      // graphRef.current.d3Force(
      //   'link',
      //   forceLink().distance(() => 5),
      // );
    }
  }, [graphRef]);

  // console.log({ data });
  // const dispatch = useDispatch();

  let wrapperRef = React.useRef<HTMLDivElement>(null);

  const onNodeClick = React.useCallback(
    (node: WebHistoryNode, evt: MouseEvent): void => {
      console.log({ node });
      setSelectedHistoryNode(node.id);
    },
    [setSelectedHistoryNode],
  );
  // const onDoubleClick = React.useCallback(async () => {
  //   console.log('double');
  //   await dispatch(crawlSelectedResource());
  // }, [dispatch]);
  // useDoubleClick({
  //   ref: wrapperRef,
  //   latency: 200,
  //   onDoubleClick,
  // });

  return (
    <>
      <div ref={wrapperRef}>
        <GraphView2D
          backgroundColor="#232343"
          graphRef={graphRef}
          graphData={data}
          onBackgroundClick={() => {
            setSelectedHistoryNode("");
          }}
          // nodeAutoColorBy="host"
          // nodeVal="resource_count"
          // linkDirectionalArrowLength={(l: LinkObject) => {
          //   if (highlightLinks.has(l as WebLink)) {
          //     return 7;
          //   }
          //   return isWebLink(l) && l.linkType == 'hostLink' ? 0 : 5;
          // }}
          d3AlphaDecay={0.001}
          linkCurvature={0}
          linkDirectionalArrowLength={15}
          linkDirectionalArrowRelPos={5}
          // linkDirectionalArrowColor="#9494a8"
          linkDirectionalArrowColor={() => "#9494a8"}
          linkColor={(l: LinkObject) => {
            return "#9494a8";
            // if (isWebLink(l)) {
            //   return l.linkType == 'hostLink'
            //     ? '#646492'
            //     : pageLinkTypes.includes(l.linkType || '')
            //     ? '#9494a8'
            //     : '#3B3B43';
            // } else {
            //   return '#3B3B43';
            // }
          }}
          // cooldownTicks={1000}
          linkWidth={2}
          onNodeClick={onNodeClick}
          // nodeCanvasObject={function (node, ctx, globalScale) {
          //   let rO = 5;
          //   let r = Math.max(1, rO / globalScale);
          //   ctx.beginPath();
          //   // @ts-ignore
          //   ctx.arc(node.x, node.y, r, 0, 2 * Math.PI, false);
          //   // @ts-ignore
          //   ctx.fillStyle = node.color || 'rgba(31, 120, 180, 0.92)';
          //   ctx.fill();

          //   let url = _.get(node, 'url');
          //   if (data.metaInfo[url]) {

          //     let {favicon}=data.metaInfo[url]
          //     if(favicon){

          //     }

          //   }

          //   ctx.restore();
          // }}
        />
        {/* <ToolTip
        highlightNodes={highlightNodes}
        highlightLinks={highlightLinks}
      /> */}
      </div>
      {/* <div
        style={{
          ...floatingBoxStyle,
          top: 'auto',
          left: 'auto',
          right: 0,
          bottom: 0,
        }}
      >
        <Debug>{stateJson}</Debug>
      </div> */}
    </>
  );
};

let linkedEntityStyle: CSSProperties = {
  overflow: "auto",
};

const ToolTip: React.FC<{
  highlightNodes: Set<NodeObject>;
  highlightLinks: Set<WebLink>;
}> = ({ highlightNodes, highlightLinks }) => {
  let content;
  let selectedLinkObject = Array.from(highlightLinks)[0];
  let currentLink,
    selectedId: string | undefined = undefined;
  if (selectedLinkObject) {
    selectedId = selectedLinkObject._id;
  }
  currentLink = useSelector((s: RootState) => {
    if (selectedId) {
      return _.find(s.resources.links, (l) => l._id == selectedId);
    }
  });
  if (currentLink) {
    let { meta, linkType } = currentLink;
    let { source, target } = selectedLinkObject;

    let label = <></>,
      sourceDisplay,
      targetDisplay;

    if (_.isObject(source)) {
      sourceDisplay = source.id;
    }
    if (_.isObject(target)) {
      targetDisplay = target.id;
    }

    if (meta) {
      if (meta.text) {
        label = <>{meta.text}</>;
      }
      if (meta.title && meta.title != meta.text) {
        if (label) {
          label = (
            <>
              {label}
              <br />
            </>
          );
        }
        label = (
          <>
            {label}({meta.title})
          </>
        );
      }
    }

    if (label) {
      content = <div>{label}</div>;
    }

    let targets;
    if (sourceDisplay && targetDisplay) {
      targets = (
        <div>
          <pre style={linkedEntityStyle}>{sourceDisplay}</pre>
          <div>{linkType}</div>
          <pre style={linkedEntityStyle}>{targetDisplay}</pre>
        </div>
      );
    }

    content = (
      <div>
        {targets}
        {content}
      </div>
    );
  }
  if (!content) {
    return null;
  }

  return (
    <div
      style={{
        ...floatingBoxStyle,
        top: "auto",
        left: "auto",
        bottom: 0,
        right: 0,
        maxWidth: "33vw",
      }}
    >
      {content}
    </div>
  );
};
