import { Middleware } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const logger: Middleware<{}, RootState> = (store) => (next) => (
  action,
) => {
  if (!action) {
    return next(action);
  }
  let isThunk = !action.type;
  if (isThunk) {
    return next(action);
  }

  let state = store.getState();
  if (!state.app.config.logging) {
    return next(action);
  }

  // if (import.meta.env.NODE_ENV === 'test') {
  if (process.env.NODE_ENV === "test") {
    if (!action.type.includes("rejected")) {
      console.info("dispatching", action.type);
      return next(action);
    }
  }
  console.group(action.type);
  console.info("dispatching", action);
  let result = next(action);
  console.log("next state", store.getState());
  console.groupEnd();
  return result;
};
