import { logger } from "./middleware/logger";

import { configureStore, DeepPartial } from "@reduxjs/toolkit";

import rootReducer from "./reducers";

export default function configureAppStore(
  preloadedState?: DeepPartial<RootState>,
) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).prepend(logger),
    preloadedState,
  });

  return store;
}

export type AppStore = ReturnType<typeof configureAppStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore["dispatch"];
