import * as _ from "lodash";

import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  combineReducers,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { apiUrl } from "../../config";
import { HostSummaryResponse, WebNode } from "../../hooks/useWebData";
import { fetchLinksForResource } from "./resources";
import { fetchJobs } from "./jobs";

type AppConfig = {
  logging: boolean;
};

const configSlice = createSlice({
  name: "app.config",
  initialState: {
    logging: process.env.NODE_ENV != "production",
  },
  reducers: {
    setConfig: (state, action: PayloadAction<AppConfig>) => {
      let config = action.payload;
      return {
        ...state,
        ...config,
      };
    },
  },

  extraReducers: (builder) => {},
});

export const appInit = createAsyncThunk<
  void,
  undefined,
  {
    state: RootState;
  }
>("app/init", async (_n = undefined, { dispatch, getState, requestId }) => {
  dispatch(fetchJobs());
});

export const actions = {
  ...configSlice.actions,
};
console.log(actions);

export default combineReducers({
  config: configSlice.reducer,
});
