import React from "react";
import _ from "lodash";

let debugOn = true;
// let debugOn = false;

export const Debug: React.FC<{
  force?: boolean;
  open?: boolean;
  noScroll?: boolean;
}> = ({ children, force, open, noScroll }) => {
  if (!debugOn && !force) return null;
  let [isOpen, setOpen] = React.useState(open);

  let style = noScroll
    ? {}
    : {
        overflow: "auto",
        maxHeight: isOpen ? "400px" : "100px",
      };

  let onClick = React.useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen, setOpen]);

  let content;
  if (isOpen) {
    content = JSON.stringify(children, null, 2);
  } else {
    if (_.isArray(children)) {
      content = _.map(children, (c, i) => (
        <Debug key={i} force={force} open={open}>
          {c}
        </Debug>
      ));
    } else if (_.isObject(children)) {
      content = Object.keys(children).join(", ");
    } else if (_.isString(children)) {
      content = children;
    }
  }

  return (
    <pre onClick={onClick} style={style}>
      {content}
    </pre>
  );
};
