import * as _ from "lodash";
import { ElementNode, ElementLink, PlainTypes } from "../DOMVisualizer";
import { strToHex } from "./hashString";
import { caesarShift } from "./caesarShift";
const defaultPathSeparator = "~-~-~-~";
function scanJSON(
  obj: any,
  cb: (p: string, k: string, v: unknown) => void,
  path = "",
  separator = defaultPathSeparator,
) {
  var k;
  if (obj instanceof Object) {
    for (k in obj) {
      if (obj.hasOwnProperty(k)) {
        //recursive call to scan property
        let propId = path + separator + k;
        cb(propId, k, obj[k]);
        scanJSON(obj[k], cb, propId);
      }
    }
  } else {
    //obj is not an instance of Object so obj here is a value
  }
}

export function parseAsJSON(baseUrl: string, body: string) {
  let nodes: ElementNode[] = [];
  let links: ElementLink[] = [];
  let rootId = ".";
  try {
    const json = JSON.parse(body);
    if (json) {
      nodes.push({
        id: rootId,
        name: "root",
        color: "#4a4a4a",
        nodeType: "root",
        baseUrl,
      });
    }
    scanJSON(json, (path, key, v) => {
      let nodeType;
      let name: string = v as string;
      let value: PlainTypes | {} = {};
      if (_.isArray(v)) {
        nodeType = "array";
        name = JSON.stringify(v, null, 2);
      } else if (_.isNumber(v)) {
        nodeType = "number";
        value = v;
      } else if (_.isString(v)) {
        nodeType = "string";
        value = v;
      } else if (_.isObject(v)) {
        nodeType = "object";
        name = JSON.stringify(v, null, 2);
      } else if (_.isUndefined(v)) {
        nodeType = "undefined";
        value = v;
      } else if (_.isNull(v)) {
        nodeType = "null";
        value = v;
      } else if (_.isBoolean(v)) {
        nodeType = "boolean";
        value = v;
      }

      name = `${path}<br/>${name}`;
      // last segment
      // let parentId = /\.[^\.]*$/.exec(path);
      // everything up to last segment
      // let parentIdMatch = /(.*)\.[^\.]*$/.exec(path);
      // let parentIdMatch = new RegExp('(.*)\\.[^\\.]*$').exec(path);
      let parentIdMatch = new RegExp(
        `(.*)${defaultPathSeparator}[^${defaultPathSeparator}]*$`,
      ).exec(path);
      if (parentIdMatch && parentIdMatch.length) {
        let parentId = parentIdMatch[1];
        if (parentId === "") {
          parentId = ".";
        }
        if (parentId) {
          console.log({
            source: parentIdMatch,
            target: path,
          });
          links.push({
            source: parentId,
            target: path,
            color: "#8484a3",
          });
        }
      }

      let color = nodeType
        ? strToHex(caesarShift(nodeType.toLowerCase(), 10))
        : "#a06da1";

      const node: ElementNode = {
        nodeType,
        id: path,
        name: name.replace(new RegExp(defaultPathSeparator, "g"), "."),
        baseUrl,
        color,
      };

      if (!(value instanceof Object)) {
        node.attributes = { value };
      }

      console.log({ path, key, v, node });
      nodes.push(node);
    });
  } catch (e) {
    console.warn(e);
  }

  return {
    nodes,
    links,
  };
}
