import * as _ from "lodash";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./redux/store";
import { actions as hostActions } from "./redux/reducers/hosts";
import { strToHex } from "./utils/hashString";
import { actionLinkStyle, floatingBoxStyle } from "./WebViewer";
import { resourceActions } from "./redux/reducers/resources";
import {
  WebNode,
  allHostsSelector,
  allResourcesSelector,
} from "./hooks/useWebData";
import { Collapsible } from "./shared/Collapsible";
export const ResourceNavigator: React.FC<{}> = () => {
  return (
    <div
      style={{
        ...floatingBoxStyle,
        top: "auto",
        bottom: 0,
        left: 0,
        maxWidth: "50vw",
      }}
    >
      <LinkTypeNavigator />
      {/* <ResourceTypeNavigator /> */}
      <HostsResourceNavigator />
    </div>
  );
};
const Swatch: React.FC<{ src: string; faded?: boolean }> = ({ src, faded }) => {
  return (
    <span
      style={{
        display: "inline-block",
        width: "15px",
        height: "15px",
        backgroundColor: strToHex(src),
        borderRadius: "100%",
        marginRight: "0.5rem",
        opacity: faded ? 0.15 : 1,
      }}
    ></span>
  );
};
const LinkTypeNavigator = () => {
  const dispatch = useDispatch();

  let linkTypes = useSelector((s: RootState) => {
    const linkTypes: Set<string> = new Set();
    return s.resources.links.reduce((curr, link) => {
      curr.add(link.linkType);
      return curr;
    }, linkTypes);
  });

  let excludedLinkTypes = useSelector((s: RootState) => {
    return s.resources.filters.excludedLinkTypes;
  });

  const toggleLinkType = React.useCallback(
    (evt) => {
      if (!evt.target.checked) {
        dispatch(resourceActions.excludeLinkType(evt.target.value));
      } else {
        dispatch(resourceActions.includeLinkType(evt.target.value));
      }
    },
    [dispatch],
  );

  let linkToggles = Array.from(linkTypes).map((linkType) => {
    let isChecked = !excludedLinkTypes.includes(linkType);
    return (
      <div key={linkType}>
        <label
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {linkType}
          <input
            type="checkbox"
            value={linkType}
            checked={isChecked}
            onChange={toggleLinkType}
          ></input>
        </label>
      </div>
    );
  });

  let displayed = <div>{linkToggles}</div>;

  return (
    <Collapsible title="link types" startExpanded>
      {displayed}
    </Collapsible>
  );
};

const HostsResourceNavigatorContent = () => {
  const dispatch = useDispatch();
  let [expandedHosts, setExpandedHosts] = React.useState<string[]>([]);

  let hosts = useSelector(allHostsSelector);
  let excludedHosts = useSelector((s: RootState) => s.hosts.filters.excluded);

  let resources = useSelector(allResourcesSelector);

  let resourcesByHost = React.useMemo(
    () => _.groupBy(resources.concat(hosts), "host"),
    [resources, hosts],
  );

  const collapseHost = React.useCallback((hostName: string) => {
    setExpandedHosts((hosts) => _.without(hosts, hostName));
  }, []);
  const expandHost = React.useCallback((hostName: string) => {
    setExpandedHosts((hosts) => hosts.concat(hostName));
  }, []);
  const includeHost = React.useCallback(
    (hostName: string) => {
      dispatch(hostActions.includeHost(hostName));
    },
    [dispatch],
  );
  const excludeHost = React.useCallback(
    (hostName: string) => {
      dispatch(hostActions.excludeHost(hostName));
    },
    [dispatch],
  );

  const selectResource = React.useCallback(
    (url: string) => {
      dispatch(resourceActions.selectResource(url));
    },
    [dispatch],
  );
  return (
    <>
      {_.map(resourcesByHost, (resourceNodes, hostName) => {
        let isExpanded = expandedHosts.includes(hostName);
        let isExcluded = excludedHosts.includes(hostName);
        let nodes = isExpanded
          ? _.map(resourceNodes, (resourceNode) => {
              if (resourceNode.nodeType == "host") {
                return null;
              }
              const url = resourceNode.url;
              return (
                <div
                  key={resourceNode.id}
                  onClick={selectResource.bind(null, url)}
                >
                  {url}
                </div>
              );
            })
          : null;

        let actions = [];
        if (isExpanded) {
          actions.push(
            <span
              key={"collapseHost"}
              style={actionLinkStyle}
              onClick={collapseHost.bind(null, hostName)}
            >
              collapse
            </span>,
          );
        } else {
          actions.push(
            <span
              key={"expandHost"}
              style={actionLinkStyle}
              onClick={expandHost.bind(null, hostName)}
            >
              expand
            </span>,
          );
        }

        if (isExcluded) {
          actions.push(
            <span
              key={"includeHost"}
              style={actionLinkStyle}
              onClick={includeHost.bind(null, hostName)}
            >
              include
            </span>,
          );
        } else {
          actions.push(
            <span
              key={"excludeHost"}
              style={actionLinkStyle}
              onClick={excludeHost.bind(null, hostName)}
            >
              exclude
            </span>,
          );
        }

        return (
          <div key={hostName}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Swatch src={hostName} faded={isExcluded} />
              <span style={{ flexGrow: 1 }}>{hostName}</span>
              <div>&nbsp;{actions}</div>
            </div>

            <div
              style={{
                marginLeft: "1rem",
                overflow: "auto",
                maxHeight: "25vh",
              }}
            >
              {nodes}
            </div>
          </div>
        );
      })}
    </>
  );
};

const HostsResourceNavigator = () => {
  return (
    <Collapsible title="hosts">
      <HostsResourceNavigatorContent />
    </Collapsible>
  );
};
