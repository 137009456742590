import * as React from "react";
import Router from "./Router";

import configureStore, { AppStore } from "./redux/store";
import { Provider } from "react-redux";
export const GlobalStyle = () => {
  return (
    <style>{`
  body {
    margin: 0;
  }

`}</style>
  );
};

function App({ store }: { store?: AppStore }) {
  const [storedStore, setStore] = React.useState<AppStore | undefined>(store);
  React.useEffect(() => {
    if (!storedStore) {
      const defaultStore = configureStore();
      setStore(defaultStore);
      (window as any).wmStore = defaultStore;
    }
  }, [storedStore]);

  if (!storedStore) {
    return null;
  }

  return (
    <>
      <GlobalStyle />
      <Provider store={storedStore}>
        <Router />
      </Provider>
    </>
  );
}

export default App;
