import { createAsyncThunk } from "@reduxjs/toolkit";
import * as _ from "lodash";
import { apiUrl } from "../../config";
import { RootState } from "../store";
import { resourceActions } from "./resources";

// function stringify(value) {
//   return toString(value);
// }
function updateClipboard(newClip: string) {
  navigator.clipboard.writeText(newClip).then(
    function () {
      /* clipboard successfully set */
    },
    function () {
      /* clipboard write failed */
    },
  );
}
export type RawKeywords = Record<
  string,
  {
    keyword: string;
    score: number;
  }[]
>;
export const analyzeKeywords = createAsyncThunk<
  string[],
  string,
  {
    state: RootState;
  }
>("text/analyzeKeywords", async (resourceUrl, { dispatch, getState }) => {
  let data: {
    analysis: {
      detail?: string;
      // summary: string;
      keywords: RawKeywords;
    };
  } = await // await fetch(`${apiUrl}/resources/responses/analyze/latest/${resourceId}`)
  (
    await fetch(
      `${apiUrl}/resources/responses/url/analyze/latest/${resourceUrl}`,
    )
  ).json();

  console.log(data.analysis);
  if (data && data.analysis) {
    let { analysis } = data;
    if (analysis.detail) {
      console.warn(analysis.detail);
      return [];
    }
    let { keywords } = analysis;

    dispatch(
      addKeywords({
        keywords,
        resourceUrl,
      }),
    );
    // let keywordsSet = new Set<string>();
    // _.forEach(keywords, (kws, algo) => {
    //   kws.forEach(({ keyword, score }) => keywordsSet.add(keyword));
    // });
    // console.log(Array.from(keywordsSet));

    // let deduped: string[] = [];
    // let potentials = Array.from(keywordsSet);
    // return potentials;
    // potentials.forEach((kw) => {
    //   if (
    //     !_.find(potentials, (potential) => {
    //       return potential.includes(kw) && potential.length > kw.length;
    //     })
    //   ) {
    //     deduped.push(kw);
    //   }
    // });
    // console.log({ deduped, potentials });
    // return deduped;
  }
  return [];

  // let data2 = await (
  //   await fetch(`${apiUrl}/resources/responses/latest/${resourceId}`)
  // ).json();

  // if (data.response) {
  //   let html = data.response.body;
  //   let parser = new DOMParser();
  //   let doc = parser.parseFromString(html, 'text/html');
  //   var element = doc.querySelectorAll('script, style'),
  //     index;

  //   for (index = element.length - 1; index >= 0; index--) {
  //     element[index].parentNode.removeChild(element[index]);
  //   }
  //   element = doc.querySelectorAll('div, p');

  //   Array.from(element).forEach((el) => {
  //     Array.from(el.childNodes).forEach((n) => {
  //       if (n.nodeType == 3) {
  //         n.nodeValue += '\n';
  //       }
  //     });
  //   });

  //   // for (index = element.length - 1; index >= 0; index--) {
  //   //   element[index].textContent += '\n';
  //   // }

  //   element = doc.querySelectorAll('a, span');

  //   // for (index = element.length - 1; index >= 0; index--) {
  //   //   element[index].textContent = ' ' + element[index].textContent + ' ';
  //   // }

  //   Array.from(element).forEach((el) => {
  //     Array.from(el.childNodes).forEach((n) => {
  //       if (n.nodeType == 3) {
  //         n.nodeValue = ' ' + n.nodeValue + ' ';
  //       }
  //     });
  //   });

  //   let body = doc.body.innerText
  //     .replace(/\n\s*\n/g, '\n')
  //     .replace(/\n/g, '. ')
  //     .replace(/\s\s+/g, ' ')
  //     .replace(/’/g, "'");
  //   console.log(body);
  //   updateClipboard(body);
  //   // return new Promise((resolve, reject) => {
  //   //   retext()
  //   //     .use(pos) // Make sure to use `retext-pos` before `retext-keywords`.
  //   //     .use(keywords, { maximum: 20 })
  //   //     .process(body, done);

  //   //   function done(err, file) {
  //   //     if (err) {
  //   //       return reject(err);
  //   //     }

  //   //     console.log('Keywords:');
  //   //     file.data.keywords.forEach(function (keyword) {
  //   //       console.log(toString(keyword.matches[0].node));
  //   //     });

  //   //     console.log();
  //   //     console.log('Key-phrases:');
  //   //     let phrases = [];
  //   //     file.data.keyphrases.forEach(function (phrase) {
  //   //       let outputPhrase = phrase.matches[0].nodes.map(stringify);
  //   //       console.log(outputPhrase.join(''));
  //   //       phrases.push(outputPhrase.join(''));
  //   //       // console.log(phrase.matches.length)
  //   //     });
  //   //     resolve(phrases);
  //   //   }
  //   // });
  // }
});
export const addKeywords = createAsyncThunk<
  void,
  {
    keywords: RawKeywords;
    resourceUrl: string;
  },
  {
    state: RootState;
  }
>(
  "text/addKeywords",
  async ({ resourceUrl, keywords }, { dispatch, getState }) => {
    let keywordsSet = new Set<string>();
    _.forEach(keywords, (kws, algo) => {
      kws.forEach(({ keyword, score }) => keywordsSet.add(keyword));
    });
    console.log(Array.from(keywordsSet));

    let potentials = Array.from(keywordsSet);

    dispatch(
      resourceActions.addKeywords({
        keywords: potentials,
        resourceUrl,
      }),
    );
  },
);
