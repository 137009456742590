import * as React from "react";

export const Collapsible: React.FC<{
  title?: string;
  startExpanded?: boolean;
  makeChildren?: () => React.ReactNode;
  trigger?: React.ReactNode;
  triggerStyles?: React.CSSProperties;
  placeHolderContent?: React.ReactNode;
  noScroll?: boolean;
}> = ({
  title,
  trigger,
  startExpanded,
  children,
  makeChildren,
  placeHolderContent,
  noScroll,
  triggerStyles,

  ...passThrough
}) => {
  triggerStyles = triggerStyles || {};

  let [sectionExpanded, setSectionExpanded] = React.useState(startExpanded);

  let renderedTrigger;

  if (!trigger) {
    renderedTrigger = (
      <strong
        style={{
          cursor: "pointer",
          margin: "0rem 0.25rem",
          display: "block",
          userSelect: "none",
        }}
        onClick={setSectionExpanded.bind(null, !sectionExpanded)}
        {...passThrough}
      >
        {title}
        {sectionExpanded ? "▼" : "▶"}
      </strong>
    );
  } else {
    renderedTrigger = (
      <div
        onClick={setSectionExpanded.bind(null, !sectionExpanded)}
        style={{
          cursor: "pointer",
          ...triggerStyles,
        }}
        {...passThrough}
      >
        {trigger}
      </div>
    );
  }
  let content;
  if (sectionExpanded) {
    if (makeChildren) {
      content = makeChildren();
    } else {
      content = children;
    }
    if (content) {
      let style: React.CSSProperties = {
        overflow: "auto",
        maxHeight: "40vh",
      };
      if (noScroll) {
        style = {};
      }

      content = (
        <div style={style} {...passThrough}>
          {content}
        </div>
      );
    }
  } else {
    content = placeHolderContent;
  }

  return (
    <>
      {renderedTrigger}
      {content}
    </>
  );
};
