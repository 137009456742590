import React from "react";
const groupIcon = require("../img/icon-group.svg");
const addIcon = require("../img/icon-add.svg");
const addCircleIcon = require("../img/icon-add-circle.svg");
const checkmarkIcon = require("../img/icon-checkmark.svg");
const linkIcon = require("../img/icon-link.svg");
const bookmarkIcon = require("../img/icon-collections-bookmark.svg");

type IconProps = {
  title: string;
  size?: "normal" | "small";
  iconName: "add" | "add-circle" | "group" | "checkmark" | "link" | "bookmark";
  onClick?: React.EventHandler<React.MouseEvent>;
};

const IconInner: React.FC<IconProps> = (
  { title, iconName, onClick, size },
  ref?,
) => {
  const cursor = !!onClick ? "pointer" : undefined;

  let iconUrl = "";

  switch (iconName) {
    case "group":
      iconUrl = groupIcon.default;
      break;
    case "add":
      iconUrl = addIcon.default;
      break;
    case "add-circle":
      iconUrl = addCircleIcon.default;
      break;
    case "checkmark":
      iconUrl = checkmarkIcon.default;
      break;
    case "link":
      iconUrl = linkIcon.default;
      break;
    case "bookmark":
      iconUrl = bookmarkIcon.default;
      break;
  }
  let [shouldFallback, setShouldFallback] = React.useState(false);

  // React.useEffect(() => {
  //   let onError = () => {
  //     setShouldFallback(true);
  //   };

  //   if (iconUrl) {
  //     let tester = new Image();
  //     tester.onerror = onError;
  //     tester.src = iconUrl;
  //   }
  //   return () => {
  //     setShouldFallback(false);
  //   };
  // }, [iconUrl]);

  const setFallback = React.useCallback(() => {
    setShouldFallback(true);
  }, []);
  if (!iconUrl) {
    console.warn("no iconUrl", iconName);
    return null;
  }

  let dims = size == "small" ? 20 : 50;

  let styles: React.CSSProperties = {
    cursor,
    position: "relative",
  };

  let imgStyles: React.CSSProperties = {
    width: dims,
    height: dims,
  };

  let fallback;

  if (shouldFallback) {
    fallback = (
      <div
        style={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          textAlign: "center",
          position: "absolute",
        }}
      >
        {title}
      </div>
    );
    styles.outline = "thin dashed black";
  }

  return (
    <div ref={ref} style={styles} title={title} onClick={onClick}>
      {fallback}
      <img style={imgStyles} src={iconUrl} onError={setFallback} />
    </div>
  );
};
// @ts-ignore
export const Icon = React.forwardRef<HTMLDivElement, IconProps>(IconInner);
