window.process = {};
import * as React from "react";
import * as _ from "lodash";

// import {
//   FlumeConfig,
//   Controls,
//   Colors,
//   NodeEditor,
//   // @ts-ignore
// } from 'flume/dist/index.es.js';
// import 'flume/dist/index.es.js';

// const flumeConfig = new FlumeConfig();

// flumeConfig
//   .addPortType({
//     type: 'number',
//     name: 'number',
//     label: 'Number',
//     color: Colors.red,
//     controls: [
//       Controls.number({
//         name: 'num',
//         label: 'Number',
//       }),
//     ],
//   })
//   .addNodeType({
//     type: 'number',
//     label: 'Number',
//     initialWidth: 150,
//     inputs: (ports) => [ports.number()],
//     outputs: (ports) => [ports.number()],
//   })
//   .addNodeType({
//     type: 'addNumbers',
//     label: 'Add Numbers',
//     initialWidth: 150,
//     inputs: (ports) => [
//       ports.number({ name: 'num1' }),
//       ports.number({ name: 'num2' }),
//     ],
//     outputs: (ports) => [ports.number({ name: 'result' })],
//   });

// export function WorkFlowEditor(p) {
//   return (
//     <div style={{ width: 600, height: 800 }}>
//       <NodeEditor
//         nodeTypes={flumeConfig.nodeTypes}
//         portTypes={flumeConfig.portTypes}
//       />
//     </div>
//   );
// }

export function WorkFlowEditor(p) {
  return <div style={{ width: 600, height: 800 }}></div>;
}
