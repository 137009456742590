import { forceManyBody } from "d3-force";
import { scaleLinear } from "d3-scale";
import _ from "lodash";
import React, { CSSProperties } from "react";
import {
  ForceGraphMethods,
  LinkObject,
  NodeObject,
} from "react-force-graph-2d";
import { useDispatch, useSelector } from "react-redux";
import { GraphView2D } from "./graph/GraphView2D";
import useDoubleClick from "./hooks/useDoubleClick";
import { pageLinkTypes, WebData, WebLink, WebNode } from "./hooks/useWebData";
import {
  crawlSelectedResource,
  fetchLinkMeta,
} from "./redux/reducers/resources";
import { RootState } from "./redux/store";
import { imageLinkTypes } from "./SafeImage";
import { floatingBoxStyle } from "./WebViewer";

function isWebNode(n: NodeObject | WebNode): n is WebNode {
  return !!(n as WebNode).nodeType;
}
function isWebLink(n: LinkObject | WebLink): n is WebLink {
  return !!(n as WebLink).linkType;
}
const undefinedColor = "#FF00FF";
export const WebVisualizer = (p: { webData: WebData }) => {
  const { webData } = p;
  const {
    data,
    hosts,
    setSelectedHost,
    setSelectedResource,
    setFetchNodeMeta,
    selectedResource,
    refetchResource,
  } = webData;

  let maxResourceCount =
    _.maxBy(hosts, (h) => h.resource_count)?.resource_count || 1;
  let resourceCountScale = scaleLinear()
    .domain([1, maxResourceCount])
    .range([5, 20]);

  const [highlightNodes, setHighlightNodes] = React.useState(
    new Set<NodeObject>(),
  );
  const [highlightLinks, setHighlightLinks] = React.useState(
    new Set<WebLink>(),
  );
  const [hoverNode, setHoverNode] = React.useState<NodeObject | null>(null);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = (node: WebNode | NodeObject | null) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node && isWebNode(node)) {
      if (node.nodeType == "resource") {
        setFetchNodeMeta(node.id);
      }
      highlightNodes.add(node);
      // node.neighbors.forEach((neighbor) => highlightNodes.add(neighbor));
      // node.links.forEach((link) => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  };

  const handleLinkHover = (link: LinkObject | null) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      console.log({ link });
      highlightNodes.add(link.source as NodeObject);
      highlightNodes.add(link.target as NodeObject);
      if (isWebLink(link)) {
        highlightLinks.add(link);
        let { resourceLinkId } = link;
        if (resourceLinkId) {
          dispatch(fetchLinkMeta({ resourceLinkId }));
        }
      }
    }

    updateHighlight();
  };

  const graphRef = React.useRef<ForceGraphMethods>();

  React.useEffect(() => {
    // console.log({ graphRef });
    if (graphRef.current) {
      //@ts-ignore
      window._graph = graphRef.current;
      //@ts-ignore
      // graphRef.current.d3Force('charge').distanceMax(200);
      // graphRef.current.d3Force('center', null);
      // graphRef.current.d3Force(
      //   'charge',
      //   forceManyBody().strength(-20).distanceMax(300),
      //   // .distanceMin(50),
      // );
      // graphRef.current.d3Force(
      //   'link',
      //   forceLink().distance(() => 5),
      // );
    }
  }, [graphRef]);

  // console.log({ data });
  const dispatch = useDispatch();

  let wrapperRef = React.useRef<HTMLDivElement>(null);

  const onDoubleClick = React.useCallback(async () => {
    console.log("double");
    await dispatch(crawlSelectedResource());
  }, [dispatch]);
  useDoubleClick({
    ref: wrapperRef,
    latency: 200,
    onDoubleClick,
  });

  return (
    <div ref={wrapperRef}>
      <GraphView2D
        backgroundColor="#232343"
        graphRef={graphRef}
        graphData={data}
        // nodeAutoColorBy="host"
        // nodeVal="resource_count"
        linkDirectionalArrowLength={(l: LinkObject) => {
          if (highlightLinks.has(l as WebLink)) {
            return 7;
          }
          return isWebLink(l) && l.linkType == "hostLink" ? 0 : 5;
        }}
        linkColor={(l: LinkObject) => {
          if (isWebLink(l)) {
            return l.linkType == "hostLink"
              ? "#646492"
              : pageLinkTypes.includes(l.linkType || "")
              ? "#9494a8"
              : "#3B3B43";
          } else {
            return "#3B3B43";
          }
        }}
        linkWidth={(l: LinkObject) => {
          if (highlightLinks.has(l as WebLink)) {
            return 4;
          }
          return isWebLink(l) && l.linkType == "hostLink" ? 0.5 : 1;
        }}
        nodeRelSize={10}
        onBackgroundClick={() => {
          setSelectedHost(null);
          setSelectedResource("");
        }}
        onNodeClick={(node, evt) => {
          if (isWebNode(node)) {
            console.log({ node });

            if (node.nodeType == "host") {
              setSelectedHost(node.id);
            } else if (node.nodeType == "resource") {
              setSelectedResource(node.id);
            }
          }
        }}
        onLinkClick={(link, evt) => {
          if (isWebLink(link)) {
            if (_.isObject(link.target) && _.isObject(link.source)) {
              // if (link.source.id == selectedResource) {
              // console.log('click', { link });
              if (link.target.id) {
                if ((link.target as WebNode).nodeType == "resource") {
                  console.log("select target", link.target.id);
                  setSelectedResource(link.target.id as string);
                }
              }
              // }
            }
          }
        }}
        nodeCanvasObject={function (node, ctx, globalScale) {
          if (!isWebNode(node)) {
            return;
          }
          if (
            _.isUndefined(node.id) ||
            _.isUndefined(node.x) ||
            _.isUndefined(node.y)
          ) {
            return;
          }
          ctx.save();
          let label = node.id || "";
          if (label) {
            if (node.resourceType) {
              if (node.resourceType != "page") {
                label = `${label} (${node.resourceType})`;
              }
            }
          }

          ctx.beginPath();
          let r = 5;

          if (node.resource_count && node.nodeType == "host") {
            r = resourceCountScale(node.resource_count) || r;
          } else if (node.resourceType && node.resourceType != "page") {
            r = r * 0.5;
          }

          node.color = node.color || strToHex(node.host || node.id);

          let filled = false;
          let scaledSize = Math.max(1, r / globalScale);
          if (node.nodeType == "resource") {
            if (node.resourceType == "script") {
              ctx.save();
              ctx.beginPath();
              ctx.arc(node.x, node.y, r + scaledSize, 0, 2 * Math.PI, false);
              ctx.clip();
              filled = true;
              horizontalLines(ctx, {
                color1: node.color,
                x: node.x,
                y: node.y,
                width: r + scaledSize,
              });
              ctx.restore();
            } else if (node.resourceType == "stylesheet") {
              ctx.save();
              ctx.beginPath();
              ctx.arc(node.x, node.y, r + scaledSize, 0, 2 * Math.PI, false);
              ctx.clip();
              filled = true;
              verticalLines(ctx, {
                color1: node.color,
                x: node.x,
                y: node.y,
                width: r + scaledSize,
              });
              ctx.restore();
            } else if (imageLinkTypes.includes(node.resourceType || "")) {
              ctx.save();
              ctx.beginPath();
              ctx.arc(node.x, node.y, r + scaledSize, 0, 2 * Math.PI, false);
              ctx.clip();
              filled = true;
              dots(ctx, {
                color1: node.color,
                x: node.x,
                y: node.y,
                width: r + scaledSize,
              });
              ctx.restore();
            } else if (node.resourceType == "iframe") {
              ctx.save();

              ctx.fillStyle = node.color || undefinedColor;
              let dim = r + scaledSize;
              ctx.fillRect(node.x - dim / 2, node.y - dim / 2, dim, dim);
              filled = true;

              ctx.restore();
            }
          }
          if (!filled) {
            ctx.fillStyle = node.color || undefinedColor;
            ctx.arc(node.x, node.y, r, 0, 2 * Math.PI, false);
            ctx.fill();
          }

          if (node.nodeType == "host") {
            ctx.strokeStyle = "gray";
            ctx.beginPath();
            ctx.arc(node.x, node.y, r, 0, 2 * Math.PI, false);
            ctx.stroke();
          } else if (selectedResource == node.id) {
            ctx.strokeStyle = "orange";
            ctx.lineWidth = scaledSize;
            ctx.beginPath();
            ctx.arc(node.x, node.y, r + scaledSize, 0, 2 * Math.PI, false);
            ctx.stroke();
          }
          if (highlightLinks.size && highlightNodes.has(node)) {
            const fontSize = 12 / globalScale;
            ctx.font = `${fontSize}px Sans-Serif`;
            const textWidth = ctx.measureText(label.toString()).width;
            const bckgDimensions = [textWidth, fontSize].map(
              (n) => n + fontSize * 0.2,
            ); // some padding

            ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
            ctx.fillRect(
              node.x - bckgDimensions[0]! / 2,
              node.y - bckgDimensions[1]! / 2,
              bckgDimensions[0]!,
              bckgDimensions[1]!,
            );

            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            // ctx.fillStyle = (node as any).color;

            ctx.globalCompositeOperation = "difference";
            ctx.fillText(label, node.x, node.y);
          }

          ctx.restore();
        }}
        onNodeHover={handleNodeHover}
        onLinkHover={handleLinkHover}
      />
      <ToolTip
        highlightNodes={highlightNodes}
        highlightLinks={highlightLinks}
      />
    </div>
  );
};

let linkedEntityStyle: CSSProperties = {
  overflow: "auto",
};

const ToolTip: React.FC<{
  highlightNodes: Set<NodeObject>;
  highlightLinks: Set<WebLink>;
}> = ({ highlightNodes, highlightLinks }) => {
  let content;
  let selectedLinkObject = Array.from(highlightLinks)[0];
  let currentLink,
    selectedId: string | undefined = undefined;
  if (selectedLinkObject) {
    selectedId = selectedLinkObject._id;
  }
  currentLink = useSelector((s: RootState) => {
    if (selectedId) {
      return _.find(s.resources.links, (l) => l._id == selectedId);
    }
  });
  if (currentLink && selectedLinkObject) {
    let { meta, linkType } = currentLink;
    let { source, target } = selectedLinkObject;

    let label = <></>,
      sourceDisplay,
      targetDisplay;

    if (_.isObject(source)) {
      sourceDisplay = source.id;
    }
    if (_.isObject(target)) {
      targetDisplay = target.id;
    }

    if (meta) {
      if (meta.text) {
        label = <>{meta.text}</>;
      }
      if (meta.title && meta.title != meta.text) {
        if (label) {
          label = (
            <>
              {label}
              <br />
            </>
          );
        }
        label = (
          <>
            {label}({meta.title})
          </>
        );
      }
    }

    if (label) {
      content = <div>{label}</div>;
    }

    let targets;
    if (sourceDisplay && targetDisplay) {
      targets = (
        <div>
          <pre style={linkedEntityStyle}>{sourceDisplay}</pre>
          <div>{linkType}</div>
          <pre style={linkedEntityStyle}>{targetDisplay}</pre>
        </div>
      );
    }

    content = (
      <div>
        {targets}
        {content}
      </div>
    );
  }
  if (!content) {
    return null;
  }

  return (
    <div
      style={{
        ...floatingBoxStyle,
        top: "auto",
        left: "auto",
        bottom: 0,
        right: 0,
        maxWidth: "33vw",
      }}
    >
      {content}
    </div>
  );
};

function hashString(value: string) {
  var hash = 0,
    i,
    chr;
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
let strHexes: Record<string, string> = {};

function strToHex(value: string) {
  if (strHexes[value]) {
    return strHexes[value];
  }
  let hash = hashString(value);

  let r = (hash & 0xff0000) >> 16;
  let g = (hash & 0x00ff00) >> 8;
  let b = hash & 0x0000ff;
  let hex =
    "#" +
    ("0" + r.toString(16)).substr(-2) +
    ("0" + g.toString(16)).substr(-2) +
    ("0" + b.toString(16)).substr(-2);
  strHexes[value] = hex;
  return hex;
}

function verticalLines(
  drawingContext: CanvasRenderingContext2D,
  patternOps: {
    color1?: string;
    color2?: string;
    width?: number;
    numberOfStripes?: number;
    x: number;
    y: number;
  },
) {
  let { color1, color2, width, numberOfStripes, x, y } = _.extend(
    {
      numberOfStripes: 5,
      width: 75,
    },
    patternOps,
  );
  if (!color1) {
    color1 = undefinedColor;
  }
  if (!color2) {
    // var color1 = "#F2EEB3",
    // color2 = '#FF4C65';
    color2 = "transparent";
  }
  var thickness = width / numberOfStripes;

  for (var i = 0; i < numberOfStripes * 2; i++) {
    drawingContext.beginPath();
    drawingContext.strokeStyle = i % 2 ? color2 : color1;
    drawingContext.lineWidth = thickness;
    drawingContext.lineCap = "round";

    drawingContext.moveTo(x + i * thickness + thickness / 2 - width, y - width);
    drawingContext.lineTo(x + i * thickness + thickness / 2 - width, y + width);
    drawingContext.stroke();
  }
}

function horizontalLines(
  drawingContext: CanvasRenderingContext2D,
  patternOps: {
    color1?: string;
    color2?: string;
    width?: number;
    numberOfStripes?: number;
    x: number;
    y: number;
  },
) {
  let { color1, color2, width, numberOfStripes, x, y } = _.extend(
    {
      numberOfStripes: 5,
      width: 75,
    },
    patternOps,
  );
  if (!color1) {
    color1 = undefinedColor;
  }
  if (!color2) {
    // var color1 = "#F2EEB3",
    // color2 = '#FF4C65';
    color2 = "transparent";
  }
  var thickness = width / numberOfStripes;

  for (var i = 0; i < numberOfStripes * 2; i++) {
    drawingContext.beginPath();
    drawingContext.strokeStyle = i % 2 ? color2 : color1;
    drawingContext.lineWidth = thickness;
    drawingContext.lineCap = "round";

    drawingContext.moveTo(x - width, y + i * thickness + thickness / 2 - width);
    drawingContext.lineTo(x + width, y + i * thickness + thickness / 2 - width);
    drawingContext.stroke();
  }
}

function dots(
  drawingContext: CanvasRenderingContext2D,
  patternOps: {
    color1?: string;
    color2?: string;
    width?: number;
    numberOfStripes?: number;
    x: number;
    y: number;
  },
) {
  let { color1, color2, width, numberOfStripes, x, y } = _.extend(
    {
      numberOfStripes: 6,
      width: 75,
    },
    patternOps,
  );
  if (!color1) {
    color1 = undefinedColor;
  }
  if (!color2) {
    // var color1 = "#F2EEB3",
    color2 = "#FF4C65";
  }
  var thickness = width / numberOfStripes;

  for (var r = 0; r < numberOfStripes * 2; r += thickness * 3) {
    for (var c = 0; c < numberOfStripes * 2; c += thickness * 3) {
      drawingContext.beginPath();
      // drawingContext.fillStyle = r % 2 ? color1 : color2;
      drawingContext.fillStyle = color1;
      // drawingContext.lineWidth = thickness;
      // drawingContext.lineCap = 'round';

      drawingContext.arc(
        x - width + c,
        y - width + r,
        thickness,
        0,
        2 * Math.PI,
        false,
      );
      // drawingContext.moveTo(x - width, y + i * thickness + thickness / 2 - width);
      drawingContext.fill();

      // drawingContext.lineTo(x + width, y + i * thickness + thickness / 2 - width);
      // drawingContext.stroke();
    }
  }
}
