import * as _ from "lodash";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./redux/store";
import { actions as hostActions } from "./redux/reducers/hosts";
import { strToHex } from "./utils/hashString";
import { actionLinkStyle, floatingBoxStyle } from "./WebViewer";
import { resourceActions } from "./redux/reducers/resources";
import {
  WebNode,
  allHostsSelector,
  allResourcesSelector,
} from "./hooks/useWebData";
import { Collapsible } from "./shared/Collapsible";

export const JobCountsSection = () => {
  const dispatch = useDispatch();

  let [sectionExpanded, setSectionExpanded] = React.useState(true);

  let waitingForJobs = useSelector((s: RootState) => {
    return s.jobs.waitingForJobs;
  });

  let sectionContent;

  let countDisplay = "";
  let count = waitingForJobs.length;
  if (count) {
    countDisplay = `(${count}) `;
  }

  let sectionHeader = (
    <strong
      style={{
        cursor: "pointer",
        margin: "0rem 0.25rem",
        display: "block",
      }}
      onClick={setSectionExpanded.bind(null, !sectionExpanded)}
    >
      waiting for jobs {countDisplay}
      {sectionExpanded ? "▼" : "▶"}
    </strong>
  );

  let displayed = null;

  const makeChildren = React.useCallback(() => {
    return waitingForJobs.map((j) => {
      let key = `${j.queueName}: ${j.jobId}`;
      return (
        <div key={key}>
          {j.queueName}: {j.jobId}
        </div>
      );
    });
  }, [waitingForJobs]);

  return (
    <Collapsible
      title={`waiting for jobs ${countDisplay}`}
      makeChildren={makeChildren}
    ></Collapsible>
  );
  // if (!sectionExpanded) {
  //   sectionContent = sectionHeader;
  // } else {
  //   let waitingForJobsDisplay = waitingForJobs.map((j) => {
  //     let key = `${j.queueName}: ${j.jobId}`;
  //     return (
  //       <div key={key}>
  //         {j.queueName}: {j.jobId}
  //       </div>
  //     );
  //   });

  //   sectionContent = (
  //     <div style={{ overflow: 'auto', maxHeight: '40vh' }}>
  //       {sectionHeader}
  //       {displayed}
  //       {waitingForJobsDisplay}
  //     </div>
  //   );
  // }
  // return sectionContent;
};
