import * as _ from "lodash";

import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  combineReducers,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { apiUrl } from "../../config";
import { HostSummaryResponse, WebNode } from "../../hooks/useWebData";
import { fetchLinksForResource } from "./resources";
import { JobsMetaResponse, JobStatus } from "../../jobs/JobsViewerPage";

export const fetchJobs = createAsyncThunk<
  JobsMetaResponse,
  undefined,
  {
    state: RootState;
  }
>(
  "jobs/fetchJobs",
  async (_n = undefined, { dispatch, getState, requestId }) => {
    let res = await (await fetch(`${apiUrl}/jobs/status`)).json();
    return res;
  },
);

export type JobId = string | number;
export type JobDescriptor = {
  queueName: string;
  jobId: JobId;
  url?: string;
};

const jobsSlice = createSlice({
  name: "jobs.all",
  initialState: {
    counts: {} as Record<string, Record<JobStatus, {}>>,
    waitingForJobs: [] as JobDescriptor[],
  },
  reducers: {
    jobComplete: (state, action: PayloadAction<JobDescriptor>) => {
      let finished = action.payload;
      state.waitingForJobs = state.waitingForJobs.filter((j) => {
        return !(
          j.jobId == finished.jobId && j.queueName == finished.queueName
        );
      });
    },
    waitingForJob: (state, action: PayloadAction<JobDescriptor>) => {
      state.waitingForJobs.push(action.payload);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchJobs.fulfilled, (state, action) => {
      let jobsByStatus = action.payload;
      state.counts = jobsByStatus;
    });
  },
});
export const actions = {
  ...jobsSlice.actions,
};

export default jobsSlice;
