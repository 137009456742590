import * as _ from "lodash";
import createLogger from "debug";
import { Model, Node, NodeVisitorFunction } from "tree-model";

import TreeModel from "tree-model";

import { DeepPartial } from "ts-essentials";
import { Annotation, PageAction } from "../../../extension/src/shared/types";
import { logPrefix } from "../../../extension/src/constants";
const treeLog = createLogger(`${logPrefix}tree`);

export type TileTreeModel = {
  node: string;
  hasChild?: boolean;

  actions?: PageAction[];
  annotations?: Record<string, Annotation[]>;
  children?: TileTreeModel[];
  needsInfo?: boolean;
};
//Model<Tile> & Partial<HasNodeDict>;
export type TileTreeNode = ProperTree.Node<TileTreeModel> &
  Partial<HasNodeDict>;
export type TileTreeChildren = TileTreeNode[];

export type TileTreeNodeWithNodeDict = TileTreeNode & Required<HasNodeDict>;
export type NodeDict = { [key: string]: TileTreeNode };
export type NodeCollection = { [key: string]: TileTreeChildren };

var util = require("util");

export type HasNodeDict = {
  _nodeDict: NodeDict;
};

const TM = (new TreeModel() as unknown) as ProperTree.TreeModel;

function hasNodeDict(tm: TileTreeNode): tm is TileTreeNodeWithNodeDict {
  if (!tm._nodeDict) {
    let nodeDict: NodeDict = {};
    tm._nodeDic = nodeDict;
  }

  return true;
}

declare namespace ProperTree {
  class TreeModel {
    constructor(config?: ProperTree.Config);

    private config: ProperTree.Config;

    parse<T>(model: DeepPartial<ProperTree.Model<T>>): ProperTree.Node<T>;
  }

  class Node<T> {
    constructor(config: any, model: Model<T>);

    isRoot(): boolean;
    hasChildren(): boolean;
    addChild(child: Node<T>): Node<T>;
    addChildAtIndex(child: Node<T>, index: number): Node<T>;
    setIndex(index: number): Node<T>;
    getPath(): Array<Node<T>>;
    getIndex(): number;

    walk(options: Options, fn: NodeVisitorFunction<T>, ctx?: object): void;
    walk(fn: NodeVisitorFunction<T>, ctx?: object): void;

    all(
      options: Options,
      fn: NodeVisitorFunction<T>,
      ctx?: object,
    ): Array<Node<T>>;
    all(fn: NodeVisitorFunction<T>, ctx?: object): Array<Node<T>>;

    first(
      options: Options,
      fn: NodeVisitorFunction<T>,
      ctx?: object,
    ): Node<T> | undefined;
    first(fn: NodeVisitorFunction<T>, ctx?: object): Node<T> | undefined;

    drop(): Node<T>;

    [propName: string]: any;
    model: Model<T>;
    parent: Node<T>;
    children: Node<T>[];
  }

  interface Config {
    /**
     * The name for the children array property. Default is "children".
     */
    childrenPropertyName?: string;
    modelComparatorFn?: ComparatorFunction;
    [propName: string]: any;
  }

  interface Options {
    strategy: StrategyName;
  }

  type StrategyName = "pre" | "post" | "breadth";

  type ComparatorFunction = (left: any, right: any) => boolean;
  type NodeVisitorFunction<T> = (
    visitingNode: Node<T>,
  ) => boolean | number | undefined | void;

  type Model<T> = T & { node: string; children?: Array<Node<T>> };
}

// }

function addNode(
  tree: TileTreeNode,
  parent: TileTreeNode,
  child: TileTreeNode,
) {
  // treeLog('tree adding ',child, child&&child.model.node)
  let node = child && child.model.node;

  treeLog("tree adding ", node);

  if (!utils.hasChild(parent, child)) {
    parent.addChild(child);
  } else {
    treeLog("tree", node, "already exists");
  }

  if (tree._nodeDict) {
    if (child.model.node) {
      child.walk((c) => {
        // if(tree._nodeDict[c.model.node]){
        //     console.log('tree node duplicate', c.model.node);
        // }
        if (tree._nodeDict) {
          tree._nodeDict[c.model.node] = c;
        }

        return true;
      });
    } else {
      console.warn("no model.node, empty child?", child);
    }
  }

  return child;
}

var utils = {
  dropNode: (tree: TileTreeNode, child: TileTreeNode) => {
    child.drop();
    if (tree._nodeDict) {
      delete tree._nodeDict[child.model.node];
    }
  },
  //   collectChildrenByLevel: function (
  //     children: TileTreeChildren,
  //     all: Record<string, string[]>,
  //   ) {
  //     var fn = function (acc: Record<string, string[]>, child: TileTreeNode) {
  //       var name, tile;
  //       if (!child.model) {
  //         console.warn('no model found', child);
  //       }
  //       tile = OSDUtils.parseId(child.model.node);
  //       name = tile.level;
  //       if (acc[name] == null) {
  //         acc[name] = [];
  //       }
  //       acc[tile.level].push(child.model.node);
  //       if (child.children && child.children.length) {
  //         utils.collectChildrenByLevel(child.children, acc);
  //       }
  //       return acc;
  //     };
  //     all || (all = {});
  //     _.reduce(children, fn, all);
  //     return all;
  //   },
  collectChildren: function (
    children?: TileTreeChildren,
    all?: TileTreeChildren,
  ): string[] {
    var fn = function (acc: TileTreeNode[], child: TileTreeNode) {
      acc.push(child.node);
      if (child.children && child.children.length) {
        utils.collectChildren(child.children, acc);
      }
      return acc;
    };
    all || (all = []);
    _.reduce(children, fn, all);
    return (all as any) as string[];
  },
  mapChildren: function mapChildren(
    children: TileTreeNode | TileTreeNode[],
    mapper: (c: TileTreeNode, p: TileTreeNode) => any,
    parent: TileTreeNode,
  ) {
    var fn = function (child: TileTreeNode) {
      mapper(child, parent);
      if (child.children && child.children.length) {
        utils.mapChildren(child.children, mapper, child);
      }
    };

    if (_.isObject(children) && !_.isArray(children) && children.children) {
      children = children.children;
    }

    if (_.isArray(children)) {
      _.map(children, fn);
    } else {
      return [];
    }
  },
  // transformChildren: function transformChildren(children, transformer) {
  //     var fn = function(result, child) {
  //         treeLog('fn',arguments)
  //         var thisRet = transformer(child);
  //         result.push(thisRet);
  //         if(thisRet === false) {
  //             return false;
  //         }
  //         if (child.children && child.children.length) {
  //             return utils.transformChildren(child.children, transformer);
  //         };
  //     };
  //     return _.transform(children, fn);
  // },
  findFirstChild: function (
    children: TileTreeChildren,
    predicate: (c: TileTreeNode) => boolean,
    ret: TileTreeChildren = [],
  ) {
    var fn = function (child: TileTreeNode) {
      // treeLog('fn',arguments)
      var thisRet = predicate(child);
      if (thisRet === true) {
        ret.push(child);
        return child;
      }
      if (child.children && child.children.length) {
        // treeLog('checking children', child.node);
        return utils.findFirstChild(child.children, predicate, ret);
      }
    };
    _.find(children, fn);
    return _.last(ret);
  },
  // depthBelowGreaterThan: function (
  //   node: TileTreeNode,
  //   depth: number,
  //   currentDepth = 0,
  // ): boolean {
  //   if (node && node.model && node.model.virtual) {
  //     treeLog("tree depthBelowGreaterThan", node.model.node);
  //     return false;
  //   }
  //   if (currentDepth >= depth) {
  //     return true;
  //   }

  //   if (node && node.children) {
  //     return _.some(
  //       node.children,
  //       _.unary(
  //         _.partialRight(utils.depthBelowGreaterThan, depth, currentDepth + 1),
  //       ),
  //     );
  //   }
  //   return false;
  // },
  //   walkToDepth: (
  //     tree: TileTreeNode,
  //     depth: number,
  //     fn: (n: TileTreeNode, d: number) => boolean,
  //   ) => {
  //     let currentDepth = 0;
  //     let startLevel = OSDUtils.parseId(tree.model.node).level;
  //     let walk = tree.walk.bind(
  //       tree,
  //       { strategy: 'breadth' },
  //       (node: TileTreeNode) => {
  //         if (node != tree) {
  //           let tile = OSDUtils.parseId(node.model.node);
  //           currentDepth = tile.level - startLevel;
  //           // console.log('ch',node.model.node, currentDepth)

  //           let ret = fn(node, currentDepth);
  //           if (ret === false) {
  //             return false;
  //           }
  //           if (currentDepth > depth) {
  //             return false;
  //           }
  //         }
  //       },
  //     );
  //     walk();
  //   },

  isTruncated: (n: TileTreeNode) => n.model.hasChild && _.isEmpty(n.children),
  makeTreeRoot: function (slug: string) {
    return `${slug}_1_0_0`;
  },
  makeTreeId: function (slug: string) {
    if (!slug) {
      throw new Error("tree makeTreeId no slug");
    }

    if (slug.indexOf("-tree") == -1) {
      return `${slug}-tree`;
    } else {
      return slug;
    }
  },
  // Check if n1 and n2 have the same id
  nodeEq: function nodeEq(n1: TileTreeNode, n2: TileTreeNode) {
    if (_.isString(n1)) {
      n1 = TM.parse({
        node: n1,
      });
    }

    if (_.isString(n2)) {
      n2 = TM.parse({
        node: n2 as string,
      });
    }
    if ((n1 && !n2) || (!n1 && n2)) {
      return false;
    }
    return n1.model.node === n2.model.node;
  },

  // Check if n1 contains the given n2 child
  hasChild: function hasChild(n1: TileTreeNode, n2Child: TileTreeNode) {
    if (_.isString(n2Child)) {
      n2Child = TM.parse({
        node: n2Child,
      });
    }

    if (!n1.children) {
      throw new Error(`no children on n1 ${util?.inspect(n1)}`);
    }

    return n1.children.some(utils.nodeEq.bind(null, n2Child));
  },

  // Check if n1 contains the given n2 child
  nodeExists: function hasChild(
    n1: TileTreeNode,
    n2Child: TileTreeNode | string,
  ): TileTreeNode | undefined {
    if (!n1 || !n2Child) {
      throw new Error("nodeExists wrong args");
    }
    if (_.isString(n2Child)) {
      n2Child = TM.parse({
        node: n2Child,
      });
    }

    if (!n1 || !n1.children) {
      throw new Error(`no children on n1 ${util?.inspect(n1)}`);
    }

    if (n1._nodeDict) {
      return n1._nodeDict[n2Child.model.node];
    } else {
      console.log("tree no nodeDict found, walking tree", n2Child.model.node);
    }

    return (
      (utils.nodeEq(n1, n2Child) && n1) ||
      n1.first(utils.nodeEq.bind(null, n2Child))
    );
  },

  dedupeChildren: function dedupeChildren(node: TileTreeNode) {
    let dedupedModel = _.extend(
      { node: node.model.node },
      _.pickBy(node.model, (v, k) => k != "children" && k != "hasChild"),
    );

    var dedupedChildren = TM.parse(dedupedModel);
    node.children.map((child: TileTreeNode) => {
      // treeLog(node.model.node, child.parent.model.node)
      // treeLog(child)
      utils.mergeNodes(
        dedupedChildren,
        TM.parse({
          node: child.parent.model.node,
          children: [child.model],
        }),
      );
    });

    return dedupedChildren;
  },

  mergeNodes: function mergeNodes(n1: TileTreeNode, n2: TileTreeNode) {
    // console.log(arguments)
    if (!n1 && n2) {
      // n1 = TM.parse({})
      return n2;
    }

    if (!n2 && n1) {
      // n2 = TM.parse({})
      return n1;
    }

    if (!n1 && !n2) {
      return null;
    }

    if (!n1.model) {
      n1 = TM.parse((n1 as unknown) as TileTreeModel);
    }

    if (!n2.model) {
      n2 = TM.parse((n2 as unknown) as TileTreeModel);
    }

    var n1HasN2Child, i, n2Child: TileTreeNode;

    var n1uniq = _.uniqBy(n1.children, (c: TileTreeNode) => {
      return c.model.node;
    }).length;
    if (n1uniq != n1.children.length) {
      console.warn(
        "n1",
        n1.model.node,
        "duplicate children",
        n1.children.length,
        "total",
        n1uniq,
        "unique",
      );
      var dedupedChildren = utils.dedupeChildren(n1);

      if (n1uniq == dedupedChildren.children.length) {
        n1.children = [];
        n1.model.children = [];
        dedupedChildren.children.map((c: TileTreeNode) => addNode(n1, n1, c));
      } else {
        console.warn("******** dedupe failed", util?.inspect(dedupedChildren));
      }
    }

    var n2uniq = _.uniqBy(n2.children, (c: TileTreeNode) => {
      return c.model.node;
    }).length;
    if (n2uniq != n2.children.length) {
      console.warn(
        "n2",
        n2.model.node,
        "duplicate children",
        n2.children.length,
        "total",
        n2uniq,
        "unique",
      );
      var dedupedChildren = utils.dedupeChildren(n2);
      // dedupedChildren.walk((n)=>console.log(n.model.node))
      if (n2uniq == dedupedChildren.children.length) {
        n2.children = [];
        n2.model.children = [];
        dedupedChildren.children.map((c: TileTreeNode) => addNode(n2, n2, c));
      } else {
        console.warn("******** dedupe failed", util?.inspect(dedupedChildren));
      }
    }
    // Check which n2 children are present in n1
    n1HasN2Child = n2.children.map(utils.hasChild.bind(null, n1));

    // console.log('found',n1HasN2Child)
    // Iterate over n2 children
    for (i = 0; i < n1HasN2Child.length; i++) {
      n2Child = n2.children[i];
      if (n1HasN2Child[i]) {
        // n1 already has this n2 child, so lets merge them
        let n1Child = n1.first(
          { strategy: "breadth" },
          utils.nodeEq.bind(null, n2Child),
        );
        // console.log('merging',n2Child.model.node)
        if (n1Child) {
          utils.mergeNodes(n1Child, n2Child);
        } else {
          throw new Error(`n2 child not found in ${JSON.stringify(n2Child)}`);
        }
      } else {
        // n1 does not have this n2 child, so add it
        // console.log('adding',n2Child.model.node);
        let dedupedChildren = utils.dedupeChildren(n2Child);
        n2Child.children = [];
        n2Child.model.children = [];
        dedupedChildren.children.map((c: TileTreeNode) =>
          addNode(n2, n2Child, c),
        );
        // console.log(dedupedChildren.children.length)
        addNode(n1, n1, n2Child);
      }
    }
    let childless = _.pickBy(
      n2.model,
      (v, k) => k != "children" && k != "hasChild",
    );
    // console.log('extend',n1.model.node,n1.model,childless)
    _.extend(n1.model, childless);
    // console.log('got',n1.model,'\n')
    return n1;
  },

  //   newNode: function (
  //     tree: TileTreeNode,
  //     maybeNode: TileTreeNode | string,
  //     attrs?: Partial<TileTreeNode>,
  //   ): TileTreeNode {
  //     let node: TileTreeNode;
  //     if (_.isString(maybeNode)) {
  //       node = TM.parse({
  //         node: maybeNode,
  //       });

  //       if (_.isObject(attrs)) {
  //         _.extend(node.model, attrs);
  //       }
  //     } else {
  //       node = maybeNode;
  //     }

  //     let existingNode = utils.nodeExists(tree, node);
  //     if (existingNode) {
  //       _.extend(existingNode.model, node.model);
  //       // treeLog('tree 3edxist',existingNode.model)
  //       treeLog('tree 3edxist', existingNode.model.node);
  //       return existingNode;
  //     }

  //     let tile = OSDUtils.parseId(node.model.node);
  //     if (!_.isFinite(tile.level) && !_.isFinite(tile.x) && !_.isFinite(tile.y)) {
  //       throw new Error(`not a tile ${JSON.stringify(node)}`);
  //     }
  //     let rootLayer = utils.makeTreeRoot(tile.slug);

  //     let parentTile, parentId, isRootLayer;
  //     if (node.model.node != rootLayer) {
  //       parentTile = OSDUtils.getTileAbove(tile);
  //       if (!parentTile) {
  //         throw new Error('is a parentTile');
  //       }
  //       parentId = OSDUtils.makeId(tile.slug, parentTile);
  //     } else {
  //       isRootLayer = true;
  //       parentId = rootLayer;
  //     }

  //     let exists = utils.nodeExists(tree, parentId);
  //     if (!exists) {
  //       treeLog('tree parent doesnt exist', parentId);
  //       let rootNode = TM.parse({
  //         node: rootLayer,
  //         // virtual: true
  //       });

  //       if (isRootLayer) {
  //         treeLog('tree add root', rootLayer);
  //         rootNode = addNode(tree, tree, node);
  //         return rootNode;
  //       }

  //       let toAdd = [];
  //       let parentExists;
  //       while (parentId && parentId != rootLayer) {
  //         parentExists = utils.nodeExists(tree, parentId);
  //         if (parentExists) {
  //           break;
  //         }
  //         // debugger
  //         toAdd.push(parentId);
  //         parentTile = OSDUtils.getTileAbove(parentTile);
  //         if (parentTile) {
  //           parentId = OSDUtils.makeId(tile.slug, parentTile);

  //           if (parentId == rootLayer) {
  //             parentExists = utils.nodeExists(tree, rootLayer);
  //             if (!parentExists) {
  //               toAdd.push(parentId);
  //             }
  //           }
  //         }
  //       }

  //       if (!parentExists) {
  //         treeLog('tree no parent found', tree, node);

  //         parentExists = tree;
  //         tree._nodeDict = tree._nodeDict || {};
  //         // parentExists = TM.parse({
  //         //     node: rootLayer,
  //         //     hasChild: true,
  //         //     virtual: true
  //         // })
  //       }
  //       treeLog('tree should add', toAdd);

  //       let parentNode = parentExists;

  //       toAdd.reverse();
  //       toAdd.forEach((nodeID) => {
  //         let child = TM.parse({
  //           node: nodeID,
  //           hasChild: true,
  //           virtual: true,
  //           // parent: parentNode.model.node
  //           // virtual: true
  //         });

  //         treeLog('adding', nodeID, 'to', parentNode.model.node);

  //         addNode(tree, parentNode, child);
  //         parentNode = child;
  //       });
  //       node = addNode(tree, parentNode, node);

  //       return node;
  //     } else {
  //       // if(!exists.getPath()[0] || !utils.nodeEq(exists.getPath()[0], rootLayer)){
  //       //     console.warn('tree unconnected parent', parentId,rootLayer)
  //       // }
  //       treeLog('tree inserting to ', parentId);
  //       exists.model.hasChild = true;
  //       // exists.addChild(node);
  //       exists = addNode(tree, exists, node);
  //       return exists;
  //     }
  //   },
  TreeModel: TM,
  addNode: addNode,
  createTree: function (rootId: string) {
    let tree = TM.parse({
      node: rootId,
      virtual: true,
    });

    tree._nodeDict = {};
    tree._nodeDict[rootId] = tree;
    return tree;
  },
  parseTree: function (json: TileTreeModel) {
    let tree = TM.parse(json);
    tree._nodeDict = {};

    tree.walk((node: TileTreeNode) => {
      if (node.model.node) {
        if (tree._nodeDict[node.model.node]) {
          console.log("tree node duplicate", node.model.node);
        }

        tree._nodeDict[node.model.node] = node;
      } else {
        console.warn("no model.node, empty child?", node);
      }

      return true;
    });

    return tree;
  },
  removeNode: function (tree: TileTreeNode, node: TileTreeNode) {
    if (_.isString(node)) {
      let exists = utils.nodeExists(tree, node);
      if (exists) {
        node = exists;
      }
    }

    if (node) {
      treeLog("tree remove node", node.model && node.model.node);
      node.drop();
      if (tree._nodeDict && node.model && node.model.node) {
        delete tree._nodeDict[node.model.node];
      }
    }
  },
  walk: function (
    tree: TileTreeNode,
    fn: ProperTree.NodeVisitorFunction<TileTreeModel>,
  ) {
    tree.walk((node) => {
      let ret = fn(node);
      if (ret !== false) {
        return true;
      }
      return false;
    });
  },
  mapAllRelatives: function (
    tree: TileTreeNode,
    fn: NodeVisitorFunction<TileTreeNode>,
  ) {
    if (!tree) {
      console.warn("mapAllRelatives no tree");
      return [];
    }
    let res = _.map(_.without(tree.getPath(), tree), fn);
    tree.walk((n: TileTreeNode) => {
      res.push(fn(n));
      return true;
    });

    return res;
  },

  //   makePath: function (tileId: string) {
  //     let tile = OSDUtils.parseId(tileId);
  //     let tempTree: TileTreeNode = utils.createTileTree(tile.slug);
  //     let treeTile = utils.newNode(tempTree, tileId);

  //     let rawPath = treeTile && treeTile.getPath();
  //     return rawPath;
  //   },
};

export default utils;
