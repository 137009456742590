import React, { CSSProperties, MutableRefObject } from "react";
import _, { fill } from "lodash";

// import { scaleLinear } from 'd3-scale';

import ForceGraph2D, {
  NodeObject,
  ForceGraphMethods,
  LinkObject,
  ForceGraphProps,
} from "react-force-graph-2d";
import { useWindowSize } from "../hooks/useWindowSize";
import useDocumentVisibility from "../hooks/useDocumentVisibility";
// @ts-ignore
import CanvasForceGraph from "force-graph/src/canvas-force-graph";

console.log({ CanvasForceGraph }, CanvasForceGraph.prototype.tickFrame);
// export const GraphView2D = React.forwardRef<ForceGraphMethods, ForceGraphProps>(
//   function GraphView2DNoRef(props, graphRef) {
export const GraphView2D: React.FC<
  ForceGraphProps & {
    graphRef: React.MutableRefObject<ForceGraphMethods | undefined>;
  }
> = (props) => {
  const { graphData, graphRef } = props;
  // graphRef = graphRef || React.useRef<ForceGraphMethods>();

  let { width, height } = useWindowSize();

  let wrapperRef = React.useRef<HTMLDivElement>(null);

  let visibility = useDocumentVisibility();
  let [waitingForInteraction, setWaitingForInteraction] = React.useState(false);

  React.useEffect(() => {
    if (visibility == "visible") {
      console.log("visible");
      if (!waitingForInteraction) {
        graphRef.current?.resumeAnimation();
      } else {
        console.log("continue to wait for interaction");
      }
    } else {
      console.log("hidden");
      graphRef.current?.pauseAnimation();
    }
  }, [visibility, waitingForInteraction, graphRef]);

  const onEngineStop = React.useCallback(() => {
    console.log("engine stopped");
    _.defer(() => {
      graphRef.current?.pauseAnimation();
      setWaitingForInteraction(true);
    });
    if (props.onEngineStop) {
      props.onEngineStop();
    }
  }, [graphRef, props]);

  const poke = React.useCallback(() => {
    graphRef.current?.resumeAnimation();
    setWaitingForInteraction(false);
  }, [graphRef]);

  React.useEffect(() => {
    console.log("data changes?");
    poke();
  }, [graphData, poke]);

  let pokeEvents: Record<string, () => void> = {};
  let onZoom;
  if (waitingForInteraction) {
    ["onMouseMove", "onPointerEnter"].forEach(
      (evt) => (pokeEvents[evt] = poke),
    );
    onZoom = function () {
      poke();
      if (props.onZoom) {
        props.onZoom.apply(null, arguments);
      }
    };
  }

  const nodeVisibility = React.useCallback(
    (n: NodeObject) => {
      let { x, y } = n;
      if (!x || !y || !width || !height) {
        return true;
      }
      if (graphRef.current) {
        let coords = graphRef.current.graph2ScreenCoords(x, y);
        if (
          coords.x < 0 ||
          coords.y < 0 ||
          coords.x > width ||
          coords.y > height
        ) {
          return false;
        }
      }
      return true;
    },
    [graphRef, width, height],
  );

  let filteredProps = _.pickBy(
    props,
    (v, k) => !["onEngineStop", "onZoom"].includes(k),
  );

  return (
    <div ref={wrapperRef} {...pokeEvents}>
      <ForceGraph2D
        width={width}
        height={height}
        backgroundColor="#777787"
        ref={graphRef}
        graphData={graphData}
        onZoom={onZoom}
        nodeRelSize={10}
        cooldownTicks={200}
        nodeVisibility={nodeVisibility}
        onEngineStop={onEngineStop}
        {...filteredProps}
      />
    </div>
  );
};
