import React from "react";
type LoadExtensionHookParam<T> = {
  isLoaded: boolean;
  payload?: T;
  loadFromExtension: (a: T) => Promise<unknown>;
};

export const useLoadExtensionState = ({
  isLoaded,
  payload,
  loadFromExtension,
}: LoadExtensionHookParam<unknown>) => {
  const [loader, setLoader] = React.useState(0);
  const [loaderDelay, setLoaderDelay] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const delayedLoad = React.useCallback(() => {
    setLoader(Math.random());
    console.log("delay elapsed reset");
    setLoaderDelay(0);
  }, []);

  React.useEffect(() => {
    if (!isLoaded && !loading && !loaderDelay) {
      setLoading(true);
      // console.log({ loaderDelay });
      let loaded = loadFromExtension(payload);

      if (loaded?.then) {
        loaded.catch((e) => {
          // setLoader(Math.random());
          console.log("error, scheduling", e);
          setLoaderDelay((setTimeout(delayedLoad, 1500) as unknown) as number);
          setLoading(false);
        });
        loaded.then((r) => {
          // console.log({ r });
          // @ts-ignore
          if (r && r.error) {
            const newDelayTimeout = (setTimeout(
              delayedLoad,
              1500,
            ) as unknown) as number;

            // @ts-ignore
            console.log("error result, scheduling", r.error, newDelayTimeout);
            setLoaderDelay(newDelayTimeout);
          }
          setLoading(false);
        });
      }
    }
    return () => {
      if (isLoaded) {
        if (loaderDelay) {
          console.log("clearing", loader, loaderDelay);
          clearTimeout(loaderDelay);
        }
      }
    };
  }, [
    loader,
    loaderDelay,
    loadFromExtension,
    isLoaded,
    payload,
    loading,
    delayedLoad,
  ]);
};
