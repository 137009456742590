import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as _ from "lodash";
import {
  AddNodeToGroupsMsgPayload,
  CompletedTabNavigation,
  Group,
  GroupLink,
} from "../../../../../extension/src/shared/types";
import { RootState } from "../../store";
import { extensionRequestTimeout } from "./constants";
import { reduxActionLog } from "../../utils";

// let maxreq = 10;
let currentrq = 0;

export const fetchAllGroups = createAsyncThunk<
  { groups: Group[] },
  undefined,
  {
    state: RootState;
  }
>("history/fetchAllGroups", async (_, { dispatch, getState, requestId }) => {
  // if (currentrq > maxreq) {
  //   return Promise.reject('maxreq');
  // }
  currentrq += 1;
  return new Promise((resolve, reject) => {
    function onMessage(event: MessageEvent) {
      if (event.source != window) return;

      if (event.data.type && event.data.type == "GOT_ALL_GROUPS") {
        if (event.data.payload) {
          reduxActionLog("action received: ", event.data);
          let { payload } = event.data;
          unlisten();
          resolve(event.data.payload);
        }
      }
    }

    window.addEventListener("message", onMessage, false);
    const unlisten = () => {
      window.clearTimeout(failedTimeout);
      window.removeEventListener("message", onMessage, false);
    };
    let failedTimeout = window.setTimeout(() => {
      console.log(requestId, "timeout");
      reject("timoeut");
      unlisten();
    }, extensionRequestTimeout);
    // console.log('would postmessag', screenshotId);
    window.postMessage(
      {
        type: "GET_ALL_GROUPS",
        payload: {},
      },
      "*",
    );
  });
});

export const fetchGroupsForNode = createAsyncThunk<
  { groupLinks: GroupLink[] },
  string,
  {
    state: RootState;
  }
>(
  "history/fetchGroupsForNode",
  async (nodeId, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }
    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "GOT_GROUPS_FOR_NODE") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotNodeId = payload.nodeId;
            if (gotNodeId == nodeId) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              console.log("waiting", gotNodeId, nodeId);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_GROUPS_FOR_NODE",
          payload: {
            nodeId,
          },
        },
        "*",
      );
    });
  },
);

export const addNodeToGroups = createAsyncThunk<
  { groupLinks: GroupLink[] },
  AddNodeToGroupsMsgPayload,
  {
    state: RootState;
  }
>(
  "history/addNodeToGroups",
  async (payload, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }
    const { nodeId } = payload;
    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (
          event.data.type &&
          event.data.type == "ADD_NODE_TO_GROUPS_SUCCESS"
        ) {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            let gotNodeId = payload.nodeId;
            if (gotNodeId == nodeId) {
              // @ts-ignore
              //   webHistoryJson.current = event.data.payload;
              // setLoader(0);
              unlisten();
              resolve(event.data.payload);
            } else {
              console.log("waiting", gotNodeId, nodeId);
            }
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "ADD_NODE_TO_GROUPS",
          payload,
        },
        "*",
      );
    });
  },
);

export const createGroup = createAsyncThunk<
  { group: Group },
  Pick<Group, "title" | "shortIndicator">,
  {
    state: RootState;
  }
>(
  "history/createGroup",
  async (groupAttrs, { dispatch, getState, requestId }) => {
    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }
    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "CREATE_GROUP_SUCCESS") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            let { payload } = event.data;
            // @ts-ignore
            //   webHistoryJson.current = event.data.payload;
            // setLoader(0);
            unlisten();
            resolve(event.data.payload);
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "CREATE_GROUP",
          payload: {
            group: groupAttrs,
          },
        },
        "*",
      );
    });
  },
);

export const toggleFilterForGroup = createAsyncThunk<
  {
    completedNavigations: CompletedTabNavigation[];
    groupLinks: GroupLink[];
  } | void,
  string,
  {
    state: RootState;
  }
>(
  "history/toggleFilterForGroup",
  async (groupId, { dispatch, getState, requestId }) => {
    let { history } = getState();
    let { groups } = history;
    let { filtered: groupIds } = groups;
    if (!groupIds.length) {
      return;
    }

    // if (currentrq > maxreq) {
    //   return Promise.reject('maxreq');
    // }
    currentrq += 1;
    return new Promise((resolve, reject) => {
      function onMessage(event: MessageEvent) {
        if (event.source != window) return;

        if (event.data.type && event.data.type == "GOT_NODES_FOR_GROUPS") {
          if (event.data.payload) {
            reduxActionLog("action received: ", event.data);
            // let { payload } = event.data;
            // @ts-ignore
            //   webHistoryJson.current = event.data.payload;
            // setLoader(0);
            unlisten();
            resolve(event.data.payload);
          }
        }
      }

      window.addEventListener("message", onMessage, false);
      const unlisten = () => {
        window.clearTimeout(failedTimeout);
        window.removeEventListener("message", onMessage, false);
      };
      let failedTimeout = window.setTimeout(() => {
        console.log(requestId, "timeout");
        reject("timoeut");
        unlisten();
      }, extensionRequestTimeout);
      // console.log('would postmessag', screenshotId);
      window.postMessage(
        {
          type: "GET_NODES_FOR_GROUPS",
          payload: {
            groupIds,
          },
        },
        "*",
      );
    });
  },
);

export const groupsSlice = createSlice({
  name: "groups",
  initialState: {
    pending: [] as string[],
    failed: [] as string[],
    loaded: [] as string[],
    all: [] as Group[],
    forNodes: {} as Record<string, string[]>,
    filtered: [] as string[],
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchGroupsForNode.pending, (state, action) => {
      // console.log('fetch pending', action);
      let { pending } = state;
      pending = [...pending, action.meta.arg];

      return {
        ...state,
        pending,
      };
    });
    builder.addCase(fetchGroupsForNode.rejected, (state, action) => {
      // console.log('fetch rejected', action);
      let { arg: screenshotId } = action.meta;
      let pending = _.without(state.pending, screenshotId);
      let failed = [...state.failed, screenshotId];
      return {
        ...state,
        pending,
        failed,
      };
    });
    builder.addCase(fetchGroupsForNode.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      console.log({ meta });

      let { arg: nodeId } = meta;

      let { groupLinks } = payload;

      let pending = _.without(state.pending, nodeId);
      let loaded = [...state.loaded, nodeId];

      let groupIds = groupLinks.map((g) => g.groupId);

      let forNodes = {
        ...state.forNodes,
        [nodeId]: _.uniq(groupIds),
      };

      return {
        ...state,
        loaded,
        pending,
        forNodes,
      };
    });
    builder.addCase(fetchAllGroups.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { loaded } = state;
      let { meta, payload } = action;

      let { groups } = payload;

      return {
        ...state,
        loaded: [...loaded, "all"],
        all: groups,
      };
    });
    builder.addCase(addNodeToGroups.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      console.log({ meta });

      let {
        arg: { nodeId },
      } = meta;

      let { groupLinks } = payload;

      let pending = _.without(state.pending, nodeId);

      let groupIds = groupLinks.map((g) => g.groupId);

      let forNodes = {
        ...state.forNodes,
        [nodeId]: _.uniq(groupIds),
      };

      return {
        ...state,
        pending,
        forNodes,
      };
    });
    builder.addCase(createGroup.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;

      let { group } = payload;
      let all = [...state.all, group];

      return {
        ...state,
        all,
      };
    });
    builder.addCase(toggleFilterForGroup.pending, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;

      let groupId = meta.arg;
      let { filtered } = state;
      if (filtered.includes(groupId)) {
        filtered = _.without(filtered, groupId);
      } else {
        filtered = [...filtered, groupId];
      }

      return {
        ...state,
        filtered,
      };
    });
    builder.addCase(toggleFilterForGroup.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      if (!payload) {
        return state;
      }
      let { completedNavigations, groupLinks } = payload;

      let { forNodes } = state;

      return {
        ...state,
        forNodes,
      };
    });
  },
});
