import * as d3Force from "d3-force";
import * as _ from "lodash";
import * as React from "react";
import ForceGraph2D, {
  ForceGraphMethods,
  LinkObject,
  NodeObject,
} from "react-force-graph-2d";
// import ForceGraph3D, {
//   ForceGraphMethods as ForceGraph3DMethods,
// } from 'react-force-graph-3d';
import { SiteData } from "./DOMViewer";
import { WebNode } from "./hooks/useWebData";
import { useWindowSize } from "./hooks/useWindowSize";
import { actionLinkStyle, floatingBoxStyle } from "./WebViewer";
import { setup, destroy } from "./2d-space-colonization/tree";
import SpaceNode from "./2d-space-colonization/jasonwebb/core/Node";
import Vec2 from "vec2";
import { strToHex } from "./utils/hashString";
import { caesarShift } from "./utils/caesarShift";
import { GraphView2D } from "./graph/GraphView2D";
function isWebNode(n: NodeObject | WebNode): n is WebNode {
  return !!(n as WebNode).nodeType;
}
function isElementNode(
  n: NodeObject | ElementNode | d3Force.SimulationNodeDatum,
): n is ElementNode {
  return !!(n as ElementNode).nodeType;
}

export type PlainTypes = string | number | boolean | undefined | null;
export type ElementNode = NodeObject & {
  name: string;
  nodeType?: string;
  baseUrl: string;
  color?: string;
  attributes?: Record<string, PlainTypes>;
};
export type ElementLink = LinkObject & {
  color?: string;
};

function bfs(root: Element | undefined, cb: (e: Element) => void) {
  const queue: Element[] = [];
  while (root) {
    console.log(root);
    Array.from(root.children).forEach((child) => queue.push(child));
    root = queue.shift();
    if (root) {
      cb(root);
    }
  }
}
function dfs(node: Element, cb: (e: Element) => void) {
  let stk: Element[] = [];
  stk.push(node);

  while (stk.length !== 0) {
    let currentNode = stk.pop();

    //check for the condiditon or just console.log
    // console.log(currentNode);

    if (
      currentNode &&
      currentNode.childNodes &&
      currentNode.childNodes.length > 0
    ) {
      for (let i = currentNode.childNodes.length - 1; i >= 0; i--) {
        stk.push(currentNode.childNodes[i] as Element);
      }
    }
  }
}

function postOrder(
  node: Element,
  cb: (e: Element, level: number) => void,
  level: number = 0,
) {
  if (node !== null) {
    if (node && node.childNodes && node.childNodes.length > 0) {
      for (let i = node.childNodes.length - 1; i >= 0; i--) {
        // stk.push(node.childNodes[i] as Element);
        postOrder(node.childNodes[i] as Element, cb, level + 1);
      }
    }
    cb(node, level);
  }
}

function nodeTypeToVal(nodeType?: string) {
  // if (
  //   nodeType &&
  //   ['TEXT_NODE', 'IMG', 'VIDEO', 'CANVAS', 'SVG'].includes(nodeType)
  // ) {
  //   return 20;
  // } else {
  //   return 1;
  // }
  if (nodeType == "TEXT_NODE") {
    return 1;
  }

  if (nodeType == "HEAD") {
    return 20;
  }
  if (nodeType == "HTML") {
    return 20;
  }
  if (nodeType == "BODY") {
    return 10;
  }
  if (nodeType == "IMG") {
    return 10;
  }
  if (nodeType == "VIDEO") {
    return 10;
  }
  if (nodeType == "AUDIO") {
    return 10;
  }

  return 3;
}

function getElementAttributes(n: Element) {
  if (n.getAttributeNames) {
    let allAttributeNames = n.getAttributeNames();

    let attrs: Record<string, string> = {};
    return allAttributeNames.reduce((acc, aName) => {
      acc[aName] = n.getAttribute(aName) || "";
      return acc;
    }, attrs);
  } else {
    return {};
  }
}

function absoluteizeUrl(baseUrl: string, url: string) {
  let absoluteUrl = new URL(url, baseUrl);
  return absoluteUrl.href;
}

function domElementToNode(baseUrl: string, n: Element, currentId: number) {
  const isTextNode = n.nodeType == Node.TEXT_NODE;
  const allTextChildren =
    n.childNodes.length &&
    _.every(n.childNodes, (c) => c.nodeType == Node.TEXT_NODE);
  // console.log(n, isTextNode, allTextChildren);

  let name = n.tagName;

  let nodeType;

  let attributes = getElementAttributes(n);
  switch (n.nodeType) {
    case Node.ELEMENT_NODE: {
      nodeType = "ELEMENT_NODE";
      name = nodeType;
      name = `${name}: ${n.tagName}`;
      if (attributes.href) {
        name = `${name}<br/>${attributes.href}`;
      }
      break;
    }
    case Node.TEXT_NODE: {
      nodeType = "TEXT_NODE";
      name = nodeType;
      break;
    }
    case Node.CDATA_SECTION_NODE: {
      nodeType = "CDATA_SECTION_NODE";
      name = nodeType;
      break;
    }
    case Node.PROCESSING_INSTRUCTION_NODE: {
      nodeType = "PROCESSING_INSTRUCTION_NODE";
      name = nodeType;
      break;
    }
    case Node.COMMENT_NODE: {
      nodeType = "COMMENT_NODE";
      name = nodeType;
      break;
    }
    case Node.DOCUMENT_NODE: {
      nodeType = "DOCUMENT_NODE";
      name = nodeType;
      break;
    }
    case Node.DOCUMENT_TYPE_NODE: {
      nodeType = "DOCUMENT_TYPE_NODE";
      name = nodeType;
      break;
    }
    case Node.DOCUMENT_FRAGMENT_NODE: {
      nodeType = "DOCUMENT_FRAGMENT_NODE";
      name = nodeType;
      break;
    }
  }

  if (n.tagName) {
    nodeType = n.tagName;
  }

  if (isTextNode) {
    name = n.textContent || "";
  }
  if (isTextNode && (!name || !name.trim())) {
    return;
  }

  if (n.id) {
    name = `${name}<br/>#${n.id}`;
  }
  if (n.className) {
    name = `${name}<br/>${n.className}`;
  }
  let color = n.tagName
    ? strToHex(
        caesarShift((n.tagName + n.tagName + n.tagName).toLowerCase(), 10),
      )
    : "#a06da1";

  // let linkInfo;
  // try {
  //   linkInfo = processResourceLink(baseUrl, n);
  // } catch (e) {
  //   // console.log(e);
  // }

  let node = {
    id: `${baseUrl}${idSeparator}${currentId}`,
    name,
    color,
    nodeType: nodeType,
    val: nodeTypeToVal(nodeType),
    attributes,
    // linkInfo,
    baseUrl,
  };
  return node;
}

const idSeparator = "~~~~";
function domElementToSpaceNode(
  baseUrl: string,
  n: Element,
  currentId: number | string | undefined,
  level: number,
) {
  const isTextNode = n.nodeType == Node.TEXT_NODE;
  const allTextChildren =
    n.childNodes.length &&
    _.every(n.childNodes, (c) => c.nodeType == Node.TEXT_NODE);
  // console.log(n, isTextNode, allTextChildren);

  let name = n.tagName;

  let nodeType;

  let attributes = getElementAttributes(n);
  switch (n.nodeType) {
    case Node.ELEMENT_NODE: {
      nodeType = "ELEMENT_NODE";
      name = nodeType;
      name = `${name}: ${n.tagName}`;
      if (attributes.href) {
        name = `${name}<br/>${attributes.href}`;
      }
      break;
    }
    case Node.TEXT_NODE: {
      nodeType = "TEXT_NODE";
      name = nodeType;
      break;
    }
    case Node.CDATA_SECTION_NODE: {
      nodeType = "CDATA_SECTION_NODE";
      name = nodeType;
      break;
    }
    case Node.PROCESSING_INSTRUCTION_NODE: {
      nodeType = "PROCESSING_INSTRUCTION_NODE";
      name = nodeType;
      break;
    }
    case Node.COMMENT_NODE: {
      nodeType = "COMMENT_NODE";
      name = nodeType;
      break;
    }
    case Node.DOCUMENT_NODE: {
      nodeType = "DOCUMENT_NODE";
      name = nodeType;
      break;
    }
    case Node.DOCUMENT_TYPE_NODE: {
      nodeType = "DOCUMENT_TYPE_NODE";
      name = nodeType;
      break;
    }
    case Node.DOCUMENT_FRAGMENT_NODE: {
      nodeType = "DOCUMENT_FRAGMENT_NODE";
      name = nodeType;
      break;
    }
  }

  if (n.tagName) {
    nodeType = n.tagName;
  }

  if (isTextNode) {
    name = n.textContent || "";
  }
  if (isTextNode && (!name || !name.trim())) {
    return;
  }
  let color = n.tagName
    ? strToHex(
        caesarShift((n.tagName + n.tagName + n.tagName).toLowerCase(), 10),
      )
    : "#a06da1";

  // let linkInfo;
  // try {
  //   linkInfo = processResourceLink(baseUrl, n);
  // } catch (e) {
  //   // console.log(e);
  // }

  let node = {
    id: `${baseUrl}${idSeparator}${currentId}`,
    name,
    color,
    nodeType: nodeType,
    val: nodeTypeToVal(nodeType),
    attributes,
    // linkInfo,
    baseUrl,
  };
  let sn = new SpaceNode(
    undefined,
    new Vec2(0, 0),
    n.childElementCount == 0,
    // @ts-ignore
    null,
    {},
    node.id,
  );
  sn.thickness = level;
  return sn;
}
const imageMatch = new RegExp("image|icon", "i");
function processResourceLink(baseUrl: string, el: Element) {
  let tagType = el.tagName?.toLowerCase();

  let urlProp: string = "";
  let linkType: string | undefined = undefined;
  switch (tagType) {
    case "a":
      linkType = "anchor";
      urlProp = "href";
      break;
    case "link":
      let { rel } = el as HTMLLinkElement;
      if (rel == "stylesheet") {
        linkType = "stylesheet";
      } else if (imageMatch.test(rel)) {
        linkType = "favicon";
      }
      urlProp = "href";
      break;
    case "script":
      linkType = "script";
      urlProp = "src";
      break;
    case "img":
      linkType = "image";
      urlProp = "src";
      break;
    default:
      throw new Error(`unknown tag ${tagType}`);
  }
  let url = urlProp ? (el as any)[urlProp] : "";
  if (url) {
    url = absoluteizeUrl(baseUrl, url);
  }
  return {
    url,
    linkType,
  };
}

export function parseBodyTree(baseUrl: string, htmlStr: string) {
  let spaceNodes: SpaceNode[] = [];
  let nodes: ElementNode[] = [];
  let links: LinkObject[] = [];

  let parser = new DOMParser();
  let doc = parser.parseFromString(htmlStr, "text/html");
  console.log({ doc });

  let currentId = 0;

  let domToTree: Map<Element, ElementNode> = new Map();
  let domToSpaceTree: Map<Element, SpaceNode> = new Map();

  postOrder(doc.documentElement, (n, level) => {
    let node = domToTree.get(n);
    let nodeId;
    if (!node) {
      nodeId = currentId++;
      node = domElementToNode(baseUrl, n, nodeId);
      if (node) {
        domToTree.set(n, node);
        nodes.push(node);
      } else {
        // console.log('empty');
        return;
      }
    } else {
      nodeId = _.isString(node.id) ? node.id.split(idSeparator)[1] : node.id;
    }

    let spaceNode = domToSpaceTree.get(n);
    // console.log({ level, n });
    if (!spaceNode) {
      spaceNode = domElementToSpaceNode(baseUrl, n, nodeId, level);
      if (spaceNode) {
        domToSpaceTree.set(n, spaceNode);
        spaceNodes.push(spaceNode);
      } else {
        // console.log('empty');
        return;
      }
    }

    if (n.parentNode) {
      let parentTreeNode = domToTree.get(n.parentNode as Element);
      let parentNodeId;
      if (parentTreeNode) {
        parentNodeId = _.isString(parentTreeNode.id)
          ? parentTreeNode.id.split(idSeparator)[1]
          : parentTreeNode.id;
        // console.log('should connect to', parentTreeNode);
      } else {
        // console.log('should add', n.parentNode);
        parentNodeId = currentId++;
        parentTreeNode = domElementToNode(
          baseUrl,
          n.parentNode as Element,
          parentNodeId,
        );
        if (parentTreeNode) {
          nodes.push(parentTreeNode);
          domToTree.set(n.parentNode as Element, parentTreeNode);
        }
      }

      let parentTreeSpaceNode = domToSpaceTree.get(n.parentNode as Element);
      if (parentTreeSpaceNode) {
        // console.log('should connect to', parentTreeNode);
        spaceNode.parent = parentTreeSpaceNode;
      } else {
        // console.log('should add', n.parentNode);
        parentTreeSpaceNode = domElementToSpaceNode(
          baseUrl,
          n.parentNode as Element,
          parentNodeId,
          level - 1,
        );
        if (parentTreeSpaceNode) {
          spaceNodes.push(parentTreeSpaceNode);
          domToSpaceTree.set(n.parentNode as Element, parentTreeSpaceNode);
          spaceNode.parent = parentTreeSpaceNode;
        }
      }

      if (!parentTreeNode) {
        console.log(n, "no parent found");
        return;
      }
      // console.log('link', { parentTreeNode, node });
      let link = {
        source: parentTreeNode.id,
        target: node.id,

        color: "#8484a3",
      };
      links.push(link);
    }
  });

  let maxLevelNode = _.maxBy(spaceNodes, (sn) => sn.thickness);
  let maxLevel = maxLevelNode ? maxLevelNode.thickness : 1;

  console.log({ maxLevel });
  spaceNodes.forEach(
    (spaceNode) =>
      (spaceNode.thickness = (1 - spaceNode.thickness / maxLevel) * 5),
  );
  return {
    nodes,
    links,
    // spaceNodes,
  };
}

export type ParsedDOMTree = ReturnType<typeof parseBodyTree>;

const useMergedTree = (siteData: SiteData) => {
  return React.useMemo(() => {
    // let spaceNodes: SpaceNode[] = [];
    let nodes: ElementNode[] = [];
    let links: LinkObject[] = [];
    let { data } = siteData;
    if (data) {
      data.forEach(({ tree: siteTree, uri }) => {
        if (siteTree?.nodes) {
          nodes = nodes.concat(siteTree?.nodes);
        }
        // if (siteTree?.spaceNodes) {
        //   spaceNodes = spaceNodes.concat(siteTree?.spaceNodes);
        // }
        if (siteTree?.links) {
          links = links.concat(siteTree?.links);
        }
      });
    }
    return {
      nodes,
      links,
      // spaceNodes,
    };
  }, [siteData]);
};

const useNodeClick = (fetchByUrl: SiteData["fetchByUrl"]) => {
  return React.useCallback(
    async (node, evt) => {
      console.log({ node });
      if (node.nodeType == "A") {
        let { baseUrl } = node;
        let { href } = node.attributes || {};
        if (href) {
          try {
            let absoluteUrl = absoluteizeUrl(baseUrl, href);
            console.log({ absoluteUrl });
            await fetchByUrl(absoluteUrl);
          } catch (e) {
            console.warn({ baseUrl, href }, e);
          }
        }
      } else if (node.nodeType == "string") {
        let href;
        try {
          let { baseUrl, attributes } = node;
          if (attributes) {
            let { value } = attributes;
            let url = new URL(value, baseUrl);
            let href = url.href;
            if (href) {
              console.log({ url });
              await fetchByUrl(href);
            }
          }
        } catch (e) {
          console.warn(node, href, e);
        }
      }
    },
    [fetchByUrl],
  );
};

const urlToX: Record<string, number> = {};

const DOMVisualizer2D = (p: { siteData: SiteData }) => {
  const { siteData } = p;
  const { fetchByUrl } = siteData;

  let tree = useMergedTree(siteData);

  // let tree = React.useMemo(() => {
  //   return parseBodyTree(body);
  // }, [body]);

  // console.log({ tree });

  const graphRef = React.useRef<ForceGraphMethods>();
  const canvasRef = React.createRef<HTMLCanvasElement>();
  React.useEffect(() => {
    if (graphRef.current) {
      let size = tree?.nodes?.length;
      if (!size) {
        return;
      }
      const rootForce = Math.min(Math.max(size * 3, 500), 3000);
      graphRef.current.d3Force(
        "y",
        d3Force.forceY(rootForce).strength(function (n) {
          // console.log(arguments);
          if (isElementNode(n)) {
            if (["HTML", "HEAD"].includes(n.nodeType || "")) {
              console.log("html", { rootForce, size });
              return 1;
            }
            if (["IMG"].includes(n.nodeType || "")) {
              return 0.05;
            }

            if (["root"].includes(n.nodeType || "")) {
              console.log("root", { rootForce, size });
              return 1;
            }
          }
          return 0;
        }),
      );
      graphRef.current.d3Force(
        "link",
        d3Force.forceLink().distance(50).iterations(3).strength(0.5),
      );
      // graphRef.current.d3Force(
      //   'x',
      //   d3Force
      //     .forceX(function (n, i, all) {
      //       if (isElementNode(n)) {
      //         const baseUrl = n.baseUrl;
      //         let x = urlToX[baseUrl];
      //         if (_.isUndefined(x)) {
      //           // let size = _.filter(all, (other) => other.baseUrl == baseUrl)
      //           //   .length;
      //           // console.log(baseUrl, { size });
      //           let numUrls = Object.keys(urlToX).length;
      //           urlToX[n.baseUrl] = numUrls * 200;
      //           x = urlToX[n.baseUrl];
      //         }
      //         // console.log('forcex', arguments, x);
      //         // console.log('forcex', x);
      //         return x;
      //       } else {
      //         return 0;
      //       }
      //     })
      //     .strength(function (n) {
      //       console.log(arguments);
      //       if (isElementNode(n)) {
      //         if (['HTML', 'HEAD'].includes(n.nodeType || '')) {
      //           console.log('html', { rootForce, size });
      //           return 1;
      //         }
      //         return 0.005;
      //         // if (['IMG'].includes(n.nodeType || '')) {
      //         //   return 0.05;
      //         // }
      //       }
      //       console.log(arguments);
      //       return 0.05;
      //     }),
      // );
      // // @ts-ignore
      // graphRef.current
      //   .d3Force('link')
      //   // @ts-ignore
      //   .distance((link) => 1)
      //   // @ts-ignore
      //   .strength((link) => 1);
      // // // @ts-ignore
      // // .iterations((link) => 2);
    }
  }, [graphRef, tree?.nodes?.length, canvasRef]);

  let { width, height } = useWindowSize();

  const drawSpaceTree = useSpaceTree(tree, canvasRef, width, height, graphRef);

  const onNodeClick = useNodeClick(fetchByUrl);

  return (
    <>
      <canvas
        ref={canvasRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: (width || 1) * 0.25,
          height: (height || 1) * 0.25,
          zIndex: 1000,
        }}
      />

      <GraphView2D
        graphRef={graphRef}
        onEngineStop={drawSpaceTree}
        onZoomEnd={drawSpaceTree}
        backgroundColor="#232343"
        graphData={tree}
        nodeRelSize={10}
        onNodeClick={onNodeClick}
        linkWidth={2}
      />
    </>
  );
};

const DOMVisualizerOptions: React.FC<{
  siteData: SiteData;
  toggleIs3D: () => void;
}> = (p) => {
  let { toggleIs3D, siteData } = p;
  let actions = [];
  actions.push(
    <span key="tog3d" style={actionLinkStyle} onClick={toggleIs3D}>
      toggleIs3D
    </span>,
  );

  let urls = siteData.data?.map((treeData) => (
    <div key={treeData.uri}>{treeData.uri}</div>
  ));
  return (
    <div
      style={{
        ...floatingBoxStyle,
        left: "auto",
        right: 0,
        maxWidth: "250px",
      }}
    >
      <div>{urls}</div>
      {actions}
    </div>
  );
};

export const DOMVisualizer = (p: { siteData: SiteData }) => {
  let [is3D, setIs3D] = React.useState(false);
  let toggleIs3D = React.useCallback(() => setIs3D(!is3D), [is3D]);
  let content;
  if (is3D) {
    // content = <DOMVisualizer3D {...p} />;
  } else {
    content = <DOMVisualizer2D {...p} />;
  }

  return (
    <>
      <DOMVisualizerOptions siteData={p.siteData} toggleIs3D={toggleIs3D} />
      {content}
    </>
  );
};

const rootNodeTypes = ["HTML", "root"];

type Point = {
  x: number;
  y: number;
};

function normalize(
  pt: Point,
  width: number,
  height: number,
  treeWidth: number,
  treeHeight: number,
  graphRef: React.MutableRefObject<ForceGraphMethods | undefined>,
) {
  let fWidth = width || 1;
  let fHeight = height || 1;
  let { x, y } = pt;
  if (!graphRef.current) {
    // throw new Error('no graph');
    return undefined;
  }
  let norm = graphRef.current.graph2ScreenCoords(x, y);
  return {
    x: (norm.x / fWidth) * treeWidth,
    y: (norm.y / fHeight) * treeHeight,
  };
}

function useSpaceTree(
  tree: ReturnType<typeof useMergedTree> | undefined,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  width: number | undefined,
  height: number | undefined,
  graphRef: React.MutableRefObject<ForceGraphMethods | undefined>,
) {
  const drawSpaceTree = React.useCallback(() => {
    console.log("stopped", {
      tree,
    });
    let canvas = canvasRef.current;
    if (canvas && width && height) {
      // let spaceNodes = tree?.spaceNodes;
      // console.log('draw space');
      let treeWidth = parseInt(canvas?.style.width || "0");
      let treeHeight = parseInt(canvas?.style.height || "0");

      canvas.width = treeWidth;
      canvas.height = treeHeight;
      let ctx = canvas.getContext("2d");

      let roots: (Point | undefined)[] = [];
      let points = tree?.nodes
        .map((node) => {
          let { nodeType } = node;
          if (rootNodeTypes.includes(nodeType || "")) {
            roots.push(
              normalize(
                {
                  x: node.x || 0,
                  y: node.y || 0,
                },
                width,
                height,
                treeWidth,
                treeHeight,
                graphRef,
              ),
            );
          }
          // let spaceNode = spaceNodes?.find((sn) => sn.id == node.id);
          // if (!spaceNode) {
          //   console.log(node.id, 'spacenode not found');
          // }
          if (node.x && node.y && width && height) {
            let pos = normalize(
              {
                x: node.x || 0,
                y: node.y || 0,
              },
              width,
              height,
              treeWidth,
              treeHeight,
              graphRef,
            );

            // if (spaceNode && pos && ctx) {
            //   spaceNode.position = new Vec2([pos.x, pos.y]);
            //   spaceNode.ctx = ctx;
            // }

            return pos;
          }
        })
        .filter((pt) => !!pt);
      // console.log({ points });
      // spaceNodes?.forEach((n) => n.ctx && n.draw());
      destroy();
      setup(canvasRef.current, points, roots);
    }
  }, [graphRef, canvasRef, height, tree, width]);

  React.useEffect(() => {
    return () => {
      destroy();
    };
  }, []);
  return drawSpaceTree;
}

// const DOMVisualizer3D = (p: { siteData: SiteData }) => {
//   const { siteData } = p;
//   const { fetchByUrl } = siteData;
//   let tree = useMergedTree(siteData);

//   // let tree = React.useMemo(() => {
//   //   return parseBodyTree(body);
//   // }, [body]);

//   // console.log({ tree });

//   const graphRef = React.useRef<ForceGraph3DMethods>();

//   React.useEffect(() => {
//     // console.log({ graphRef });
//     if (graphRef.current) {
//       let size = tree?.nodes?.length;
//       if (!size) {
//         return;
//       }

//       const rootForce = Math.min(Math.max(size * 3, 500), 3000);
//       graphRef.current.d3Force(
//         'z',
//         d3Force.forceY(-rootForce).strength(function (n) {
//           // console.log(arguments);
//           if (isElementNode(n)) {
//             if (['HTML', 'HEAD'].includes(n.nodeType || '')) {
//               console.log('html');
//               return 1;
//             }
//           }
//           return 0;
//         }),
//       );
//     }
//   }, [graphRef, tree?.nodes?.length]);

//   const onNodeClick = useNodeClick(fetchByUrl);
//   let { width, height } = useWindowSize();

//   // console.log({ data });
//   return (
// <ForceGraph3D
//       // dagMode="bu"
//       // dagLevelDistance={150}
//       width={width}
//       height={height}
//       backgroundColor="#232343"
//       ref={graphRef}
//       graphData={tree}
//       nodeRelSize={10}
//       nodeOpacity={1}
//       // cooldownTime={5000}
//       // cooldownTicks={5000}
//       // d3AlphaDecay={0.002}
//       // d3AlphaMin={0.95}
//       // nodeVisibility={(n) => {
//       //   let { x, y } = n;
//       //   if (!x || !y || !width || !height) {
//       //     return true;
//       //   }
//       //   if (graphRef.current) {
//       //     let coords = graphRef.current.graph2ScreenCoords(x, y, z);
//       //     if (
//       //       coords.x < 0 ||
//       //       coords.y < 0 ||
//       //       coords.x > width ||
//       //       coords.y > height
//       //     ) {
//       //       return false;
//       //     }
//       //   }
//       //   return true;
//       // }}
//       onNodeClick={onNodeClick}
//       linkWidth={2}
//     />
//   );
// };
