import Vec2 from "vec2";

import Network from "./jasonwebb/core/Network";
import {
  getRandomAttractors,
  getGridOfAttractors,
  applyNoise,
  getPhyllotaxisAttractors,
  getWaveOfAttractors,
} from "./jasonwebb/core/AttractorPatterns";
import Node from "./jasonwebb/core/Node";
import { random } from "./jasonwebb/core/Utilities";
import { setupKeyListeners } from "./jasonwebb/core/KeyboardInteractions";
import Settings from "./jasonwebb/basic/js/Settings";
import Attractor from "./jasonwebb/core/Attractor";

let canvas, ctx;
let network;

let showHelp = false;
let animating;
let keyListener;
let unbindKeyListeners;
// Create initial conditions for simulation
let setup = (canvas, attractorPoints, root) => {
  if (!canvas) {
    console.log("no canavs");
    return;
  }
  // Initialize canvas and context
  //   canvas = document.getElementById('sketch');
  ctx = canvas.getContext("2d");

  // canvas.width = window.innerWidth;
  // canvas.height = window.innerHeight;

  // Key commands specific to this sketch
  document.removeEventListener("keypress", keyListener);
  if (unbindKeyListeners) {
    unbindKeyListeners();
  }
  keyListener = onKeyPress(canvas, attractorPoints, root);
  document.addEventListener("keypress", keyListener);
  console.log({ root });
  // Setup Network with initial conditions
  setupNetwork(canvas.width, canvas.height, attractorPoints, root);

  // Begin animation loop
  animating = requestAnimationFrame(update);
};

function onKeyPress(canvas, attractorPoints, root) {
  return (e) => {
    switch (e.key) {
      // r = reset simulation by reinitializing the network with initial conditions
      case "r":
        setupNetwork(canvas.width, canvas.height, attractorPoints, root);
        if (!animating) {
          animating = requestAnimationFrame(update);
        }
        break;

      // h = toggle help text
      case "h":
        showHelp = !showHelp;
        break;
    }
  };
}

function attractorPointsToAttractors(attractorPoints = [], ctx, settings) {
  return attractorPoints.map((pt) => {
    return new Attractor(new Vec2(pt.x, pt.y), ctx, settings);
  });
}

// Create the network with initial conditions
let setupNetwork = (width, height, attractorPoints, roots) => {
  // Initialize simulation object
  network = new Network(ctx, Settings);
  let attractors;
  if (attractorPoints) {
    attractors = attractorPointsToAttractors(attractorPoints, ctx, Settings);
  } else {
    // Set up the attractors using pre-made patterns
    let randomAttractors = getRandomAttractors(
      500,
      ctx,
      undefined,
      undefined,
      width,
      height,
      Settings,
    );
    // let gridAttractors = getGridOfAttractors(150, 100, ctx, 10);
    // let phyllotaxisAttractors = getPhyllotaxisAttractors(
    //   ctx,
    //   width,
    //   height,
    //   Settings,
    // );
    // let waveAttractors = getWaveOfAttractors(ctx, width, height, Settings);
    attractors = randomAttractors;
  }

  // console.log({
  //   attractors,
  //   ra: getRandomAttractors(
  //     500,
  //     ctx,
  //     undefined,
  //     undefined,
  //     width,
  //     height,
  //     Settings,
  //   ),
  //   root,
  //   Settings,
  //   width,
  //   height,
  //   // gridAttractors,
  // });
  network.attractors = attractors;
  // network.attractors = waveAttractors;

  // Add a set of random root nodes throughout scene
  // for (let i = 0; i < 1; i++) {

  if (!roots || !roots.length) {
    // || root.x > width || root.y > height) {
    roots = [
      {
        x: random(width),
        y: random(height),
      },
    ];
  }

  roots.forEach((root) => {
    if (!root) {
      return;
    }
    network.addNode(
      new Node(null, new Vec2(root.x, root.y), true, ctx, Settings),
    );
  });
  // }

  // Set up common keyboard interaction listeners
  unbindKeyListeners = setupKeyListeners(network);
};

let drawText = () => {
  let text = [
    "Space = toggle pause",
    "r = reset",
    "c = toggle canalization",
    "p = toggle opacity blending",
    "b = toggle branch visibility",
    "a = toggle attractor visibility",
    "z = toggle attraction zones",
    "k = toggle kill zones",
    "t = toggle tips",
    "i = toggle influence lines",
    "h = toggle this help text",
  ];

  ctx.font = "16px sans-serif";
  ctx.fillStyle = "rgba(0,0,0,.5)";
  for (let i = 0; i < text.length; i++) {
    ctx.fillText(text[i], 20, 22 * i + 10);
  }
};

// Main program loop
let update = (timestamp) => {
  let finished = network.update();
  network.draw();

  if (showHelp) {
    drawText();
  }
  if (!finished) {
    animating = requestAnimationFrame(update);
  } else {
    animating = null;
  }
};

let destroy = () => {
  document.removeEventListener("keypress", keyListener);
  cancelAnimationFrame(animating);
  animating = null;
  if (unbindKeyListeners) {
    unbindKeyListeners();
  }
};
export { setup, destroy };
// Kick off the application
// setup();
