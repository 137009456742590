import * as _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import {
  AppState,
  Group,
  GroupLink,
} from "../../../extension/src/shared/types";
import {
  addNodeToGroups,
  createGroup,
  fetchGroupsForNode,
} from "../redux/reducers/history/groups";
import { Icon } from "./Icon";
import { useInView } from "react-hook-inview";
import { useLoadExtensionState } from "./useLoadExtensionState";
import { Collapsible } from "../shared/Collapsible";

export const GroupingControl: React.FC<GroupingControlProps> = (props) => {
  const [ref, inView] = useInView();
  const [everWasInView, setEverWasInview] = React.useState(inView);
  React.useEffect(() => {
    if (inView) {
      setEverWasInview(true);
    }
  }, [inView]);

  let display;
  if (everWasInView) {
    display = <GroupingControlInner {...props} />;
  }
  return <div ref={ref}>{display}</div>;
};

type GroupingControlProps = {
  nodeId: string;
  nodeType: GroupLink["nodeType"];
  noCollapse?: boolean;
  iconSize?: React.ComponentProps<typeof Icon>["size"];
};

export const GroupingControlInner: React.FC<GroupingControlProps> = ({
  nodeId,
  nodeType,
  noCollapse,
  iconSize,
}) => {
  let currentGroupIds = useSelector((s: RootState) => {
    let allItemGroups = s.history.groups.forNodes;
    return allItemGroups[nodeId] || [];
  });

  let allGroups = useSelector((s: RootState) => {
    let allItemGroups = s.history.groups.all;
    return allItemGroups;
  });

  let isLoaded = useSelector((s: RootState) => {
    return (
      s.history.groups.loaded.includes(nodeId) ||
      s.history.groups.failed.includes(nodeId)
    );
  });

  // React.useEffect(() => {
  //   dispatch(getGroupsForNode(nodeId));
  // }, []);
  const [pendingAddGroups, setPendingAddGroups] = React.useState(
    [] as string[],
  );
  const [pendingRemoveGroups, setPendingRemoveGroups] = React.useState(
    [] as string[],
  );

  const togglePendingGroup = React.useCallback(
    (groupId: string) => {
      let pendingGroupState = setPendingAddGroups;

      if (currentGroupIds.includes(groupId)) {
        pendingGroupState = setPendingRemoveGroups;
      }

      pendingGroupState((currentGroups) => {
        if (currentGroups.includes(groupId)) {
          return _.without(currentGroups, groupId);
        } else {
          return [...currentGroups, groupId];
        }
      });
    },
    [currentGroupIds],
  );

  const dispatch = useDispatch<AppDispatch>();

  const fetchGroups = React.useCallback(() => {
    return dispatch(fetchGroupsForNode(nodeId));
  }, [dispatch, nodeId]);

  useLoadExtensionState({
    isLoaded,
    loadFromExtension: fetchGroups,
  });

  const addNodeToPendingGroups = React.useCallback(async () => {
    let res = await dispatch(
      addNodeToGroups({
        nodeType,
        nodeId,
        addToGroupIds: pendingAddGroups,
        removeFromGroupIds: pendingRemoveGroups,
      }),
    );

    if (!(res as any).error) {
      setPendingAddGroups([]);
      setPendingRemoveGroups([]);
    }
  }, [dispatch, nodeId, pendingAddGroups, pendingRemoveGroups, nodeType]);

  let currentGroupsDisplay = currentGroupIds.map((groupId) => {
    let group = _.find(allGroups, (g) => g.id == groupId);
    if (group) {
      return <GroupIndicator iconSize={iconSize} key={groupId} group={group} />;
    }
  });
  const [showingTitles, setShowingTitles] = React.useState(false);
  const toggleTitles = React.useCallback(() => {
    setShowingTitles(!showingTitles);
  }, [showingTitles]);

  let toggleTitleDisplay = (
    <span
      onMouseEnter={toggleTitles}
      onMouseLeave={toggleTitles}
      style={{ padding: "5px", cursor: "pointer" }}
    >
      ?
    </span>
  );

  let allGroupsDisplay: React.ReactNode = React.useMemo(() => {
    return _.sortBy(allGroups, "title").map((group) => {
      let isPendingAdd = pendingAddGroups.includes(group.id);
      let isPendingRemove = pendingRemoveGroups.includes(group.id);

      let currentMember = currentGroupIds.includes(group.id);

      let backgroundColor = "transparent";

      if (currentMember) {
        if (isPendingRemove) {
          backgroundColor = "red";
        } else {
          backgroundColor = "green";
        }
      } else if (isPendingAdd) {
        backgroundColor = "blue";
      }

      return (
        <GroupIndicator
          key={group.id}
          group={group}
          backgroundColor={backgroundColor}
          onClick={togglePendingGroup}
          showTitle={showingTitles}
        />
      );
    });
  }, [
    allGroups,
    currentGroupIds,
    pendingAddGroups,
    pendingRemoveGroups,
    showingTitles,
    togglePendingGroup,
  ]);

  allGroupsDisplay = (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {allGroupsDisplay}
    </div>
  );

  let addToPendingGroups =
    pendingAddGroups.length || pendingRemoveGroups.length ? (
      <div>
        <Icon
          size={iconSize}
          iconName="checkmark"
          onClick={addNodeToPendingGroups}
          title="groups"
        />
      </div>
    ) : null;

  const expandedContent = (
    <div>
      {toggleTitleDisplay}
      {allGroupsDisplay}
      {addToPendingGroups}
      <NewGroupForm />
    </div>
  );

  if (noCollapse) {
    return expandedContent;
  }

  const icon = <Icon size={iconSize} iconName="group" title="groups" />;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Collapsible
        trigger={icon}
        triggerStyles={{
          display: "inline-block",
          alignSelf: "flex-start",
        }}
        placeHolderContent={currentGroupsDisplay}
      >
        {expandedContent}
      </Collapsible>
    </div>
  );
};
const NewGroupForm: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const titleRef = React.useRef<HTMLInputElement>(null);
  const shortIndicatorRef = React.useRef<HTMLInputElement>(null);
  const onSubmit = React.useCallback(
    async (evt) => {
      evt.preventDefault();
      if (titleRef.current && shortIndicatorRef.current) {
        let newGroup = {
          title: titleRef.current.value,
          shortIndicator: shortIndicatorRef.current.value,
        };
        if (newGroup.title && newGroup.shortIndicator) {
          let out = await dispatch(createGroup(newGroup));
          console.log({ out });
          // @ts-ignore
          if (!out.error) {
            titleRef.current.value = "";
            shortIndicatorRef.current.value = "";
          }
        }
      }
    },
    [dispatch],
  );
  return (
    <form onSubmit={onSubmit}>
      <input type="text" placeholder="title" ref={titleRef} />
      <input
        type="text"
        placeholder="short indicator"
        ref={shortIndicatorRef}
      />
      <Icon iconName="add-circle" title="create group" onClick={onSubmit} />
    </form>
  );
};
export const GroupIndicator: React.FC<{
  group: Group;
  backgroundColor?: string;
  showTitle?: boolean;
  onClick?: (groupId: string) => void;
  iconSize?: GroupingControlProps["iconSize"];
}> = ({ group, backgroundColor, onClick, showTitle, iconSize }) => {
  const { shortIndicator, title } = group;
  const borderColor = backgroundColor || "black";
  const cursor = !!onClick ? "pointer" : undefined;
  const titleDisplay = showTitle ? (
    <div style={{ fontSize: "11px" }}>{title}</div>
  ) : null;

  const fontSize = iconSize == "small" ? "10px" : "20px";

  return (
    <div
      key={group.id}
      style={{
        display: "inline-block",
        fontSize,
        backgroundColor,
        borderRadius: "100%",
        border: `3px solid ${borderColor}`,
        padding: "5px",
        cursor,
      }}
      onClick={onClick?.bind(null, group.id)}
      title={title}
    >
      {shortIndicator}
      {titleDisplay}
    </div>
  );
};
