import debug from "debug";
import * as _ from "lodash";
import React from "react";
import { NodeObject } from "react-force-graph-2d";
import { useSelector } from "react-redux";
import { logPrefix } from "../../../extension/src/constants";
import {
  Annotation,
  CompletedTabNavigation,
  PageAction,
} from "../../../extension/src/shared/types";
import {
  HistoryItemNode,
  notEmpty,
  WebHistoryData,
  WebHistoryNode,
} from "../hooks/useWebHistoryData";
import { useAllGroups } from "../redux/hooks/useAllGroups";
import { RootState } from "../redux/store";
import { Collapsible } from "../shared/Collapsible";
import { Debug } from "../shared/Debug";
import TreeUtils from "../utils/tree-utils";
import { floatingBoxStyle } from "../WebViewer";
import { PageInfo } from "./PageInfo";

const { TreeModel } = TreeUtils;

export type ActionIdToAnnotations = Record<string, Annotation[]>;
export type NavigationIdToActionToAnnotations = Record<
  string,
  ActionIdToAnnotations
>;

const grouperLog = debug(`${logPrefix}group`);
// grouperLog.enabled = true;

export type NodeTypes = PageAction | CompletedTabNavigation;

export const WebHistoryGrouper = (p: { webData: WebHistoryData }) => {
  const extensionState = useSelector((s: RootState) => {
    return s.history.extension.appState;
  });
  const rootId = "root";

  const [graph, displayTree, nodeDict] = React.useMemo(() => {
    if (!extensionState) {
      return [];
    }
    const { completedNavigations, pageActions, annotations } = extensionState;

    let nodeDict: Record<string, CompletedTabNavigation> = {};
    let displayTree = TreeUtils.createTree(rootId);

    let urlToAnno = annotations.reduce((rec, anno) => {
      rec[anno.url] = rec[anno.url] || [];
      rec[anno.url].push(anno);
      return rec;
    }, {} as Record<string, Annotation[]>);

    const addNodeToTree = (n: CompletedTabNavigation) => {
      let { id, sourceNavigationId } = n;

      nodeDict[id] = n;
      if (TreeUtils.nodeExists(displayTree, n.id)) {
        return;
      }

      let parentNode;
      if (sourceNavigationId) {
        parentNode = TreeUtils.nodeExists(displayTree, sourceNavigationId);
        if (!parentNode) {
          // parentNode = TreeModel.parse({
          //   node: sourceNavigationId,
          //   actions: [] as PageAction[],
          // });
          let parentNav = completedNavigations.find(
            (n) => n.id == sourceNavigationId,
          );
          if (parentNav) {
            addNodeToTree(parentNav);
            parentNode = TreeUtils.nodeExists(displayTree, sourceNavigationId);
          } else {
            grouperLog("parentNav not found", n, "add as needs info node");
            parentNode = TreeModel.parse({
              node: sourceNavigationId,
              actions: [] as PageAction[],
              needsInfo: true,
            });
            // @ts-ignore
            nodeDict[sourceNavigationId] = {
              id: sourceNavigationId,
              finalDest: n.sourceUrl,
            };
            TreeUtils.addNode(displayTree, displayTree, parentNode);
          }
        }
      }
      if (!parentNode) {
        parentNode = displayTree;
      }

      let navNode = TreeModel.parse({
        node: id,
        actions: [] as PageAction[],
        annotations: {},
      });

      let actions = _.chain(pageActions)
        .filter((a) => a.navigationId == id)
        .sortBy("timeStamp")
        .value();

      let annotations = urlToAnno[n.finalDest] || [];
      let urlAnnotations = annotations.filter(
        (a) => a.targetType == "url" && a.targetId == n.finalDest,
        // should url annotations be per nav or for every url?
        // && a.targetNavigationId == id
      );
      let actionsToAnnos = actions.reduce(
        (rec, action) => {
          let forAction = annotations.filter((a) => a.targetId == action.id);
          if (forAction.length) {
            rec[action.id] = forAction;
          }
          return rec;
        },
        {
          [n.finalDest]: urlAnnotations,
        } as Record<string, Annotation[]>,
      );

      actionsToAnnos = annotations.reduce((rec, annotation) => {
        if (
          annotation.navigationId == id
          // &&
          // annotation.targetNavigationId != id
        ) {
          rec[id] = rec[id] || [];
          rec[id].push(annotation);
        }
        return rec;
      }, actionsToAnnos);

      navNode.model.actions = actions;
      navNode.model.annotations = actionsToAnnos;
      // console.log("adding", navNode, "to", parentNode);
      TreeUtils.addNode(displayTree, parentNode, navNode);
    };

    completedNavigations.forEach(addNodeToTree);

    return [null, displayTree, nodeDict];
  }, [extensionState]);

  const [
    display,
    pages,
    pageEvents,
    pageLinks,
    pageAnnotations,
  ] = React.useMemo(() => {
    if (!displayTree || !nodeDict) {
      return [];
    }
    let pages: CompletedTabNavigation[] = [];
    let orphanPages: CompletedTabNavigation[] = [];
    let pageEvents: Record<string, PageAction[]> = {};

    let pageAnnotations: Record<string, ActionIdToAnnotations> = {};
    let pageLinks: Record<string, CompletedTabNavigation[]> = {};

    TreeUtils.walk(displayTree, (node) => {
      let id = node.model.node;
      let historyNode = nodeDict[id];
      if (historyNode) {
        grouperLog("nav", historyNode, node);

        if (!node.parent || node.parent.model.node == rootId) {
          if (
            historyNode.sourceUrl != "about:blank" &&
            !historyNode.sourceNavigationId &&
            !node.model.needsInfo
          ) {
            orphanPages.push(historyNode);
          } else {
            pages.push(historyNode);
          }
        }
        pageLinks[historyNode.id] = pageLinks[historyNode.id] || [];
        pageLinks[historyNode.id] = node.children
          .map((n) => {
            let node = nodeDict[n.model.node];
            if (node && isCompletedNavigation(node)) {
              return node;
            }
          })
          .filter(notEmpty);
        pageEvents[historyNode.id] = node.model.actions || [];
        pageAnnotations[historyNode.id] = node.model.annotations || {};
      } else {
        if (id != rootId) {
          console.warn("tree node not found", node);
        }
      }
    });

    let normalPages = pages.map((historyNode) => {
      const key = pageKey(historyNode);
      return (
        <PageInfo
          actualDepth={0}
          depth={0}
          key={key}
          historyNode={historyNode}
          pageEvents={pageEvents}
          pageLinks={pageLinks}
          pageAnnotations={pageAnnotations}
        />
      );
    });

    let orphanPagesDisplay = !!orphanPages.length && (
      <Collapsible
        title="orphans"
        noScroll
        makeChildren={() => {
          return orphanPages.map((historyNode) => {
            const key = pageKey(historyNode);
            return (
              <PageInfo
                actualDepth={0}
                depth={0}
                key={key}
                historyNode={historyNode}
                pageEvents={pageEvents}
                pageLinks={pageLinks}
                pageAnnotations={pageAnnotations}
              />
            );
          });
        }}
      />
    );

    let output = (
      <>
        {normalPages}
        {orphanPagesDisplay}
      </>
    );
    return [output, pages, pageEvents, pageLinks, pageAnnotations];
  }, [displayTree, nodeDict]);

  useAllGroups();

  return (
    <>
      <div>{display}</div>
      <div
        style={{
          ...floatingBoxStyle,
          top: "auto",
          left: "auto",
          right: 0,
          bottom: 0,
        }}
      >
        <Debug>{{ pages, pageLinks, pageEvents, pageAnnotations }}</Debug>
      </div>
    </>
  );
};

export function pageKey(historyNode: CompletedTabNavigation) {
  return `page-${historyNode.id}`;
}

export function isPageNode(n: NodeObject): n is HistoryItemNode {
  return (n as WebHistoryNode).nodeType == "history-item";
}

export function isCompletedNavigation(
  n: NodeTypes,
): n is CompletedTabNavigation {
  return (
    !!(n as CompletedTabNavigation).tabId &&
    !!(n as CompletedTabNavigation).destUrl
  );
}

function isActionItem(n: NodeTypes): n is PageAction {
  return !!(n as PageAction).url && !!(n as PageAction).target;
}
