export const DEFAULT_CONFIG = {
  // proctree
  seed: 256,
  segments: 6,
  levels: 5,
  vMultiplier: 2.36,
  // twigScale: 0.39,
  twigScale: 0,
  initalBranchLength: 0.49,
  lengthFalloffFactor: 0.85,
  lengthFalloffPower: 0.99,
  clumpMax: 0.454,
  clumpMin: 0.404,
  branchFactor: 2.45,
  // dropAmount: -0.1,
  dropAmount: 0.0,
  growAmount: 0.235,
  sweepAmount: 0.01,
  maxRadius: 0.139,
  climbRate: 0.371,
  trunkKink: 0.093,
  treeSteps: 5,
  taperRate: 0.947,
  radiusFalloffRate: 0.73,
  twistRate: 3.02,
  trunkLength: 2.4,

  // custom
  treeColor: 0x9d7362,
  twigColor: 0xf16950,
};
// export const DEFAULT_CONFIG = {
//   // proctree
//   seed: 256,
//   segments: 6,
//   levels: 5,
//   vMultiplier: 2.36,
//   twigScale: 0.39,
//   initalBranchLength: 0.49,
//   lengthFalloffFactor: 0.85,
//   lengthFalloffPower: 0.99,
//   clumpMax: 0.454,
//   clumpMin: 0.404,
//   branchFactor: 2.45,
//   dropAmount: -0.1,
//   growAmount: 0.235,
//   sweepAmount: 0.01,
//   maxRadius: 0.139,
//   climbRate: 0.371,
//   trunkKink: 0.093,
//   treeSteps: 5,
//   taperRate: 0.947,
//   radiusFalloffRate: 0.73,
//   twistRate: 3.02,
//   trunkLength: 2.4,

//   // custom
//   treeColor: 0x9d7362,
//   twigColor: 0xf16950,
// };
