import * as React from "react";
import { render } from "react-dom";
import { Router, Link, RouteComponentProps } from "@reach/router";
import { DOMViewer } from "./DOMViewer";
import { WebViewer } from "./WebViewer";
import { WebHistory } from "./WebHistory/WebHistory";
import { DOM3DVisualizer } from "./3DProcTree";
import { JobsViewer, JobStatus } from "./jobs/JobsViewerPage";
import { WorkFlowEditor } from "./workflow/WorkFlowEditor";

type RouterPage<T = object> = React.FC<RouteComponentProps & T>;

const WebViewerPage: RouterPage = () => <WebViewer />;
const WebHistoryPage: RouterPage = () => <WebHistory />;
const DOMViewerPage: RouterPage<{ resourceId?: string }> = (p) => (
  <DOMViewer resourceId={p.resourceId || ""} />
);
const DOM3DVisualizerPage: RouterPage = () => <DOM3DVisualizer />;

const JobsPage: RouterPage<{ jobStatus?: JobStatus; queueName?: string }> = (
  p,
) => <JobsViewer jobStatus={p.jobStatus} queueName={p.queueName} />;

const WorkFlowPage: RouterPage<{}> = (p) => <WorkFlowEditor />;

const AppRouter = () => {
  return (
    <Router>
      <DOMViewerPage path="/dom/:resourceId" />
      <DOM3DVisualizerPage path="/proctree" />
      <JobsPage path="/jobs" />
      <JobsPage path="/jobs/:queueName/:jobStatus" />
      <WorkFlowPage path="/workflow" />
      <WebViewerPage path="/" />
      <WebHistoryPage path="/history" />
    </Router>
  );
};
export default AppRouter;
