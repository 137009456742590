import { combineReducers } from "@reduxjs/toolkit";
import { screenshotsSlice } from "./screenshots";
import { extensionStateSlice } from "./extensionState";
import { groupsSlice } from "./groups";
import { mediaSlice } from "./media";

// const hostFiltersSlice = createSlice({
//   name: 'hosts.filters',
//   initialState: {
//     excluded: [] as string[],
//   },
//   reducers: {
//     excludeHost: (state, action: PayloadAction<string>) => {
//       let hostName = action.payload;
//       console.log('should exclude', hostName);
//       state.excluded = _.uniq(state.excluded.concat([hostName]));
//       return state;
//     },
//     includeHost: (state, action: PayloadAction<string>) => {
//       let hostName = action.payload;
//       console.log('should inclkude', hostName);
//       state.excluded = _.without(state.excluded, hostName);
//       return state;
//     },
//   },
//   extraReducers: (builder) => {},
// });

export const actions = {
  ...screenshotsSlice.actions,
  ...extensionStateSlice.actions,
  ...mediaSlice.actions,
};
export default combineReducers({
  screenshots: screenshotsSlice.reducer,
  extension: extensionStateSlice.reducer,
  groups: groupsSlice.reducer,
  media: mediaSlice.reducer,
  //   filters: hostFiltersSlice.reducer,
});
