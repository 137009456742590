import * as _ from "lodash";
import * as React from "react";
import { actionLinkStyle, floatingBoxStyle } from "./WebViewer";
import { JobCountsSection } from "./JobsCountSection";
import { ManualCrawler } from "./ManualCrawler";
export const TopRightPanel: React.FC<{}> = () => {
  return (
    <div
      style={{
        ...floatingBoxStyle,
        top: 0,
        left: "auto",
        right: 0,
        maxWidth: "50vw",
      }}
    >
      <JobCountsSection />
      <ManualCrawler />
    </div>
  );
};
