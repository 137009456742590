import React, { CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWebHistoryData, WebHistoryData } from "../hooks/useWebHistoryData";
import {
  fetchHistoryForHost,
  fetchHistoryForUrl,
  fetchResultsForSearch,
} from "../redux/reducers/history/extensionState";
import { toggleFilterForGroup } from "../redux/reducers/history/groups";
import { RootState } from "../redux/store";
import { GroupIndicator } from "./GroupingControl";
import { WebHistoryGrouper } from "./WebHistoryGrouper";
import { WebHistoryNodeVisualizer } from "./WebHistoryNodeVisualizer";
import { WebHistoryTimeSelector } from "./WebHistoryTimeSelector";
import { WebHistoryVisualizer } from "./WebHistoryVisualizer";

interface AppProps {}

export const floatingBoxStyle = {
  position: "fixed" as const,
  top: 0,
  left: 0,
  background: "white",
  borderRadius: "5px",
  border: "1px solid gray",
  padding: "15px",
  margin: "5px",
  maxWidth: "calc(100vw - 250px)",
  overflow: "hidden",
  zIndex: 1000,
};

export const actionLinkStyle: CSSProperties = {
  textDecoration: "underline",
  cursor: "pointer",
  userSelect: "none",
};

// const hostnameFilterKey = `wm:hostnameFilter`;
const WebHistoryHostSelector: React.FC<{}> = ({}) => {
  const dispatch = useDispatch();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const onSubmit = React.useCallback(
    (evt) => {
      evt.preventDefault();
      if (inputRef.current) {
        // setValue(inputRef.current.value);
        dispatch(
          fetchHistoryForHost({ hostnameFilter: inputRef.current.value }),
        );
      }
    },
    [dispatch],
  );

  return (
    <div>
      <form onSubmit={onSubmit}>
        <label style={{ display: "flex" }}>
          <span>hostfilter:</span>{" "}
          <input
            type="text"
            ref={inputRef}
            // defaultValue={initial}
            name="wm-hosts"
          />{" "}
          <input type="submit" />
        </label>
      </form>
    </div>
  );
};

const WebHistoryUrlAdder: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const onSubmit = React.useCallback(
    (evt) => {
      evt.preventDefault();
      if (inputRef.current) {
        dispatch(fetchHistoryForUrl(inputRef.current.value));
        inputRef.current.value = "";
      }
    },
    [dispatch],
  );
  return (
    <div>
      <form onSubmit={onSubmit}>
        <label style={{ display: "flex" }}>
          <span>add url:</span>{" "}
          <input type="text" ref={inputRef} name="wm-url" />{" "}
          <input type="submit" />
        </label>
      </form>
    </div>
  );
};
const WebHistoryGroupSelector: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const allGroups = useSelector((s: RootState) => {
    return s.history.groups.all;
  });
  const filterGroups = useSelector((s: RootState) => {
    return s.history.groups.filtered;
  });

  const onGroupClick = React.useCallback(
    (groupId: string) => {
      dispatch(toggleFilterForGroup(groupId));
    },
    [dispatch],
  );

  let display = allGroups.map((g) => {
    let backgoundColor = filterGroups.includes(g.id) ? "blue" : "transparent";
    return (
      <GroupIndicator
        backgroundColor={backgoundColor}
        onClick={onGroupClick}
        key={g.id}
        group={g}
      />
    );
  });

  return <div>{display}</div>;
};

const WebHistorySearch: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const onSubmit = React.useCallback(
    (evt) => {
      evt.preventDefault();
      if (inputRef.current) {
        dispatch(fetchResultsForSearch(inputRef.current.value));
        inputRef.current.value = "";
      }
    },
    [dispatch],
  );
  return (
    <div>
      <form onSubmit={onSubmit}>
        <label style={{ display: "flex" }}>
          <span>search:</span>{" "}
          <input type="text" ref={inputRef} name="wm-search" />{" "}
          <input type="submit" />
        </label>
      </form>
    </div>
  );
};
``;
const WebHistoryForm: React.FC<{ webData: WebHistoryData }> = (props) => {
  const { webData } = props;

  return (
    <div style={{ display: "flex" }}>
      <div>
        <WebHistoryHostSelector />
        <WebHistoryUrlAdder />
        <WebHistorySearch />
      </div>
      <WebHistoryTimeSelector />
      <WebHistoryGroupSelector />
    </div>
  );
};

export function WebHistory({}: AppProps) {
  let webData = useWebHistoryData();
  // let { hosts } = webData;
  // let { excluded, excludedResourceTypes } = webData.data;
  // let dispatch = useDispatch();

  // let [triedHosts, setTriedHosts] = React.useState(false);
  // React.useEffect(() => {
  //   if (
  //     !triedHosts &&
  //     hosts.length == 0 &&
  //     excluded.length == 0 &&
  //     excludedResourceTypes.length == 0
  //   ) {
  //     setTriedHosts(true);
  //     dispatch(fetchHosts());
  //   }
  // }, [dispatch, hosts, excluded, excludedResourceTypes, triedHosts]);

  return (
    <div className="App wm-exclude" style={{ marginBottom: 25 }}>
      <div style={{ height: "70vh", overflow: "hidden" }}>
        <WebHistoryVisualizer webData={webData} />
      </div>
      <div style={{ height: "30vh" }}>
        <WebHistoryForm webData={webData} />
        <WebHistoryGrouper webData={webData} />
      </div>
      <WebHistoryNodeVisualizer webData={webData} />
      {/* <ResourceNavigator /> */}
      {/* <TopRightPanel /> */}
    </div>
  );
}
