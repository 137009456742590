const API_HOST = process.env.API_HOST;
const API_PORT = process.env.API_PORT;
const API_PROTOCOL = process.env.API_PROTOCOL;

const apiHost = API_HOST || "localhost";
const apiPort = API_PORT ? `:${API_PORT}` : "";
const apiProtocol = API_PROTOCOL || "http";

export const mediaMirrorUrl =
  process.env.MEDIA_MIRROR_URL || `http://localhost:7080/media/`;

export const apiUrl = `${apiProtocol}://${apiHost}${apiPort}/api`;

// export const apiUrl = 'http://localhost:7080/api';

// export const apiUrl = 'http://192.168.99.103/api';
