import React from "react";
import { useDispatch } from "react-redux";
import { fetchHistoryForDateRange } from "../redux/reducers/history/extensionState";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

export const WebHistoryTimeSelector: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const handleSelect = React.useCallback(
    (e) => {
      console.log(e);
      let { selection } = e;
      setSelectionRange(selection);
      if (selection && selection.startDate && selection.endDate) {
        let { startDate, endDate } = selection;

        if (startDate == endDate) {
          endDate = new Date(startDate.getTime() + 60 * 60 * 24 * 1000);
        }

        dispatch(
          fetchHistoryForDateRange({
            startDate,
            endDate,
          }),
        );
      }
    },
    [dispatch],
  );

  const [selectionRange, setSelectionRange] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  return (
    <div>
      <style>
        {`.rdrDefinedRangesWrapper {
          display: none
        }`}
      </style>
      <DateRangePicker
        staticRanges={[]}
        inputRanges={[]}
        ranges={[selectionRange]}
        onChange={handleSelect}
      />
      {/* <Calendar date={new Date()} onChange={handleSelect} /> */}
    </div>
  );
};
