function hashString(value: string) {
  var hash = 0,
    i,
    chr;
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
let strHexes: Record<string, string> = {};
export function strToHex(value: string) {
  if (strHexes[value]) {
    return strHexes[value];
  }
  let hash = hashString(value);

  let r = (hash & 0xff0000) >> 16;
  let g = (hash & 0x00ff00) >> 8;
  let b = hash & 0x0000ff;
  let hex =
    "#" +
    ("0" + r.toString(16)).substr(-2) +
    ("0" + g.toString(16)).substr(-2) +
    ("0" + b.toString(16)).substr(-2);
  strHexes[value] = hex;
  return hex;
}
