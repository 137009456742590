import * as React from "react";
import { SiteData } from "./DOMViewer";
import { useWindowSize } from "./hooks/useWindowSize";

import * as THREE from "three";
import { App } from "./gltf-tree";

const Vis = () => {
  const { useRef, useEffect, useState } = React;
  const mount = useRef<HTMLDivElement>(null);
  const [isAnimating, setAnimating] = useState(true);
  const controls = useRef<{ start: () => {}; stop: () => {} | null }>(null);

  useEffect(() => {
    if (!mount.current) {
      return;
    }
    const app = new App(mount.current);
    app.createTree();
    // let width = mount.current.clientWidth;
    // let height = mount.current.clientHeight;
    // let frameId: number | null = null;

    // const scene = new THREE.Scene();
    // const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    // const renderer = new THREE.WebGLRenderer({ antialias: true });
    // const geometry = new THREE.BoxGeometry(1, 1, 1);
    // const material = new THREE.MeshBasicMaterial({ color: 0xff00ff });
    // const cube = new THREE.Mesh(geometry, material);

    // camera.position.z = 4;
    // scene.add(cube);
    // renderer.setClearColor('#000000');
    // renderer.setSize(width, height);

    // const renderScene = () => {
    //   renderer.render(scene, camera);
    // };

    // const handleResize = () => {
    //   if (!mount.current) {
    //     return;
    //   }
    //   width = mount.current.clientWidth;
    //   height = mount.current.clientHeight;
    //   renderer.setSize(width, height);
    //   camera.aspect = width / height;
    //   camera.updateProjectionMatrix();
    //   renderScene();
    // };

    // const animate = () => {
    //   cube.rotation.x += 0.01;
    //   cube.rotation.y += 0.01;

    //   renderScene();
    //   frameId = window.requestAnimationFrame(animate);
    // };

    // const start = () => {
    //   if (!frameId) {
    //     frameId = requestAnimationFrame(animate);
    //   }
    // };

    // const stop = () => {
    //   cancelAnimationFrame(frameId as number);
    //   frameId = null;
    // };

    // mount.current.appendChild(renderer.domElement);
    // window.addEventListener('resize', handleResize);
    // start();

    // // @ts-ignore
    // controls.current = { start, stop };

    // let currentMount = mount.current;
    return () => {
      app.destroy();
      // stop();
      // window.removeEventListener('resize', handleResize);
      // if (currentMount) {
      //   currentMount.removeChild(renderer.domElement);
      // }
      // scene.remove(cube);
      // geometry.dispose();
      // material.dispose();
    };
  }, []);

  useEffect(() => {
    if (controls.current) {
      if (isAnimating) {
        controls.current.start();
      } else {
        controls.current.stop();
      }
    }
  }, [isAnimating]);

  return (
    <div
      className="vis"
      ref={mount}
      style={{
        width: "100%",
        height: "100vh",
        margin: "0",
        padding: "0",
      }}
      onClick={() => setAnimating(!isAnimating)}
    />
  );
};

export const DOM3DVisualizer = (p: {}) => {
  // const { siteData } = p;
  // const { tree } = siteData;

  let { width, height } = useWindowSize();

  // console.log({ data });
  return <Vis />;
};
