import React, { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { apiUrl } from "./config";
import { useWebData } from "./hooks/useWebData";
import { fetchHosts } from "./redux/reducers/hosts";
import { ResourceNavigator } from "./ResourceNavigator";
import { ResourceVisualizer } from "./ResourceVisualizer";
import { TopRightPanel } from "./TopRightPanel";
import { WebVisualizer } from "./WebVisualizer";

interface AppProps {}

export const floatingBoxStyle = {
  position: "fixed" as const,
  top: 0,
  left: 0,
  background: "white",
  borderRadius: "5px",
  border: "1px solid gray",
  padding: "15px",
  margin: "5px",
  maxWidth: "calc(100vw - 250px)",
  overflow: "hidden",
  zIndex: 1000,
};

export const actionLinkStyle: CSSProperties = {
  textDecoration: "underline",
  cursor: "pointer",
  userSelect: "none",
};
export function WebViewer({}: AppProps) {
  let webData = useWebData();
  let { hosts } = webData;
  let { excluded, excludedResourceTypes } = webData.data;
  let dispatch = useDispatch();

  let [triedHosts, setTriedHosts] = React.useState(false);
  React.useEffect(() => {
    if (
      !triedHosts &&
      hosts.length == 0 &&
      excluded.length == 0 &&
      excludedResourceTypes.length == 0
    ) {
      setTriedHosts(true);
      dispatch(fetchHosts());
    }
  }, [dispatch, hosts, excluded, excludedResourceTypes, triedHosts]);
  return (
    <div className="App">
      <WebVisualizer webData={webData} />
      <ResourceVisualizer webData={webData} />
      <ResourceNavigator />
      <TopRightPanel />
    </div>
  );
}
