import * as _ from "lodash";
import * as React from "react";
import { parseBodyTree } from "../DOMVisualizer";
import { doCrawlResource } from "../redux/reducers/resources";
import { apiUrl } from "../config";
import {
  DOMTree,
  DOMLatestResponseResponse,
  ResourceResponse,
} from "../DOMViewer";
import { parseAsJSON } from "../utils/jsonParser";

export function usePageData(resourceId: string) {
  let [fetchedHosts, setFetchedHosts] = React.useState<Record<string, boolean>>(
    {},
  );
  let [fetchedResources, setFetchedResources] = React.useState<
    Record<string, boolean>
  >({});

  let [data, setData] = React.useState<DOMTree[]>([]);

  let fetchLatestResponse = React.useCallback(
    async (resourceId: string, triedCrawl?: boolean) => {
      let domData: DOMLatestResponseResponse = await (
        await fetch(`${apiUrl}/resources/responses/latest/${resourceId}`)
      ).json();

      const url = domData.resource?.url;
      let parsed: ReturnType<typeof parseBodyTree> | undefined = undefined;

      if (domData.response) {
        const { meta } = domData.response;
        let isJSON = false;
        if (meta.headers) {
          const contentType = meta.headers["content-type"];
          if (contentType) {
            if (contentType.includes("application/json")) {
              console.log("json");
              isJSON = true;
            }
          }
        }
        if (isJSON) {
          parsed = parseAsJSON(url, domData.response.body);
        } else {
          parsed = parseBodyTree(url || "", domData.response.body);
        }
      }

      if (parsed) {
        setData((exists) => {
          let updated = exists.slice();
          updated.push({
            resourceId,
            tree: parsed,
            uri: url,
            raw: domData.response?.body,
          });
          return updated;
        });
      } else if (!triedCrawl) {
        if (url) {
          await doCrawlResource(url);
          fetchLatestResponse(resourceId, true);
        }
      }
    },
    [],
  );

  let fetchByUrl: (u: string, tc?: boolean) => unknown = React.useCallback(
    async (url: string, triedCrawl?: boolean) => {
      let exists = data.find((siteData) => siteData.uri == url);
      if (exists) {
        return;
      }
      let resourceResponse: {
        resource?: ResourceResponse;
      } = await (await fetch(`${apiUrl}/resources/info/${url}`)).json();
      if (resourceResponse && resourceResponse.resource) {
        let { id } = resourceResponse.resource;
        await fetchLatestResponse(id.toString());
      } else {
        if (triedCrawl !== true) {
          console.log(url, "resource not found, crawling");
          let crawledUrl = await doCrawlResource(url);
          if (crawledUrl) {
            console.log(url, "crawled", crawledUrl);
            return fetchByUrl(crawledUrl, true);
          }
        }
      }
    },
    [fetchLatestResponse, data],
  );

  React.useEffect(() => {
    fetchLatestResponse(resourceId);
  }, [resourceId, fetchLatestResponse]);

  const refetchResource = (url: string) => {
    setFetchedResources((r) => _.omit(r, [url]));
    // setSelectedResource(null);
    // setSelectedResource(url);
    // setFetchNodeMeta(null);
    // setFetchNodeMeta(url);
  };
  return {
    // hosts,
    // resources,
    // nodeConnections,
    data,
    fetchByUrl,
    // data: dataRef.current,
    // selectedResource,
    // setSelectedHost,
    // setSelectedResource,
    // setFetchNodeMeta,
    refetchResource,
  };
}
