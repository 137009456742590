import * as _ from "lodash";
import React from "react";
import { useInView } from "react-hook-inview";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateAnnotationData,
  PageActionAnnotations,
} from "../../../extension/src/content_script/PageActionAnnotations";
import {
  Annotation,
  CompletedTabNavigation,
  PageAction,
} from "../../../extension/src/shared/types";
import { normalizeUrl } from "../../../extension/src/utils/normalizedUrl";
import { mediaMirrorUrl } from "../config";
import {
  getImageSrc,
  getSelectedText,
  notEmpty,
} from "../hooks/useWebHistoryData";
import {
  fetchAnnotationsForNavigationId,
  fetchAnnotationsForUrl,
  fetchHistoryForNavigationId,
  outsideCreateAnnotation,
} from "../redux/reducers/history/extensionState";
import { getScreenshot } from "../redux/reducers/history/screenshots";
import { AppDispatch, RootState } from "../redux/store";
import { Collapsible } from "../shared/Collapsible";
import { Debug } from "../shared/Debug";
import { EverWasInView } from "../shared/EverWasInView";
import { GroupingControl } from "./GroupingControl";
import { Icon } from "./Icon";
import { useLoadExtensionState } from "./useLoadExtensionState";
import {
  ActionIdToAnnotations,
  NavigationIdToActionToAnnotations,
  pageKey,
} from "./WebHistoryGrouper";
import { fetchMedia, mediaSlice } from "../redux/reducers/history/media";
import { getDocumentInfoDescription } from "../../../extension/src/content_script/selectors/html/document-plugin/util";

const maxDepth = 5;

export const PageInfo: React.FC<{
  actualDepth: number;
  depth: number;
  historyNode: CompletedTabNavigation;
  pageEvents: Record<string, PageAction[]>;
  pageLinks: Record<string, CompletedTabNavigation[]>;
  pageAnnotations: NavigationIdToActionToAnnotations;
}> = (props) => {
  const {
    historyNode,
    pageEvents,
    pageLinks,
    pageAnnotations,
    depth,
    actualDepth,
  } = props;

  let data = historyNode;
  const { timeStamp } = data;
  const navigationId = data.id;

  const historyNodeType = "finalDest" as const;
  let url = data[historyNodeType] as string;
  let actionNodes = pageEvents[data.id];
  let myAnnotations = pageAnnotations[data.id];

  let myLinkNodes = pageLinks[historyNode.id] || [];

  let [
    actions,
    screenshotActions,
    selectionActions,
    // annotationActions,
  ] = React.useMemo(() => {
    let screenshotActions: PageAction[] = [];
    let selectionActions: PageAction[] = [];

    // let annotationActions: SelectionToAnnotationActions = {};
    let actions: JSX.Element[] = [];
    if (actionNodes && actionNodes.length) {
      actions = actionNodes.map((actionNode) => {
        let actionData = actionNode;
        if (actionData.type == "wm:screenshot") {
          screenshotActions.push(actionNode);
        }
        if (
          ["selectionchangeend", "copy", "wm:preserve-image"].includes(
            actionData.type,
          )
        ) {
          selectionActions.push(actionNode);
        }

        return (
          <div key={actionNode.id}>
            {actionData.type} <Debug>{actionData}</Debug>
          </div>
        );
      });
    }
    // return [actions, screenshotActions, selectionActions, annotationActions];
    return [actions, screenshotActions, selectionActions];
  }, [actionNodes]);

  let screenshotsDisplay = (
    <PageScreenshots screenshotActions={screenshotActions} />
  );

  let selections = React.useMemo(
    () =>
      selectionActions.length ? (
        <SelectionActions
          selectionActions={selectionActions}
          actionIdToAnnotations={myAnnotations}
        />
      ) : null,
    [
      selectionActions,
      // annotationActions,
      myAnnotations,
    ],
  );

  let foreignAnnotationsData = myAnnotations[historyNode.id];

  let dispatch = useDispatch();
  const loadNavigationId = React.useCallback(
    async (navigationId) => {
      await dispatch(fetchHistoryForNavigationId(navigationId));
      let added = document.querySelector(`#navigation-${navigationId}`);
      if (added) {
        added.scrollIntoView();
      }
    },
    [dispatch],
  );

  let foreignAnnotations = React.useMemo(() => {
    if (foreignAnnotationsData && foreignAnnotationsData.length) {
      return foreignAnnotationsData
        .map((anno) => {
          if (anno.targetType == "completedNavigations") {
            return null;
          }
          let link = (
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={loadNavigationId.bind(null, anno.targetNavigationId)}
            >
              {anno.targetNavigationId}
            </span>
          );
          return (
            <div key={anno.id}>
              {anno.text}
              -&gt;
              {link}
            </div>
          );
        })
        .filter(notEmpty);
    }
  }, [foreignAnnotationsData, loadNavigationId]);

  let navigationAnnotations = React.useMemo(() => {
    return foreignAnnotationsData
      ? foreignAnnotationsData.filter(
          (a) => a.targetType == "completedNavigations",
        )
      : [];
  }, [foreignAnnotationsData]);

  let [useDepth, setUseDepth] = React.useState(depth);
  let [linksExpanded, setExpanded] = React.useState(useDepth + 1 <= maxDepth);
  const doExpand = React.useCallback(() => {
    setExpanded(true);
    setUseDepth(0);
  }, []);

  let myLinks: React.ReactNode = null;
  if (myLinkNodes && myLinkNodes.length) {
    if (!linksExpanded) {
      myLinks = (
        <div style={{ cursor: "pointer" }} onClick={doExpand}>
          <div>
            <em>click to expand</em>
          </div>
        </div>
      );
    } else {
      myLinks = myLinkNodes.map((linkNode, i) => {
        const key = "link" + i + pageKey(linkNode);
        if (linkNode == historyNode) {
          // console.log('is self?', linkNode, historyNode);
          return null;
        }
        // console.log('woudl render paginfo', linkNode);
        const nextDepth = useDepth + 1;
        const actualNextDepth = actualDepth + 1;
        return (
          <div
            key={key}
            style={{
              marginLeft: actualNextDepth * 15,
              outline: "thin solid lightgray",
            }}
          >
            <PageInfo
              actualDepth={actualNextDepth}
              depth={nextDepth}
              historyNode={linkNode}
              pageEvents={pageEvents}
              pageLinks={pageLinks}
              pageAnnotations={pageAnnotations}
            />
          </div>
        );
      });
    }
  }

  // console.log({ data });
  let myLinksDisplay;
  if (myLinks) {
    myLinksDisplay = (
      <div>
        <h4>links</h4>
        {myLinks}
      </div>
    );
  }

  let selectionsDisplay;
  if (selections) {
    selectionsDisplay = (
      <div>
        <h4>selections</h4>
        {selections}
      </div>
    );
  }
  let foreignAnnotationsDisplay;
  if (foreignAnnotations && foreignAnnotations.length) {
    foreignAnnotationsDisplay = (
      <div>
        <h4>foreignAnnotations</h4>
        {foreignAnnotations}
      </div>
    );
  }

  let actionsDisplay;
  if (actions.length) {
    actionsDisplay = (
      <div>
        <Collapsible title={"actions"}>{actions}</Collapsible>
      </div>
    );
  }

  let fetchInfo;

  let fetched = useSelector((s: RootState) => s.history.extension.fetched);

  let fetchThis = React.useCallback(() => {
    dispatch(fetchHistoryForNavigationId(navigationId));
  }, [dispatch, navigationId]);

  let needsToFetch = !fetched.includes(navigationId);
  if (needsToFetch) {
    fetchInfo = (
      <div>
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={fetchThis}
        >
          fetch info
        </span>
      </div>
    );
  }

  let navMetaTitle = `navigation meta`;
  if (navigationAnnotations.length) {
    navMetaTitle = `${navMetaTitle} (${navigationAnnotations.length})`;
  }

  let navigationMeta = (
    <Collapsible
      title={navMetaTitle}
      makeChildren={() => {
        return (
          <>
            <NavigationAnnotations
              annotations={navigationAnnotations}
              historyNode={historyNode}
            />
          </>
        );
      }}
    />
  );

  let normUrl = normalizeUrl(historyNode.finalDest);

  let urlMeta;
  if (normUrl) {
    // urlMeta = <GroupingControl nodeId={normUrl} nodeType="url" />;

    let urlAnnotations = myAnnotations[normUrl] || [];
    urlMeta = (
      <EverWasInView>
        <PageUrlAnnotations
          urlAnnotations={urlAnnotations}
          normUrl={normUrl}
          historyNode={historyNode}
        />
      </EverWasInView>
    );
  }

  let warning;
  if (
    historyNode.sourceUrl != "about:blank" &&
    !historyNode.sourceNavigationId
  ) {
    warning = (
      <h3
        style={{
          color: "darkorange",
        }}
      >
        no sourceNavigationId
      </h3>
    );
  }

  return (
    <div
      style={{
        padding: 10,
        border: "2px solid lightgray",
      }}
      id={`navigation-${historyNode.id}`}
    >
      {warning}
      <PageTitle historyNode={historyNode} actions={actionNodes} />
      {urlMeta}
      {navigationMeta}
      <Debug>{historyNode}</Debug>
      {fetchInfo}
      {screenshotsDisplay}
      {selectionsDisplay}
      {foreignAnnotationsDisplay}
      {actionsDisplay}
      {myLinksDisplay}
    </div>
  );
};

const PageTitle: React.FC<{
  historyNode: CompletedTabNavigation;
  actions?: PageAction[];
}> = ({ historyNode, actions }) => {
  let timeDisplay;
  // let { data, historyNodeType } = historyNode;

  const historyNodeType = "finalDest";
  let data = historyNode;
  const { timeStamp } = data;

  let url = data[historyNodeType] as string;
  if (timeStamp) {
    try {
      timeDisplay = new Date(timeStamp).toISOString();
    } catch (e) {}
    if (timeDisplay) {
      timeDisplay = <h5 style={{ display: "inline-block" }}>{timeDisplay}</h5>;
    }
  }

  let info = React.useMemo(() => {
    // if (historyNodeType == "sourceUrl") {
    //   return undefined;
    // }
    let withMeta = _.find(
      actions,
      (a) =>
        a.type == "load" &&
        // @ts-ignore
        !!a.payload.info,
    );
    if (withMeta) {
      let meta = _.get(withMeta, "payload.info");
      return meta;
    }
  }, [actions]);

  let urlDisplay = (
    <>
      {url}
      <a
        style={{ display: "inline-block" }}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <Icon title="open" iconName="link" />
      </a>
    </>
  );
  let display = <h3>{urlDisplay}</h3>;
  if (info) {
    if (info.title) {
      let favicon = <FavIcon info={info} />;

      let socialImageData =
        _.get(info, "facebook.image") || _.get(info, "twitter.image");
      let socialImage;
      if (socialImageData && socialImageData[0]) {
        socialImage = (
          <img
            style={{
              display: "inline-block",
              maxWidth: 100,
              float: "right",
            }}
            src={`${mediaMirrorUrl}${socialImageData[0]}`}
          />
        );
      }

      let descriptionData = getDocumentInfoDescription(info);
      let description;
      if (descriptionData) {
        description = <p>{descriptionData}</p>;
      }
      display = (
        <div>
          {socialImage}
          <h3>
            {favicon}
            {info.title}
          </h3>
          <h4>{urlDisplay}</h4>
          {description}
        </div>
      );
    }
  }

  let qualifiers;
  if (historyNode.qualifiers) {
    qualifiers = historyNode.qualifiers.map((str, i) => (
      <span key={i}>{str}</span>
    ));
  }

  let navType;
  if (historyNode.type && historyNode.type != "link") {
    navType = <span style={{ fontStyle: "italic" }}>{historyNode.type}</span>;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {timeDisplay}
        {qualifiers}
        {navType}
      </div>
      {display}
    </>
  );
};
const PageScreenshots: React.FC<{ screenshotActions: PageAction[] }> = ({
  screenshotActions,
}) => {
  const [ref, inView] = useInView();
  const [everWasInView, setEverWasInview] = React.useState(inView);
  React.useEffect(() => {
    if (inView) {
      setEverWasInview(true);
    }
  }, [inView]);

  let display;
  if (everWasInView) {
    display = <PageScreenshotsInner screenshotActions={screenshotActions} />;
  }
  return <div ref={ref}>{display}</div>;
};
const PageScreenshotsInner: React.FC<{ screenshotActions: PageAction[] }> = ({
  screenshotActions,
}) => {
  const dispatch = useDispatch();
  const screenshotData = useSelector((s: RootState) => {
    return s.history.screenshots;
  });
  React.useEffect(() => {
    screenshotActions
      .map((n) => {
        return (n.payload as { screenshotId: string }).screenshotId;
      })
      .forEach((id, i) => {
        // if (i !== 0) {
        //   return;
        // } else {
        // }
        if (
          !screenshotData.failed.includes(id) &&
          !screenshotData.pending.includes(id) &&
          !screenshotData.all[id]
        ) {
          // console.log('wopudl fetch', id, i, screenshotData.failed);
          dispatch(getScreenshot(id));
        }
        // else {
        //   console.log('already fetching/failed', id);
        // }
      });
  }, [screenshotActions, dispatch, screenshotData]);

  let screenshotsGrouped = React.useMemo(
    () =>
      _.chain(screenshotActions)
        .groupBy((actionNode) => {
          let actionData = actionNode;
          let { screenshotId } = actionData.payload as { screenshotId: string };

          let loaded = screenshotData.all[screenshotId];
          if (loaded) {
            return loaded.width;
          } else {
            return undefined;
          }
        })
        .map((screenshotActions, widthStr) => {
          console.log({ screenshotActions, width: widthStr });
          if (widthStr != "undefined") {
            let width = parseInt(widthStr);

            let screenNodes = _.map(screenshotActions, (act) => {
              let { screenshotId } = act.payload as { screenshotId: string };

              let loaded = screenshotData.all[screenshotId];
              return loaded;
            }).filter(notEmpty);

            let topOffsetNode = _.minBy(screenNodes, (n) => {
              return n.y || 0;
            });

            let topOffset = topOffsetNode ? topOffsetNode.y : 0;
            topOffset = topOffset || 0;

            let screens = _.chain(screenNodes)

              .sortBy((n) => n.timeStamp)
              .map((n, i, all) => {
                if (n) {
                  let style: React.CSSProperties = {
                    position: "absolute",
                    left: n.x || 0,
                    top: (n.y || 0) - topOffset,
                    width: n.width,
                    height: n.height,
                    background: `url(${n.data})`,
                    // zIndex: all.length - i,
                    zIndex: i,
                  };

                  return <div key={n.id} style={style}></div>;
                }
              })
              .filter(notEmpty)
              .value();

            return (
              <div style={{ width: 400 }}>
                <div
                  style={{
                    position: "relative",
                    width,
                    transformOrigin: "top left",
                    transform: `scale(${400 / width})`,
                  }}
                >
                  {screens}
                </div>
              </div>
            );
          }
        })
        .value(),
    [screenshotActions, screenshotData.all],
  );
  // console.log({ screenshotsGrouped });
  let screenshots = React.useMemo(
    () =>
      screenshotActions.map((actionNode) => {
        let actionData = actionNode;
        let { screenshotId } = actionData.payload as { screenshotId: string };

        let loaded = screenshotData.all[screenshotId];

        let display = <>{screenshotId} loading...</>;

        if (loaded) {
          display = (
            <div>
              <img
                style={{
                  maxWidth: 300,
                  height: "auto",
                }}
                src={(loaded.data as any) as string}
              />
              <div style={{ maxWidth: 300 }}>
                <Debug>{_.omit(loaded, "data")}</Debug>
              </div>
            </div>
          );
        } else if (screenshotData.failed.includes(screenshotId)) {
          display = (
            <>
              {screenshotId} failed,{" "}
              <span
                onClick={() => {
                  dispatch(getScreenshot(screenshotId));
                }}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                retry
              </span>
            </>
          );
          // console.log('screenshotloading', screenshotId, screenshotData.all);
        }

        return <div key={screenshotId}>{display}</div>;
      }),
    [screenshotActions, screenshotData.all, screenshotData.failed, dispatch],
  );

  let [showGrouped, setShowGrouped] = React.useState(true);

  let screenshotsDisplay = null;
  let toggle = (
    <span
      style={{
        fontStyle: "italic",
        cursor: "pointer",
      }}
      onClick={() => setShowGrouped(!showGrouped)}
      title={`show ${showGrouped ? "split" : "grouped"}`}
    >
      {showGrouped ? "grouped" : "split"}
    </span>
  );
  if (screenshots.length) {
    let display = (
      <div
        style={{
          overflow: "auto",
          minHeight: "300px",
        }}
      >
        {screenshotsGrouped}
      </div>
    );
    if (!showGrouped) {
      display = (
        <div
          style={{
            display: "flex",
            overflow: "auto",
            maxHeight: "300px",
          }}
        >
          {screenshots}
        </div>
      );
    }

    screenshotsDisplay = (
      <div>
        <h4>
          screens&nbsp;
          {toggle}
        </h4>
        {display}
      </div>
    );
  }
  return screenshotsDisplay;
};
const SelectionActions: React.FC<{
  selectionActions: PageAction[];
  // annotationActions: SelectionToAnnotationActions;
  actionIdToAnnotations: ActionIdToAnnotations;
}> = ({
  selectionActions,
  // annotationActions,
  actionIdToAnnotations,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  let createAnnotation = React.useCallback(
    (navigationId: string) => (annoData: CreateAnnotationData) => {
      dispatch(
        outsideCreateAnnotation({
          ...annoData,
          navigationId,
        }),
      );
    },
    [dispatch],
  );

  let actionsDisplay = selectionActions
    .map((actionData) => {
      return (
        <SelectionAction
          key={actionData.id}
          actionIdToAnnotations={actionIdToAnnotations}
          actionData={actionData}
          createAnnotation={createAnnotation}
        />
      );
    })
    .filter(notEmpty);

  return <>{actionsDisplay}</>;
};
const PageUrlAnnotations: React.FC<{
  urlAnnotations: Annotation[];
  normUrl: string;
  historyNode: CompletedTabNavigation;
}> = ({ urlAnnotations, normUrl, historyNode }) => {
  let isLoaded = useSelector((s: RootState) => {
    return (
      s.history.extension.fetched.includes(`annotations:${normUrl}`) &&
      !s.history.extension.fetching.includes(`annotations:${normUrl}`)
    );
  });

  const dispatch = useDispatch<AppDispatch>();

  const loadFromExtension = React.useCallback(() => {
    return dispatch(fetchAnnotationsForUrl(normUrl));
  }, [dispatch, normUrl]);

  useLoadExtensionState({
    isLoaded,
    loadFromExtension,
  });

  let createAnnotation = React.useCallback(
    (annoData: CreateAnnotationData) => {
      dispatch(
        outsideCreateAnnotation({
          ...annoData,
          navigationId: historyNode.id,
        }),
      );
    },
    [dispatch, historyNode],
  );

  let num = urlAnnotations.length;
  let title = num ? `${num} annotations for url` : `annotations for url`;
  return (
    <PageActionAnnotations
      annotationUrl={normUrl}
      startExpanded={false}
      title={title}
      highlightActionToNode={{
        action: {
          id: normUrl,
          navigationId: historyNode.id,
        },
      }}
      existing={urlAnnotations}
      targetType={"url"}
      createAnnotation={createAnnotation}
    />
  );
};

const NavigationAnnotations: React.FC<{
  annotations: Annotation[];
  historyNode: CompletedTabNavigation;
}> = ({ annotations, historyNode }) => {
  let { id } = historyNode;
  let isLoaded = useSelector((s: RootState) => {
    return (
      s.history.extension.fetched.includes(`annotations:navigation:${id}`) &&
      !s.history.extension.fetching.includes(`annotations:navigation:${id}`)
    );
  });

  const dispatch = useDispatch<AppDispatch>();

  const loadFromExtension = React.useCallback(() => {
    return dispatch(fetchAnnotationsForNavigationId(id));
  }, [dispatch, id]);

  useLoadExtensionState({
    isLoaded,
    loadFromExtension,
  });

  let createAnnotation = React.useCallback(
    (annoData: CreateAnnotationData) => {
      dispatch(
        outsideCreateAnnotation({
          ...annoData,
          navigationId: historyNode.id,
        }),
      );
    },
    [dispatch, historyNode],
  );

  let num = annotations.length;
  let title = num
    ? `${num} annotations for navigation`
    : `annotations for navigation`;
  return (
    <PageActionAnnotations
      annotationUrl={historyNode.finalDest}
      startExpanded={!!annotations.length}
      title={title}
      highlightActionToNode={{
        action: {
          id,
          navigationId: id,
        },
      }}
      existing={annotations}
      targetType={"completedNavigations"}
      createAnnotation={createAnnotation}
    />
  );
};

const isDataUrl = (str: string) =>
  /^data:((?:\w+\/(?:(?!;).)+)?)((?:;[\w\W]*?[^;])*),(.+)$/.test(str);

const makeMediaMirrorUrl = (str: string) => {
  if (!isDataUrl(str)) {
    str = `${mediaMirrorUrl}${str}`;
  }

  return str;
};
const FavIcon: React.FC<{ info: any }> = ({ info }) => {
  let favicon = null;

  let defaultFaviconUrl = React.useMemo(() => {
    try {
      let link = _.first(info.link) as { href: string };
      if (link && link.href) {
        let hostUrl = new URL(link.href);
        hostUrl.pathname = "/favicon.ico";
        hostUrl.search = "";
        hostUrl.hash = "";

        let defaultFaviconUrl = hostUrl.href;
        // console.log({ defaultFaviconUrl });
        return makeMediaMirrorUrl(defaultFaviconUrl);
      }
    } catch (e) {
      return null;
    }
  }, [info]);

  // let [checkedDefault, setCheckedDefault] = React.useState(false);
  let [showDefault, setShowDefault] = React.useState(true);

  let onDefaultError = React.useCallback(() => {
    // setCheckedDefault(true);
    setShowDefault(false);
  }, []);

  const faviconStyle: React.CSSProperties = {
    display: "inline-block",
    maxWidth: 25,
    float: "left",
  };

  if (info.favicon) {
    let src = makeMediaMirrorUrl(info.favicon);
    favicon = <img style={faviconStyle} src={src} />;
  } else {
    if (showDefault && defaultFaviconUrl) {
      favicon = (
        <img
          style={faviconStyle}
          src={defaultFaviconUrl}
          onError={onDefaultError}
          onAbort={onDefaultError}
        />
      );
    }
  }
  return favicon;
};

const PreserveImageActionContent: React.FC<{
  actionData: PageAction;
}> = ({ actionData }) => {
  let dispatch = useDispatch();

  let mediaId = actionData.payload.mediaId;

  let imgSrc = useSelector((s: RootState) => {
    if (mediaId) {
      let loaded = s.history.media.all[mediaId];
      return loaded?.blobUrl;
    }
  });

  if (!mediaId) {
    let rawSrc = getImageSrc(actionData);
    imgSrc = makeMediaMirrorUrl(rawSrc);
  }

  React.useEffect(() => {
    if (mediaId) {
      dispatch(fetchMedia(mediaId));
    }

    return () => {
      dispatch(mediaSlice.actions.unloadMedia(mediaId));
    };
  }, [mediaId, dispatch]);

  let meta = null;
  let currentTime =
    actionData.payload.target[0]?.selector[0]?.attrs?.currentTime;
  if (currentTime) {
    meta = <div>time: {currentTime}</div>;
  }
  return (
    <div>
      <img
        style={{
          maxWidth: 400,
        }}
        src={imgSrc}
      ></img>
      {meta}
    </div>
  );
};

const SelectionAction: React.FC<{
  actionIdToAnnotations: ActionIdToAnnotations;
  createAnnotation: (
    navigationId: string,
  ) => (annoData: CreateAnnotationData) => void;
  actionData: PageAction;
}> = ({ actionIdToAnnotations, createAnnotation, actionData }) => {
  let content;
  if (actionData.type == "wm:preserve-image") {
    content = (
      <EverWasInView>
        <PreserveImageActionContent actionData={actionData} />
      </EverWasInView>
    );
  } else {
    let text = getSelectedText(actionData);
    if (text) {
      content = (
        <blockquote
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {text}
        </blockquote>
      );
    }
  }

  // let annotations = annotationActions[actionData.id] || [];
  let annotations =
    _.sortBy(actionIdToAnnotations[actionData.id], "timeStamp") || [];
  if (content) {
    return (
      <div key={actionData.id}>
        <div style={{ display: "flex" }}>
          <GroupingControl nodeId={actionData.id} nodeType="pageActions" />
          {content}
        </div>
        <PageActionAnnotations
          noGroupsForAction
          noCollapse
          highlightActionToNode={{
            action: {
              id: actionData.id,
              navigationId: actionData.navigationId,
            },
          }}
          annotationUrl={actionData.url}
          targetType="pageActions"
          existing={annotations}
          createAnnotation={createAnnotation(actionData.id)}
        />

        <Debug>{actionData}</Debug>
      </div>
    );
  } else {
    return null;
  }
};
