import * as _ from "lodash";

import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  combineReducers,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { apiUrl } from "../../config";
import { HostSummaryResponse, WebNode } from "../../hooks/useWebData";
import { fetchLinksForResource } from "./resources";

type HostNode = WebNode & {};

export const fetchHosts = createAsyncThunk<
  HostNode[],
  undefined,
  {
    state: RootState;
  }
>(
  "hosts/fetchHosts",
  async (_n = undefined, { dispatch, getState, requestId }) => {
    let allHosts = await (await fetch(`${apiUrl}/resources/hosts`)).json();
    let munged = allHosts
      // .filter((h: HostSummaryResponse) => parseInt(h.resource_count) > 5)
      .map((host: HostSummaryResponse) => {
        return {
          id: host.host,
          name: `${host.host} ${host.resource_count}`,
          nodeType: "host",
          host: host.host,
          resource_count: parseInt(host.resource_count),
          resourceType: "host",
        };
      });
    // munged = _.filter(munged, (h) => h.host == 'news.ycombinator.com');
    munged = _.sampleSize(munged, 1);
    return munged;
  },
);
const allHostsSlice = createSlice({
  name: "hosts",
  initialState: [] as HostNode[],
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchHosts.pending, (state, action) => {
      // console.log('fetch pending', action);
    });
    builder.addCase(fetchHosts.rejected, (state, action) => {
      // console.log('fetch rejected', action);
    });
    builder.addCase(fetchHosts.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      console.log({ meta });
      return [...payload];
    });

    builder.addCase(fetchLinksForResource.fulfilled, (state, action) => {
      // console.log('fetch fulfilled', action);
      let { meta, payload } = action;
      let allResources = payload;
      if (!allResources) {
        return state;
      }
      let existing = state;
      type HostLink = { host: string };

      // let newHosts = (allResources.fromLinks as HostLink[])
      //   .concat(allResources.toLinks as HostLink[])
      //   .filter((l) => {
      //     let { host } = l;
      //     return !existing.find((node) => node.id == host);
      //   })
      //   .map((l) => {
      //     return {
      //       id: l.host,
      //       url: l.host,
      //       name: `${l.host} ???`,
      //       nodeType: 'host',
      //       host: l.host,
      //       resource_count: 1,
      //     };
      //   });

      let newHosts = _.chain(allResources.fromLinks as HostLink[])
        .concat([allResources.resource])
        .concat(allResources.toLinks as HostLink[])
        .filter((l) => {
          if (!l) {
            return false;
          }
          let { host } = l;
          return !existing.find((node) => node.id == host);
        })
        .uniqBy("host")
        .map((l) => {
          return {
            id: l.host,
            url: l.host,
            name: `${l.host} ???`,
            nodeType: "host",
            host: l.host,
            resource_count: 1,
            resourceType: "host",
          };
        })
        .value();
      console.log("should add", newHosts.length, "hosts");
      // let linkedToResources = allResources.toLinks.map((l) => {
      //   let { host, from_url, link_type } = l;
      //   let url = from_url;
      //   let resourceType = pageLinkTypes.includes(link_type)
      //     ? 'page'
      //     : link_type;

      //   let resourceId = l.from_id;
      //   return {
      //     id: url,
      //     name: url,
      //     url,
      //     nodeType: 'resource',
      //     host: host,
      //     resourceId,
      //     // resourceType,
      //   } as WebNode;
      //   //   linkType: link_type,
      //   // };
      // });

      // linkedToResources = linkedToResources.filter(
      //   (newResource) =>
      //     !_.find(
      //       existing,
      //       (existingResource) => existingResource.url == newResource.url,
      //     ),
      // );
      // return existing.concat(linkedToResources);
      return existing.concat(newHosts);
      // return existing
    });
  },
});
const hostFiltersSlice = createSlice({
  name: "hosts.filters",
  initialState: {
    excluded: [] as string[],
  },
  reducers: {
    excludeHost: (state, action: PayloadAction<string>) => {
      let hostName = action.payload;
      console.log("should exclude", hostName);
      state.excluded = _.uniq(state.excluded.concat([hostName]));
      return state;
    },
    includeHost: (state, action: PayloadAction<string>) => {
      let hostName = action.payload;
      console.log("should inclkude", hostName);
      state.excluded = _.without(state.excluded, hostName);
      return state;
    },
  },

  extraReducers: (builder) => {},
});

export const actions = {
  ...hostFiltersSlice.actions,
};

export default combineReducers({
  all: allHostsSlice.reducer,
  filters: hostFiltersSlice.reducer,
});
