import * as React from "react";
import * as _ from "lodash";
import { actionLinkStyle } from "../WebViewer";

import { apiUrl } from "../config";
import { Link } from "@reach/router";
import { doCrawlResource } from "../redux/reducers/resources";

interface JobsViewerProps {
  queueName?: string;
  jobStatus?: JobStatus;
}

type QueueMetaResponse = {
  waiting: number;
  active: number;
  completed: number;
  failed: number;
  delayed: number;
  paused: number;
};

export type JobsMetaResponse = Record<string, QueueMetaResponse>;

export type JobStatus = keyof QueueMetaResponse;
type JobDescription<T> = {
  id: number | null;
  name: string;
  data?: T;
  opts: {
    attempts: number;
    delay: number;
    timestamp: number;
  };
  progress: number;
  delay: number;
  timestamp: number;
  attemptsMade: number;
  failedReason: string;
  stacktrace: string[];
  //   "returnvalue": null,
  finishedOn: number;
  processedOn: number;
};

type RepeatableJobDescription = {
  key: string;
  name: string;
  id: null;
  endDate: null;
  tz: null;
  cron: string;
  every: null;
  next: number;
};

type CrawlJobDescription = JobDescription<{
  url: string;
  crawlOptions: {
    crawlerOptions: {
      userAgent: string;
    };
    maxDepth: string;
  };
}>;
type KeywordsJobDescription = JobDescription<{
  responseId: number;
}>;

type JobDescriptionResponse =
  | CrawlJobDescription
  | KeywordsJobDescription
  | RepeatableJobDescription;

const actionButton: React.CSSProperties = {
  padding: "5px",
  border: "1px solid gray",
  borderRadius: "75%",
  cursor: "pointer",
  minWidth: "20px",
  minHeight: "20px",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  userSelect: "none",
};

type JobStatusInfoResponse = JobDescriptionResponse[];

const useJobsData = (queueName?: string, jobStatus?: JobStatus) => {
  let [meta, setMeta] = React.useState<JobsMetaResponse>();
  type JobStatuses = Record<JobStatus, JobStatusInfoResponse>;

  let [statusInfo, setStatusInfo] = React.useState<JobStatuses>();

  let fetchJobsMeta = React.useCallback(async function fetchData() {
    let res: JobsMetaResponse = await (
      await fetch(`${apiUrl}/jobs/status`)
    ).json();
    setMeta(res);
  }, []);

  let fetchStatusInfo = React.useCallback(
    async function fetchData() {
      if (jobStatus && queueName) {
        let res: JobStatusInfoResponse = await (
          await fetch(`${apiUrl}/jobs/status/${queueName}/${jobStatus}`)
        ).json();
        setStatusInfo((existing) => {
          if (!jobStatus) {
            return existing;
          }
          return ({
            ...existing,
            [jobStatus]: res,
          } as unknown) as JobStatuses;
        });
      }
    },
    [jobStatus, queueName],
  );

  const refetch = React.useCallback(() => {
    fetchJobsMeta();
    fetchStatusInfo();
  }, [fetchJobsMeta, fetchStatusInfo]);

  React.useEffect(() => {
    fetchJobsMeta();
  }, [fetchJobsMeta]);
  React.useEffect(() => {
    fetchStatusInfo();
  }, [fetchStatusInfo]);
  return {
    jobStatus,
    meta,
    statusInfo,
    refetch,
    queueName,
  };
};

type JobsData = ReturnType<typeof useJobsData>;

const JobVisualizer = (p: { jobsData: JobsData }) => {
  const { jobsData } = p;
  const { jobStatus, meta, statusInfo, queueName } = jobsData;
  const { refetch } = jobsData;

  let [autoRefresh, setAutoRefresh] = React.useState(false);
  let [expanded, setExpanded] = React.useState<number | null>();

  const toggleAutoRefresh = React.useCallback(() => {
    setAutoRefresh((r) => !r);
  }, [setAutoRefresh]);

  const metaActions = (
    <>
      <span onClick={toggleAutoRefresh}>
        {autoRefresh ? "autorefresh on" : "autorefresh off"}
      </span>
    </>
  );

  let metaDisplay = metaActions;
  if (meta) {
    let queueLists = _.map(meta, (queueInfo, queueName) => {
      return (
        <React.Fragment key={queueName}>
          <h3>{queueName}</h3>
          <ul
            style={{
              display: "flex",
              listStyle: "none",
              justifyContent: "space-between",
              maxWidth: "50vw",
            }}
          >
            {_.map(queueInfo, (metaCount, status, i) => {
              return (
                <li key={status}>
                  <Link
                    to={`/jobs/${queueName}/${status}`}
                    getProps={({ isCurrent }) => {
                      // the object returned here is passed to the
                      // anchor element's props
                      return {
                        style: {
                          color: isCurrent ? "red" : "blue",
                        },
                      };
                    }}
                  >
                    {status}:{metaCount}
                  </Link>
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      );
    });

    metaDisplay = (
      <>
        {metaDisplay}
        {queueLists}
      </>
    );
  }

  React.useEffect(() => {
    let interval: number;
    if (autoRefresh) {
      interval = (setInterval(() => {
        console.log("refreshing");
        refetch();
      }, 1000) as unknown) as number;
    }

    return () => {
      clearInterval(interval);
    };
  }, [autoRefresh, refetch]);
  const doDeleteJobById = React.useCallback(
    async (id: number) => {
      console.log("del", id);
      if (id) {
        await fetch(`${apiUrl}/jobs/by-id/${queueName}/${id}`, {
          method: "delete",
        });
        await refetch();
      }
    },
    [refetch, queueName],
  );
  const doDeleteRepeatableJob = React.useCallback(
    async (id: number) => {
      console.log("del reoetabke", id);
      if (id) {
        await fetch(
          `${apiUrl}/jobs/by-repeatable-key/${queueName}/${encodeURIComponent(
            id,
          )}`,
          {
            method: "delete",
          },
        );
        await refetch();
      }
    },
    [refetch, queueName],
  );
  const doRetryJob = React.useCallback(
    async (url: string) => {
      if (url) {
        await doCrawlResource(url, {
          onQueued: refetch,
        });
        refetch();
      }
    },
    [refetch],
  );

  let statusInfoDisplay;
  if (jobStatus && statusInfo) {
    let currentInfo = statusInfo[jobStatus];
    if (currentInfo) {
      statusInfoDisplay = (
        <ul style={{}}>
          {_.map(currentInfo, (jobInfo, i) => {
            const { id, name } = jobInfo;
            let deleteAction;

            const jobActions = [];

            let data, timestamp, finishedOn, stacktrace, attemptsMade;
            let key, url;
            let display;
            if (isRepeatableJobDescription(jobInfo)) {
              key = jobInfo.key;
              deleteAction = doDeleteRepeatableJob.bind(null, key);
            } else {
              ({
                timestamp,
                finishedOn,
                stacktrace,
                data,
                attemptsMade,
              } = jobInfo);
              deleteAction = doDeleteJobById.bind(null, id);
              if (jobInfo.data) {
                if (isCrawlJobDescription(jobInfo)) {
                  ({ url } = jobInfo.data);

                  if (url) {
                    display = (
                      <a href={url} rel="noreferrer" target="_blank">
                        {url}
                      </a>
                    );
                  }

                  jobActions.push(
                    <span
                      key={`retry-${id}`}
                      onClick={doRetryJob.bind(null, url)}
                      style={actionButton}
                      title="retry"
                    >
                      🗘
                    </span>,
                  );
                } else if (isKeywordsJobDescription(jobInfo)) {
                  display = <span>responseID: {jobInfo.data.responseId}</span>;
                }
              }
            }

            const reactKey = id || key || `${name}-${i}`;

            jobActions.unshift(
              <span
                key={`del-${reactKey}`}
                onClick={deleteAction}
                style={actionButton}
                title="delete"
              >
                x
              </span>,
            );

            let createdDisplay = timestamp ? (
              <>&nbsp; created: {new Date(timestamp).toLocaleString()}</>
            ) : null;
            let finishedDisplay = finishedOn ? (
              <>&nbsp; finished: {new Date(finishedOn).toLocaleString()}</>
            ) : null;
            let stackDisplay =
              stacktrace && stacktrace.length ? (
                <>
                  <br /> stack: <pre>{stacktrace.join("\n")}</pre>
                </>
              ) : null;

            let expandedDisplay =
              expanded == id ? (
                <pre>{JSON.stringify(jobInfo, null, 2)}</pre>
              ) : null;
            let toggleExpand;
            if (expandedDisplay) {
              toggleExpand = setExpanded.bind(null, null);
            } else {
              toggleExpand = setExpanded.bind(null, id);
            }

            let attemptsMadeDisplay;
            if (_.isNumber(attemptsMade)) {
              attemptsMadeDisplay = <>&nbsp; attempts: {attemptsMade}</>;
            }

            return (
              <li
                key={reactKey}
                style={{
                  marginBottom: "5px",
                }}
              >
                {jobActions}
                <span onClick={toggleExpand}>
                  {reactKey}:{display}
                  <br />
                  {attemptsMadeDisplay}
                  {createdDisplay}
                  {finishedDisplay}
                  {stackDisplay}
                  {expandedDisplay}
                </span>
              </li>
            );
          })}
        </ul>
      );
    }
  }

  const doClearJobStatus = React.useCallback(async () => {
    if (jobStatus) {
      await fetch(`${apiUrl}/jobs/by-status/${queueName}/${jobStatus}`, {
        method: "delete",
      });
      await refetch();
    }
  }, [jobStatus, refetch, queueName]);

  let actions = [];
  if (jobStatus) {
    actions.push(
      <span
        key={`clear-${jobStatus}`}
        style={actionLinkStyle}
        onClick={doClearJobStatus}
      >
        clear all {jobStatus} jobs
      </span>,
    );
  }

  return (
    <>
      <h1>{jobStatus} Jobs</h1>
      {metaDisplay}
      {actions}
      {statusInfoDisplay}
    </>
  );
};
function isRepeatableJobDescription(
  n: JobDescriptionResponse,
): n is RepeatableJobDescription {
  return !!(n as RepeatableJobDescription).key;
}

function isCrawlJobDescription(
  n: JobDescriptionResponse,
): n is CrawlJobDescription {
  return !!(n as CrawlJobDescription).id && _.get(n, "data.url");
}
function isKeywordsJobDescription(
  n: JobDescriptionResponse,
): n is KeywordsJobDescription {
  return !!(n as KeywordsJobDescription).id && _.get(n, "data.responseId");
}
export function JobsViewer(p: JobsViewerProps) {
  let { jobStatus, queueName } = p;
  let jobsData = useJobsData(queueName, jobStatus);
  // let selectedResource = { webData };
  return (
    <>
      <JobVisualizer jobsData={jobsData} />
    </>
  );
}
