import _ from "lodash";
import * as React from "react";
import { LinkObject, NodeObject } from "react-force-graph-2d";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  fetchForHost,
  fetchLinksForResource,
  fetchNodeMeta,
  NodeInfo,
  resourceActions,
  WebLinkStored,
} from "../redux/reducers/resources";
import { RootState } from "../redux/store";

export type WebLink = LinkObject & {
  _id: string;
  linkType?: string;
  resourceLinkId?: number;
};

export type WebNode = NodeObject & {
  nodeType: string;

  color?: string;

  id: string;
  url: string;
  host: string;
  name: string;

  crawled?: boolean;
  resourceType?: string;

  resource_count?: number;
  resourceId?: number;
  info?: NodeInfo;
};

export type HostSummaryResponse = { host: string; resource_count: string };

export const pageLinkTypes = ["anchor", "redirect"];

export const allHostsSelector = createSelector(
  (s: RootState) => s.hosts.all,
  (h) => h,
);
export const allResourcesSelector = createSelector(
  (s: RootState) => s.resources.all,
  (h) => h,
);
const excludedHostNames = (s: RootState) => s.hosts.filters.excluded;
const excludedLinkTypesSelector = (s: RootState) =>
  s.resources.filters.excludedLinkTypes;
const excludedResourceTypesSelector = (s: RootState) =>
  s.resources.filters.excludedResourceTypes;
const visibleHostsSelector = createSelector(
  [allHostsSelector, excludedHostNames],
  (hosts, excluded) => {
    return hosts.filter((h) => !excluded.includes(h.host));
  },
);

const makeSelectAllHosts = () =>
  createSelector(
    [visibleHostsSelector, excludedResourceTypesSelector],
    (hosts, excludedResourceTypes) =>
      excludedResourceTypes.includes("host")
        ? []
        : hosts.map((h) => ({ ...h })),
  );

const excludedNodeIdsSelector = createSelector(
  [allResourcesSelector, excludedHostNames, excludedResourceTypesSelector],
  (resources, excludedHostNames, excludedResourceTypes) => {
    return resources
      .map((resource) => {
        if (
          excludedHostNames.includes(resource.host) ||
          excludedResourceTypes.includes(resource.resourceType || "")
        ) {
          return resource.id;
        } else {
          // console.log('exclude', resource.id);
          return;
        }
      })
      .filter((r) => !!r) as string[];
  },
);

const makeSelectAllResources = () =>
  createSelector(
    [(state: RootState) => state.resources.all, excludedNodeIdsSelector],
    (resources, excludedNodeIds) => {
      // console.log({ excludedNodeIds });
      return resources
        .map((resource) => {
          if (!excludedNodeIds.includes(resource.id)) {
            return { ...resource };
          } else {
            // console.log('exclude', resource);
            return;
          }
        })
        .filter((r) => !!r) as WebNode[];
    },
  );

const makeSelectAllLinks = () =>
  createSelector(
    [
      (state: RootState) => state.resources.links,
      excludedHostNames,
      excludedLinkTypesSelector,
      excludedNodeIdsSelector,
    ],
    (links, excluded, excludedLinkTypes, excludedNodeIds) => {
      return links
        .map((link) => {
          if (_.intersection(excluded, link.hosts).length) {
            // console.log('should exclude link', link);
            return;
          }

          if (_.includes(excludedLinkTypes, link.linkType)) {
            return;
          }

          if (
            excludedNodeIds.includes(link.target) ||
            excludedNodeIds.includes(link.source)
          ) {
            return;
          }

          return { ...link };
        })
        .filter((r) => !!r) as WebLinkStored[];
    },
  );

function isNodeObject(n: LinkObject["source"]): n is WebNode {
  return !!(n as NodeObject)?.id;
}

export function useWebData() {
  const dispatch = useDispatch();

  const hostsSelector = React.useRef(makeSelectAllHosts());
  const resourcesSelector = React.useRef(makeSelectAllResources());
  const linksSelector = React.useRef(makeSelectAllLinks());

  const hosts = useSelector(hostsSelector.current);
  const resources = useSelector(resourcesSelector.current);
  const nodeConnections = useSelector(linksSelector.current);
  const excluded = useSelector(excludedHostNames);
  const excludedLinkTypes = useSelector(excludedLinkTypesSelector);
  const excludedResourceTypes = useSelector(excludedResourceTypesSelector);

  let [selectedHost, setSelectedHost] = React.useState<string | null>(null);

  let selectedResource = useSelector(
    (s: RootState) => s.resources.filters.selected,
  );

  let setSelectedResource = React.useCallback(
    (url: string) => {
      dispatch(resourceActions.selectResource(url));
    },
    [dispatch],
  );
  let setFetchNodeMeta = React.useCallback(
    (fetchNodeMetaUrl: string) => {
      dispatch(
        fetchNodeMeta({
          url: fetchNodeMetaUrl,
        }),
      );
    },
    [dispatch],
  );

  React.useEffect(() => {
    dispatch(fetchForHost(selectedHost));
  }, [selectedHost, dispatch]);
  React.useEffect(() => {
    dispatch(
      fetchLinksForResource({
        url: selectedResource,
      }),
    );
  }, [selectedResource, dispatch]);

  let [data, setData] = React.useState<{
    nodes: WebNode[];
    links: WebLinkStored[];
    excluded: string[];
    excludedLinkTypes: string[];
    excludedResourceTypes: string[];
  }>({
    nodes: [],
    links: [],
    excluded: [],
    excludedLinkTypes: [],
    excludedResourceTypes: [],
  });

  React.useEffect(() => {
    console.log("data updated");

    const excludedResourceTypesChanged =
      data.excludedResourceTypes.length != excludedResourceTypes.length;
    if (
      data.excluded.length != excluded.length ||
      excludedResourceTypesChanged
    ) {
      let filteredNodeCopnnections = nodeConnections;
      if (excludedResourceTypesChanged) {
        // filteredNodeCopnnections = nodeConnections.filter((l) => {
        //   let { source, target } = l;
        //   if (
        //     (isNodeObject(source) &&
        //       excludedResourceTypes.includes(source.resourceType || '')) ||
        //     (isNodeObject(target) &&
        //       excludedResourceTypes.includes(target.resourceType || ''))
        //   ) {
        //     return false;
        //   }
        //   return true;
        // });
      }

      let excludedLinks = _.difference(
        nodeConnections,
        filteredNodeCopnnections,
      );
      console.log({ excludedLinks });

      setData({
        links: filteredNodeCopnnections,
        nodes: hosts.concat(resources),
        excluded,
        excludedLinkTypes,
        excludedResourceTypes,
      });
      return;
    }
    let newNodes = hosts.concat(resources).filter((n) => {
      return !_.find(data.nodes, (oldNode) => oldNode.id == n.id);
    });
    if (!newNodes.length && nodeConnections.length == data.links.length) {
      console.log("no change?");
      return;
    }

    const newLinks = findNewLinks(nodeConnections, data.links);
    const linkCountDoesntMatch =
      data.links.length + newLinks.length != nodeConnections.length;
    // console.log(
    //   'nodes:',
    //   newNodes.length,
    //   existingData.links.length,
    //   nodeConnections.length,
    //   'new:',
    //   newLinks,
    // );
    if (linkCountDoesntMatch) {
      setData({
        links: nodeConnections,
        nodes: hosts.concat(resources),
        excluded,
        excludedLinkTypes,
        excludedResourceTypes,
      });
      return;
    }

    if (!newLinks.length && !newNodes.length) {
      debugger;
    }
    setData({
      nodes: data.nodes.concat(newNodes),
      // links: nodeConnections,
      excluded,
      excludedLinkTypes,
      excludedResourceTypes,
      links: data.links.concat(newLinks),
    });
  }, [
    data,
    hosts,
    resources,
    nodeConnections,
    excluded,
    excludedLinkTypes,
    excludedResourceTypes,
  ]);

  const refetchResource = React.useCallback(
    (url: string) => {
      // setFetchedResources((r) => _.omit(r, [url]));
      setSelectedResource("");
      setSelectedResource(url);

      dispatch(
        fetchLinksForResource({
          url,
          force: true,
        }),
      );
      dispatch(fetchNodeMeta({ url, force: true }));
    },
    [dispatch, setSelectedResource],
  );
  return {
    hosts,
    resources,
    nodeConnections,
    data,
    // data: dataRef.current,
    selectedResource,
    setSelectedHost,
    setSelectedResource,
    setFetchNodeMeta,
    refetchResource,
  };
}

export type WebData = ReturnType<typeof useWebData>;
function linkTargetToString(oldLink: WebLink, property: "target" | "source") {
  let got =
    _.isString(oldLink[property]) || _.isNumber(oldLink[property])
      ? oldLink[property]
      : (oldLink[property] as NodeObject)?.id;

  return got?.toString();
}

function findNewLinks(
  allLinks: WebLinkStored[],
  existingLinks: { _id: string }[],
) {
  let newLinks = allLinks.filter((allLink) => {
    return !_.find(existingLinks, { _id: allLink._id });
  });
  return newLinks;
}

export function linkTypeToResourceType(link_type?: string) {
  return pageLinkTypes.includes(link_type || "") ? "page" : link_type;
}
